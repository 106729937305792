import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import moment from 'moment';
import { kDateFormatDisplay } from '../../functional/min-support';
import InternalCalendar from '../calendar/InternalCalendar';
import { getAllOperatorsAdvanced } from '../../functional/api';
import { kToastActionError } from '../general/Toast';
import TextFieldDisplay from '../input/TextFieldDisplay';

class Modal extends Component {
    
    static propTypes = {
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
        
        onSelect: PropTypes.func,
    };
    
    state = {
        date: moment().format(kDateFormatDisplay),
        operator: '',
        operators: [],
        slot: '',
        seeAll: false,
    
        scheduleDate: moment(new Date()).format(kDateFormatDisplay),
        fromDate: moment(new Date()).startOf('month'),
    };

    render () {
        return (
            <BaseModal
                stableHeight
                title={'Alege operatorul'}
                open={this.props.open}
                doneActive={false}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>
    
                    <InternalCalendar
                        activeDate={this.state.scheduleDate}
                        startDate={this.state.fromDate}
                        onDayPressed={this.onCalendarDayPressed}/>
                        
                    {
                        this.state.operators.map((item, index) => {
                            return (
                                <ItemEntry key={index} onPress={(operator, time) => {
                                    this.props.onSelect(operator, time, this.state.scheduleDate);
                                    this.props.onClose();
                                }} operator={item.operator} times={item.times}/>
                            );
                        })
                    }

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        //this.props.openLoading();
        this.props.onSelect(this.state.operator);
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
        this.setState({ operators: [] });
        getAllOperatorsAdvanced(this.state.scheduleDate, this.props.itemKey, (successful, data) => {
            if (successful) {
                this.setState({
                    operators: data,
                });
            } else {
                this.props.toast('A aparut o problema la incarcare!', kToastActionError);
            }
        });
    };

    clear = () => {
        this.setState({
            date: moment().format(kDateFormatDisplay),
            operator: '',
            seeAll: false,
    
            scheduleDate: new Date(),
            fromDate: moment(new Date()).startOf('month'),
        });
    }
    
    
    onCalendarDayPressed = (currentInterval, newDate, direction) => {
        if (!currentInterval) {
            if (direction === 'left' && new moment().isSame(this.state.fromDate, 'month')) {
                return;
            }
            this.onChangeIntervalPressed(direction);
        }
        const today = new moment();
        const newDateMoment = new moment(newDate, kDateFormatDisplay);
        if (newDateMoment.isBefore(today, 'day')) {
            return;
        }
        this.setState({
            scheduleDate: newDate,
        });
        this.update();
    };
    
    onChangeIntervalPressed = (type) => {
        const currentDate = new moment();
        let isCurrentMonth = false;
        
        const currentStart = new moment(this.state.fromDate, kDateFormatDisplay);
        let newStart = new moment(this.state.fromDate, kDateFormatDisplay);
        
        let newEnd = new moment(this.state.toDate, kDateFormatDisplay);
        
        if (type === 'left') {
            newStart = currentStart.subtract(1, 'months');
        } else if (type === 'right') {
            newStart = currentStart.add(1, 'months');
        }
        
        isCurrentMonth = currentDate.isSame(newStart, 'month');
        
        if (true || !isCurrentMonth) { // whole month
            newEnd = new moment(newStart);
            newEnd = newEnd.add(1, 'months').subtract(1, 'days');
        } else {
            newEnd = new moment(currentDate);
        }
        
        this.setState({
            fromDate: newStart.format(kDateFormatDisplay),
            toDate: newEnd.format(kDateFormatDisplay),
            scheduleDate: isCurrentMonth ? currentDate.format(kDateFormatDisplay) : newStart.format(kDateFormatDisplay),
        });
    };

}

const ItemEntry = (props) => {
    return (
        <div
            onClick={() => {}}
            style={{ width: '100%', padding: 10, paddingLeft: 0, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flex: 5 }}>
            
            <TextFieldDisplay
                style={{ flex: 1, minWidth: 150 }}
                label={'operator'}
                value={props.operator.username}
                icon={'code'}/>
                
            {
                props.times.map((item, index) => {
                    return (
                        <TextFieldDisplay
                            key={index}
                            onPress={() => { props.onPress(props.operator, item.time); }}
                            style={{ flex: 1, cursor: 'pointer' }}
                            label={`Distanta ${ item.distance }km`}
                            value={`Ora ${ item.time }`}/>
                    );
                })
            }
        
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);