import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addTicket, checkClientAssigned, getAddTicketInfo, getAllEquipments } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationInteger,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
    inputValidationYesSelected,
    kDateFormatDisplay,
    kDateFormatServer,
    renderIf,
} from '../../functional/min-support';
import { kInterventionTypes, kWarrantyFirms } from '../../functional/constants';
import AddTicketEquipments from '../ticket/AddTicketEquipments';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';
import AddEquipmentModal from './AddEquipmentModal';
import ChooseOperatorModal from './ChooseOperatorModal';
import moment from 'moment';
import SimpleButton from '../input/SimpleButton';

const kPriorityTypes = [
    {
        value: 'low',
        label: 'Prio 3',
    },
    {
        value: 'normal',
        label: 'Prio 2',
    },
    {
        value: 'high',
        label: 'Prio 1',
    },
    {
        value: 'urgent',
        label: 'Urgent',
    },
];

const createWorkPrices = (basePrice) => {
    try {
        basePrice = parseInt(basePrice);
    } catch (e) {
        return [];
    }

    const result = [
        {
            value: `${ basePrice * 2 / 3 }`,
            label: 'Cost scazut',
            description: `${ basePrice * 2 / 3 } RON`,
        },
        {
            value: `${ basePrice }`,
            label: 'Cost normal',
            description: `${ basePrice } RON`,
        },
        {
            value: `${ basePrice * 3 / 2 }`,
            label: 'Cost ridicat',
            description: `${ basePrice * 3 / 2 } RON`,
        },
        {
            label: 'Altul',
            value: 'other',
        },
    ];

    return result;
};

class Modal extends Component {

    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        clientId: -1,
        clients: [],

        headquartersId: -1,

        type: -1,
    
        warrantyFirm: -1,

        differentBeneficiary: 0,

        equipments: [ { title: '', model: '', series: '', problem: '' } ],
        equipments_chosen: [],
        equipments_all: [],

        beneficiary: '',
        title: '',
        description: '',
        phone: '',

        info: {},

        formComplete: false,

        operatorId: -1,
        
        fixedClient: -1,
        
        priority: 'normal',
    
        addEquipmentModal: false,
    
        chooseOperatorModalOpen: false,
        
        operatorSch: null,
        timeSch: null,
        dateSch: null,

        workPrices: [
            {
                label: 'Altul',
                value: 'other',
            },
        ],
        workPrice: null,
        workPriceCustom: null,

        clientInfo: null,
    };

    render () {
        return (
            <BaseModal
                title={'Adauga tichet'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
                        <TextFieldInput
                            advancedPadding
                            icon={'ft-align-justify'}
                            value={this.state.description}
                            style={{ marginBottom: 12 }}
                            label={'Detaliile generale ale tichetului'}
                            onChange={(newValue) => {
                                this.setState({
                                    description: newValue,
                                });
                            }}/>
                        
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.priority)}
        
                            icon={'ft-alert-triangle'}
                            label={'Prioritatea tichetului'}
                            options={kPriorityTypes}
                            value={this.state.priority}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    priority: value,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.type)}
        
                            icon={'ft-clipboard'}
                            label={'Tipul interventiei'}
                            options={kInterventionTypes}
                            value={this.state.type}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    type: value,
                                    warrantyFirm: -1,
                                //equipments: [{title: '', model: '', series: '', problem: ''}],
                                });
                            }}/>
    
                        {
                            this.props.user.isPerson && this.state.type == 'Garantie' &&
                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.warrantyFirm)}
            
                                icon={'ft-settings'}
                                label={'Firma pentru garantie'}
                                options={kWarrantyFirms}
                                value={this.state.warrantyFirm}
                                style={{ marginBottom: 12 }}
                                onSelect={(value) => {
                                    this.setState({
                                        warrantyFirm: value,
                                    });
                                }}/>
                        }

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId)}

                            blocked={this.state.fixedClient > 0}
                            icon={'ft-briefcase'}
                            label={'Clientul'}
                            predefinedModal={'client'}
                            value={this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId}
                            style={{ marginBottom: 12 }}
                            onSelect={(value, otherClientInfo) => {
                                console.log('other client info', otherClientInfo);
                                const workPrices = createWorkPrices(otherClientInfo.work_price);
                                console.log('work prices', workPrices);
                                this.setState({
                                    clientId: value,
                                    workPrices,
                                    workPrice: otherClientInfo.work_price,
                                    headquartersId: -1,
                                });
                            }}/>

                        <SingleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationSingleChoice(this.state.workPrice)}

                            icon={'fa-dollar-sign'}
                            label={'Costul mediu al manoperei'}
                            options={this.state.workPrices}
                            value={this.state.workPrice}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    workPrice: value,
                                });
                                if (value === 'other' && this.state.workPrices && this.state.workPrices.length > 1) {
                                    this.setState({
                                        workPriceCustom: this.state.workPrices[ 1 ].value,
                                    });
                                }
                            }}/>

                        {
                            this.state.workPrice === 'other' &&
                            <TextFieldInput
                                required
                                validated={inputValidationInteger(this.state.workPriceCustom)}
                                icon={'blank'}
                                value={this.state.workPriceCustom}
                                style={{ marginBottom: 4 }}
                                label={'Introdu costul manoperei'}
                                onChange={(newValue) => {
                                    this.setState({
                                        workPriceCustom: newValue,
                                    });
                                }}/>
                        }

                        <SingleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationSingleChoice(this.state.headquartersId)}

                            parentKey={this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId}
                            icon={'ft-map-pin'}
                            label={'Punctul de lucru'}
                            predefinedModal={'headquarters'}
                            value={this.state.headquartersId}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    headquartersId: value,
                                    equipments_chosen: [],
                                });
                                this.refreshEquipmentsAll(value);
                            }}/>
    
                        <div required requireCompletion validated={this.state.equipments_chosen.length > 0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
                            <MultipleChoiceField
                                minimumChoices={0}
                                maximumChoices={100}
    
                                icon={'ft-chevrons-right'}
                                label={'Alege utilajele cu probleme'}
                                options={this.state.equipments_all}
                                values={this.state.equipments_chosen.map((item) => { return item.id; })}
                                style={{ flex: 1 }}
                                onSelect={(values) => {
                                    const newEq = values.map((item) => { return { id: item, problem: '', title: 'utilaj neidentificat' }; });
                                    for (let i = 0; i < newEq.length; ++i) {
                                        const found = this.state.equipments_all.filter((item) => { return item.value == newEq[ i ].id; });
                                        if (found.length > 0) {
                                            newEq[ i ].title = found[ 0 ].label;
                                        }
                                    }
                                    this.setState({
                                        equipments_chosen: newEq,
                                    });
                                }}/>
                            <div>
                                <SimpleButton value={'Adauga utilaj'} icon={'ft-plus'} onPress={() => {
                                    this.setState({
                                        addEquipmentModal: true,
                                    });
                                }}/>
                            </div>
                        </div>
    
                        <AddTicketEquipments
                            equipments={this.state.equipments_chosen}
                            onChange={(newValue) => {
                                this.setState({
                                    equipments_chosen: newValue,
                                    differentBeneficiary: 0,
                                });
                            }}/>
                            
                        <AddEquipmentModal
                            open={this.state.addEquipmentModal}
                            parentKey={this.state.headquartersId}
                            parentKeySecond={this.state.clientId}
                            onClose={() => {
                                this.refreshEquipmentsAll(this.state.headquartersId);
                                this.setState({
                                    addEquipmentModal: false,
                                });
                            }}/>

                        <SingleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationSingleChoice(this.state.differentBeneficiary)}

                            icon={'ft-copy'}
                            label={'Tipul beneficiarului'}
                            options={[
                                {
                                    label: 'Clientul este si beneficiar',
                                    value: 0,
                                },
                                {
                                    label: 'Clientul NU este si beneficiar',
                                    value: 1,
                                },
                            ]}
                            value={this.state.differentBeneficiary}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    differentBeneficiary: value,
                                });
                            }}/>

                        {
                            renderIf(this.isDifferentBeneficiary,
                                <TextFieldInput
                                    required
                                    requireCompletion
                                    validated={inputValidationNonEmpty(this.state.beneficiary)}

                                    icon={'ft-paperclip'}
                                    label={'Beneficiarul'}
                                    value={this.state.beneficiary}
                                    style={{ marginBottom: 12 }}
                                    onChange={(newValue) => {
                                        this.setState({
                                            beneficiary: newValue,
                                        });
                                    }}/>)
                        }

                        <TextFieldInput
                            requireCompletion

                            icon={'ft-phone'}
                            label={'Numarul de telefon'}
                            value={this.state.phone}
                            style={{ marginBottom: 12 }}
                            onChange={(newValue) => {
                                this.setState({
                                    phone: newValue,
                                });
                            }}/>

                            
                        {
                            !this.props.user.isPerson && this.state.fixedClient <= 0 &&
                            <SingleChoiceField
                                requireCompletion
                                validated={inputValidationSingleChoice(this.state.operatorId)}
    
                                icon={'ft-user'}
                                label={'Atribuie direct operatorului (optional)'}
                                predefinedModal={'operator'}
                                value={this.state.operatorId}
                                style={{ marginBottom: 12 }}
                                onSelect={(value) => {
                                    this.setState({
                                        operatorId: value,
                                    });
                                }}/>
                        }
                        
                        {
                            this.props.user.isPerson && this.state.fixedClient <= 0 &&
                            <TextFieldDisplay
                                oneLine
                                label={'Programeaza direct operatorului'}
                                value={!this.state.operatorSch ? 'Alege operatorul in functie de program' : `${ this.state.operatorSch.first_name } ${ this.state.operatorSch.last_name } programat la ora ${ this.state.timeSch }`}
                                icon={'ft-calendar'}
                                action={'view'}
                                onPress={() => { this.setState({ chooseOperatorModalOpen: true }); }}
                                style={{ marginBottom: 12 }}/>
                        }
    
                        <SingleChoiceField
                            required
                            requireCompletion
                            
                            validated={inputValidationYesSelected(this.state.cancelValue)}
                            icon={'ft-alert-circle'}
                            label={'Esti sigur ca doresti inregistrarea tichetului?'}
                            options={[ { label: 'Da, doresc', value: 'yes' } ]}
                            value={this.state.cancelValue}
                            style={{ marginBottom: 12 }}
                            onSelect={(value) => {
                                this.setState({
                                    cancelValue: value,
                                });
                            }}/>
                    </Form>

                    <ChooseOperatorModal
                        itemKey={this.state.headquartersId}
                        open={this.state.chooseOperatorModalOpen}
                        onClose={() => { this.setState({ chooseOperatorModalOpen: false }); }}
                        onSelect={(operator, time, date) => {
                            this.props.toast(`${ operator.first_name } ${ operator.last_name } programat la ora ${ time }.`);
                            this.setState({
                                operatorSch: operator,
                                timeSch: time,
                                dateSch: date,
                            });
                        }}/>
                    
                </div>

            </BaseModal>
        );
    }
    
    refreshEquipmentsAll = (headquartersId) => {
        getAllEquipments(headquartersId, (successful, data) => {
            if (successful) {
                this.setState({
                    equipments_all: data.map((item) => { return { value: item.id, label: item.title, description: `${ item.model } - ${ item.series }` }; }),
                });
            } else {
                this.props.toast('Problema la primirea echipamentelor!', kToastActionError);
            }
        });
    }
    
    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        let beneficiary = this.state.beneficiary;
        const actualClientId = this.state.fixedClient > 0 ? this.state.fixedClient : this.state.clientId;
        if (this.state.differentBeneficiary == 0) {
            for (let i = 0; i < this.state.clients.length; ++i) {
                if (this.state.clients[ i ].value == actualClientId) {
                    beneficiary = this.state.clients[ i ].label;
                    break;
                }
            }
        }

        const request = {
            clientId: actualClientId,
            headquartersId: this.state.headquartersId,
            description: this.state.description,
            beneficiary,

            equipment: '',
            model: '',
            series: '',

            workPrice: this.state.workPrice === 'other' ? this.state.workPriceCustom : this.state.workPrice,
            
            priority: this.state.priority,

            equipments: this.state.equipments_chosen,
            type: this.state.type,

            assigned: this.state.operatorId,
        };
        
        if (this.state.operatorSch && this.state.timeSch) {
            request.operatorSch = this.state.operatorSch;
            request.timeSch = this.state.timeSch;
            request.dateSch = moment(this.state.dateSch, kDateFormatDisplay).format(kDateFormatServer);
        }

        this.props.openLoading();

        addTicket(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Tichet inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
        checkClientAssigned((hasClientAssigned, clientId) => {
            if (hasClientAssigned) {
                this.setState({
                    fixedClient: clientId,
                });
            } else {
                this.setState({
                    fixedClient: -1,
                });
            }
        });
        getAddTicketInfo((successful, data) => {
            if (successful) {
                const clients = data.clients.map((client) => {
                    return {
                        label: client.name,
                        value: parseInt(client.id),
                        headquarters: client.headquarters,
                        workPrice: parseInt(client.work_price),
                    };
                });
                this.setState({
                    clients,
                });
            } else {
                console.error('no add action info');
            }
        });
    };

    get clientHeadquarters () {
        const clients = this.state.clients.filter((client) => { return client.value == this.state.clientId; });
        if (clients.length > 0) {
            return clients[ 0 ].headquarters.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
        } else {
            return [];
        }
    }

    get clientSelected () {
        return this.state.clientId != -1;
    }

    get headquartersSelected () {
        return this.state.headquartersId != -1;
    }

    get typeSelected () {
        return this.state.type != -1;
    }

    get equipmentsSelected () {
        return this.state.equipments.length > 1 || this.state.equipments[ 0 ].title !== '';
    }

    get isDifferentBeneficiary () {
        return this.state.differentBeneficiary > 0;
    }

    get beneficiarySelected () {
        return !this.isDifferentBeneficiary || (this.isDifferentBeneficiary && this.state.beneficiary !== '');
    }

    get phoneSelected () {
        return this.state.phone != '';
    }

    readyToSend = () => {
        return false;
        //return this.clientSelected && this.headquartersSelected && this.typeSelected && this.equipmentsSelected && this.beneficiarySelected && this.phoneSelected;
    }

    clear = () => {
        this.setState({
            clientId: -1,
            clients: [],

            headquartersId: -1,
            equipments_chosen: [],

            type: -1,

            differentBeneficiary: 0,

            equipments: [ { title: '', model: '', series: '', problem: '' } ],

            beneficiary: '',
            title: '',
            description: '',
            phone: '',
            
            priority: 'normal',
            fixedClient: -1,

            info: {},
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);