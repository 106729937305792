import React from 'react';
import PropTypes from 'prop-types';
import { getAllSuppliers } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterSupplierFields, kFilterSupplierFieldsDefault } from '../functional/constants';
import AddSupplierModal from '../components/modal/AddSupplierModal';
import { toSupplierPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';
import { compareGeneratorString } from '../functional/min-support';

class SuppliersPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = SuppliersPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-suppliers-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(SuppliersPage.defaultState, this.update);
    }

    update = () => {
        getAllSuppliers((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no suppliers');
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga furnizor',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Denumirea',
                        transform: (item) => { return item.name; },
                        action: (item) => { toSupplierPage(item.id); },
                        compare: compareGeneratorString('name'),
                        icon: 'ft-align-justify',
                    },
                    'cif': {
                        title: 'CIF',
                        transform: (item) => { return item.cif; },
                        compare: compareGeneratorString('cif'),
                        icon: 'ft-hash',
                    },
                    'reg': {
                        title: 'Reg. Com.',
                        transform: (item) => { return item.reg; },
                        compare: compareGeneratorString('reg'),
                        icon: 'ft-code',
                    },
                    'bank': {
                        title: 'Banca',
                        transform: (item) => { return item.bank; },
                        compare: compareGeneratorString('bank'),
                        icon: 'ft-credit-card',
                    },
                    'iban': {
                        title: 'IBAN',
                        transform: (item) => { return item.iban; },
                        compare: compareGeneratorString('iban'),
                        icon: 'ft-info',
                    },
                    'address': {
                        title: 'Sediu',
                        transform: (item) => { return item.address; },
                        compare: compareGeneratorString('address'),
                        icon: 'ft-map-pin',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toSupplierPage(item.id); },
                        weight: 0.5,
                    },
                }}
                defaultSort={'title'}
                defaultFields={kFilterSupplierFieldsDefault}
                allFields={kFilterSupplierFields}>

                <AddSupplierModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }
}

export default withRouter(SuppliersPage);