import React from 'react';
import PropTypes from 'prop-types';
import { getAllOrders } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterOrderFields, kFilterOrderFieldsDefault } from '../functional/constants';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import AddOrderModal from '../components/modal/AddOrderModal';
import { toOrderPage, toSupplierPage, toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';
import { compareGeneratorString } from '../functional/min-support';

class OrdersPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = OrdersPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-orders-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(OrdersPage.defaultState, this.update);
    }

    update = () => {
        getAllOrders((successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    data[ index ].supplier_name = data[ index ].supplier.name;
                    data[ index ].supplier_id = data[ index ].supplier.id;
                    data[ index ].username = data[ index ].user.username;
                    data[ index ].creator_id = data[ index ].user.id;
                });
                this.setState({
                    items: data,
                });
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga comanda',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Comanda',
                        transform: (item) => { return item.name; },
                        action: (item) => { toOrderPage(item.id); },
                        compare: compareGeneratorString('name'),
                        icon: 'ft-align-justify',
                    },
                    'status': {
                        title: 'Status',
                        transform: (item) => { return item.status; },
                        compare: compareGeneratorString('status'),
                        icon: 'ft-code',
                    },
                    'supplier': {
                        title: 'Furnizor',
                        transform: (item) => { return item.supplier_name; },
                        action: (item) => { toSupplierPage(item.supplier_id); },
                        compare: compareGeneratorString('supplier_name'),
                        icon: 'ft-package',
                    },
                    'operator': {
                        title: 'Operator',
                        transform: (item) => { return item.username; },
                        action: (item) => { toUserPage(item.creator_id); },
                        compare: compareGeneratorString('username'),
                        icon: 'ft-user',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toOrderPage(item.id); },
                        weight: 0.5,
                    },
                }}
                defaultSort={'title'}
                defaultFields={kFilterOrderFieldsDefault}
                allFields={kFilterOrderFields}>

                <AddOrderModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }

}

const ListItem = (props) => {
    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>

            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#fff' : '#fff' }}>
                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.status}
                        icon={'ft-code'}
                        style={{ marginBottom: 4 }}/>

                    <TextFieldDisplay
                        oneLine
                        value={props.item.supplier.name}
                        icon={'ft-package'}
                        style={{ marginBottom: 4 }}
                        action={'ft-eye'}
                        onPress={() => { toSupplierPage(props.item.supplier.id); }}/>

                    <TextFieldDisplay
                        oneLine
                        value={`${ props.item.user.first_name } ${ props.item.user.last_name }`}
                        icon={'ft-users'}
                        style={{ marginBottom: 4 }}/>
                </div>

                <div className={'item-action-button'}>
                    <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                        {'Vezi comanda'}
                    </div>
                </div>

            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

export default withRouter(OrdersPage);