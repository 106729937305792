import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    getBriefListOperators,
    getBriefListOperatorsForStock,
    getBriefListOperatorsForStockExtended,
} from '../../../functional/api';
import PickerModal from '../model/PickerModal';
import AddOperatorModal from '../AddOperatorModal';

class Picker extends Component {

    static propTypes = {
        parentKey: PropTypes.any,
        open: PropTypes.bool,
        value: PropTypes.any,

        onClose: PropTypes.func,
        onDone: PropTypes.func,

        setLabel: PropTypes.func,

        uniqueOperator: PropTypes.any,
    };

    state = {
        options: [],

        modalOpen: false,
    };

    componentDidMount () {
        this.update();
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.parentKey != nextProps.parentKey || this.props.uniqueOperator != nextProps.uniqueOperator) {
            this.update();
        }
        if (this.props.value != nextProps.value) {
            this.updateLabel(nextProps.value);
        }
    }

    update = () => {
        if (this.props.forStock) {
            if (this.props.extended) {
                getBriefListOperatorsForStockExtended(this.props.parentKey, (successful, data) => {
                    if (successful) {

                        if (this.props.uniqueOperator) {
                            const operator = data.find((option) => { return option.value == this.props.uniqueOperator; });
                            if (operator) {
                                data = [ operator ];
                            } else {
                                data = [];
                            }
                        }

                        this.setState({
                            options: data,
                        }, () => {
                            this.updateLabel(this.props.value);
                        });
                    } else {
                        console.error('data retrieve error');
                    }
                });
            } else {
                getBriefListOperatorsForStock(this.props.parentKey, (successful, data) => {
                    if (successful) {

                        if (this.props.uniqueOperator) {
                            const operator = data.find((option) => { return option.value == this.props.uniqueOperator; });
                            if (operator) {
                                data = [ operator ];
                            } else {
                                data = [];
                            }
                        }

                        this.setState({
                            options: data,
                        }, () => {
                            this.updateLabel(this.props.value);
                        });
                    } else {
                        console.error('data retrieve error');
                    }
                });
            }
        } else {
            getBriefListOperators((successful, data) => {
                if (successful) {

                    if (this.props.uniqueOperator) {
                        const operator = data.find((option) => { return option.value == this.props.uniqueOperator; });
                        if (operator) {
                            data = [ operator ];
                        } else {
                            data = [];
                        }
                    }

                    this.setState({
                        options: data,
                    }, () => {
                        this.updateLabel(this.props.value);
                    });
                } else {
                    console.error('data retrieve error');
                }
            });
        }
    };

    render () {
        return (
            <PickerModal
                open={this.props.open}
                options={this.state.options}
                initialSelectedValues={[ this.props.value ]}

                title={'Alege operatorul'}

                search={true}
                searchInDescription={false}

                minimumChoices={1}
                maximumChoices={1}

                onClose={this.props.onClose}
                onDone={this.props.onDone}

                onMore={() => {
                    this.setState({
                        modalOpen: true,
                    });
                }}
                onMoreText={'Adauga operator'}
                onMoreIcon={'ft-user'}>

                <AddOperatorModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </PickerModal>
        );
    }

    updateLabel = (newValue) => {
        if (this.props.setLabel) {
            for (let i = 0; i < this.state.options.length; ++i) {
                if (this.state.options[ i ].value === newValue) {
                    this.props.setLabel(this.state.options[ i ].label);
                    return;
                }
            }
            this.props.setLabel(-1);
        }
    };

}

export default Picker;