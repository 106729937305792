import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class BarChart extends Component {

    render () {
        const data = {
            labels: [ 'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie' ],
            datasets: [
                {
                    label: 'Vanzari',
                    data: [ 12, 19, 3, 17, 28, 24, 7 ],
                    backgroundColor: '#443c3c',
                    borderWidth: 2,
                },
                {
                    label: 'Comenzi',
                    data: [ 30, 29, 5, 5, 20, 3, 10 ],
                    backgroundColor: '#6c1b1b',
                    borderWidth: 2,
                },
            ],
        };

        const option = {
            maintainAspectRatio: false,
            legend: {
                display: false,
                position: 'bottom',

                labels: {
                    fontColor: '#71748d',
                    fontSize: 14,
                },
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            fontSize: 14,
                            fontColor: '#71748d',
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            fontSize: 14,
                            fontColor: '#71748d',
                        },
                    },
                ],
            },
        };

        return (
            <React.Fragment>
                <Bar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default BarChart;