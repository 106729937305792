import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PageError from '../components/general/PageError';

class NotFoundProtectedPage extends Component {
    
    render () {
        return (
            <React.Fragment>
                <PageError
                    art={'•404•'}
                    title={'404 - Pagina nu exista'}
                    message={'Pagina pe care o cauti este posibil sa fie redenumita, stearsa sau indisponibila momentan. Incearca mai tarziu.'}
                    button={{ title: 'Inapoi la Dashboard', link: '/dashboard' }}/>
            </React.Fragment>
        );
    }
    
}
export default withRouter(NotFoundProtectedPage);