import React from 'react';
import PropTypes from 'prop-types';
import InternalToolbar from '../../components/general/InternalToolbar';
import { filter, smallIcon } from '../../functional/min-support';
import PickerModal from '../../components/modal/model/PickerModal';
import PersistentStateComponent from '../../components/extends/PersistentStateComponent';

class GroupTablePage extends PersistentStateComponent {

    static baseIncrement = 20;

    static loadOffset = 100;

    currentScroll = 0;

    static propTypes = {
        onRefresh: PropTypes.func,
        loading: PropTypes.bool,
        deepSearch: PropTypes.bool,

        grid: PropTypes.bool,

        actionText: PropTypes.string,
        onAction: PropTypes.func,

        allListItems: PropTypes.array,
        renderEmpty: PropTypes.func,

        allFields: PropTypes.array,
        defaultFields: PropTypes.array,

        children: PropTypes.array,
        persistentIdentifier: PropTypes.string.isRequired,

        grouped: PropTypes.bool,
    };

    constructor (props) {
        super(props);
        this.listElement = React.createRef();
        this.persistentExcludedState = [];
        this.persistentIdentifier = this.props.persistentIdentifier;
    }

    componentDidMount () {
        super.componentDidMount();
        const savedScroll = window.localStorage.getItem(`${ this.persistentIdentifier }-scroll`);
        if (savedScroll) {
            this.currentScroll = savedScroll;
        }
        setTimeout(() => {
            if (this.state.fields.length === 0 && this.props.defaultFields) {
                this.setState({
                    fields: this.props.defaultFields,
                });
            }

            if (this.state.sort === null && this.props.defaultSort) {
                this.setState({
                    sort: this.props.defaultSort,
                });
            }
        }, 500);
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (!nextProps.loading && this.props.loading) {
            setTimeout(() => {
                if (this.currentScroll && this.listElement && this.listElement.current) {
                    this.listElement.current.scrollTo(0, this.currentScroll);
                }
            }, 0);
        }
    }

    componentWillUnmount () {
        window.localStorage.setItem(`${ this.persistentIdentifier }-scroll`, `${ this.currentScroll }`);
        super.componentWillUnmount();
    }

    state = {
        search: '',
        fields: [],
        sort: null,
        sortReverse: false,
        filterModal: false,

        maxElement: GroupTablePage.baseIncrement,

        toolbarShadow: false,
    };

    lastSize = 0;

    matchFields = (fields) => {
        const matchedFields = [];
        for (let i = 0; i < this.props.allFields.length; ++i) {
            if (fields.includes(this.props.allFields[ i ].value)) {
                matchedFields.push(this.props.allFields[ i ]);
            }
        }
        return matchedFields.map((item) => { return item.label; });
    };

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
            maxElement: GroupTablePage.baseIncrement,
        });
        this.lastSize = 0;
    };

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop < GroupTablePage.loadOffset + e.target.clientHeight;
        this.currentScroll = e.target.scrollTop;
        if (bottom && this.state.maxElement > this.lastSize) {
            this.lastSize = this.state.maxElement;
            this.addToList();
        }
    };

    addToList = () => {
        this.setState({
            maxElement: this.state.maxElement + GroupTablePage.baseIncrement,
        });
    };

    render () {
        const { tableFields } = this.props;
        const tableFieldsKeys = Object.keys(tableFields);
        let nbOfCols = 0;
        let pointWeight = 0;
        tableFieldsKeys.forEach((fieldKey) => {
            const field = tableFields[ fieldKey ];
            pointWeight += field.weight ? field.weight : 1;
            nbOfCols += 1;
        });
        pointWeight = 100 / pointWeight;

        let list = [];
        if (!this.props.loading) {
            let elements = this.props.allListItems;

            if (!this.props.grouped) {
                if (this.state.sort && tableFields[ this.state.sort ] && tableFields[ this.state.sort ].compare) {
                    elements = elements.sort(tableFields[ this.state.sort ].compare);
                    if (this.state.sortReverse) {
                        elements = elements.reverse();
                    }
                }
                elements = filter(elements, this.state.fields, this.state.search, this.props.deepSearch).slice(0, this.state.maxElement);
            }

            list = elements.map((item, itemIndex) => {
                return (
                    <GroupTableListRow
                        groupTitleExtractor={this.props.groupTitleExtractor}
                        key={itemIndex} item={item}
                        tableFields={tableFields}
                        tableFieldsKeys={tableFieldsKeys}
                        nbOfCols={nbOfCols}/>);
            });
        }

        const toolbarActions = [
            {
                title: 'Filtre',
                onPress: this.openFilterModal,
                icon: { title: 'ft-filter' },
            },
        ];
        if (this.props.actions) {
            toolbarActions.push(...this.props.actions);
        }

        const toolbarLabels = [
            {
                connection: 'se cauta dupa:',
                tags: this.matchFields(this.state.fields),
            },
        ];
        if (this.props.labels) {
            toolbarLabels.push(...this.props.labels);
        }

        return (
            <React.Fragment>

                <div className={'list-page'}>

                    <InternalToolbar
                        refresh
                        onRefresh={this.props.onRefresh}
                        search
                        onSearchChange={this.searchChange}
                        searchValue={this.state.search}
                        actions={toolbarActions}
                        labels={toolbarLabels}/>

                    {
                        this.props.loading &&
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    }

                    {
                        !this.props.loading &&
                        <div className={'list-table-container'} onScroll={this.handleScroll} ref={this.listElement}>

                            <table className={'list-table'}>

                                <colgroup>
                                    {
                                        tableFieldsKeys.map((fieldKey) => {
                                            const field = tableFields[ fieldKey ];
                                            return (
                                                <col key={fieldKey} style={{ width: pointWeight * (field.weight ? field.weight : 1) }}/>
                                            );
                                        })
                                    }
                                </colgroup>

                                <thead style={{ position: 'sticky', top: 0, zIndex: 20 }}>

                                    <tr className={'table-list-item-row table-list-item-row-header'}>
                                        {
                                            tableFieldsKeys.map((fieldKey) => {
                                                const field = tableFields[ fieldKey ];
                                                return (
                                                    <th
                                                        key={fieldKey}
                                                        onClick={() => {
                                                            if (field.compare) {
                                                                this.setState({
                                                                    sort: fieldKey,
                                                                    sortReverse: this.state.sort === fieldKey && !this.state.sortReverse,
                                                                });
                                                            }
                                                        }}
                                                        className={`table-list-item-cell-header ${ field.compare ? 'table-list-item-cell-header-action' : '' }`}>
                                                        {field.icon && <span style={{ marginRight: 4 }}>{smallIcon(field.icon)}</span>}
                                                        {field.title}
                                                        {this.state.sort === fieldKey && !this.state.sortReverse && <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-down')}</span>}
                                                        {this.state.sort === fieldKey && this.state.sortReverse && <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-up')}</span>}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>

                                </thead>

                                <tbody>

                                    {
                                        list
                                    }

                                </tbody>

                            </table>

                        </div>
                    }

                </div>

                <PickerModal
                    title={'Alege campurile cautarii'}
                    open={this.state.filterModal}
                    options={this.props.allFields}
                    initialSelectedValues={this.state.fields}
                    minimumChoices={1}
                    maximumChoices={100}
                    onClose={this.closeFilterModal}
                    onDone={this.doneFilterModal}
                    returnMatchedFields/>

                {
                    this.props.children
                }

            </React.Fragment>
        );
    }

    renderEmpty = () => {
        return <div className={'grid-list-item-empty'}/>;
    }

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };
}

const GroupTableListRow = ({ item, tableFields, tableFieldsKeys, nbOfCols, groupTitleExtractor }) => {
    const elements = item.elements;
    const groupTitle = groupTitleExtractor(elements[ 0 ]);
    return (
        <>
            <tr className={'table-list-item-row'}>
                <td colSpan={nbOfCols} className={'table-list-item-cell-title'}>
                    {groupTitle}
                </td>
            </tr>
            {
                elements.map((element, elementIndex) => {
                    return (
                        <tr key={elementIndex} className={`table-list-item-row${ elementIndex === elements.length - 1 ? '-last-in-group' : '' }`}>
                            {
                                tableFieldsKeys.map((fieldKey) => {
                                    const field = tableFields[ fieldKey ];
                                    return (
                                        <td
                                            key={fieldKey}
                                            className={`table-list-item-cell ${ field.action ? 'table-list-item-cell-action' : '' }`}
                                            onClick={() => {
                                                if (field.action) {
                                                    field.action(element);
                                                }
                                            }}>
                                            {
                                                !field.custom && field.transform(element)
                                            }

                                            {
                                                field.custom && field.custom(element)
                                            }
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    );
                })
            }
        </>
    );
};

export default GroupTablePage;