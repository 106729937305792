import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addEquipment } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import { inputValidationNonEmpty } from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {

        title: '',
        model: '',
        series: '',

        formComplete: false,
        
    };

    render () {

        return (
            <BaseModal
                title={'Adauga utilaj'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.title)}

                            icon={'ft-align-justify'}
                            value={this.state.title}
                            style={{ marginBottom: 12 }}
                            label={'Denumirea utilajului'}
                            onChange={(newValue) => {
                                this.setState({
                                    title: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'ft-cpu'}
                            value={this.state.model}
                            style={{ marginBottom: 12 }}
                            label={'Modelul utilajului'}
                            onChange={(newValue) => {
                                this.setState({
                                    model: newValue,
                                });
                            }}/>
    
                        <TextFieldInput
                            icon={'ft-hash'}
                            value={this.state.series}
                            label={'Seria utilajului'}
                            onChange={(newValue) => {
                                this.setState({
                                    series: newValue,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            headquartersId: this.props.parentKey,
            clientId: this.props.parentKeySecond,
            title: this.state.title,
            model: this.state.model,
            series: this.state.series,
        };

        this.props.openLoading();

        addEquipment(request, (successful, data) => {
            if (successful) {
                this.props.closeLoading();
                this.onClose();
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
                this.props.closeLoading();
                this.onClose();
            }
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
    
    };

    clear = () => {
        this.setState({
            name: '',
            model: '',
            series: '',
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);