import React from 'react';
import PropTypes from 'prop-types';
import TextFieldInput from '../input/TextFieldInput';
import TextFieldDisplay from '../input/TextFieldDisplay';

class AddTicketEquipmentsSingle extends React.Component {
    
    static propTypes = {
        item: PropTypes.object,
        nb: PropTypes.number,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
    };
    
    state = {
    
    };
    
    render () {
        
        return (
            <div className={'add-ticket-equipments-single'}>
                <div className={'m-field'} style={{ width: '100%', marginBottom: 4 }}>
                    <TextFieldDisplay
                        value={this.props.item.title}
                        icon={'ft-chevron-right'}
                        label={'descrierea utilajului'}
                        placeholder={'Introduceti descrierea utilajului'}/>
                </div>

                <TextFieldInput
                    advancedPadding
                    icon={'blank'}
                    value={this.props.item.problem}
                    label={'problema utilajului'}
                    placeholder={'Introduceti problema utilajului'}
                    onChange={this.problemChangeText}/>
            </div>
        );
    }
    
    titleChange = (event) => {
        const item = this.props.item;
        item.title = event.target.value;
        this.onChange(item);
    };

    titleChangeText = (newValue) => {
        const item = this.props.item;
        item.title = newValue;
        this.onChange(item);
    };
    
    problemChange = (event) => {
        const item = this.props.item;
        item.problem = event.target.value;
        this.onChange(item);
    };

    problemChangeText = (newValue) => {
        const item = this.props.item;
        item.problem = newValue;
        this.onChange(item);
    };
    
    modelChange = (event) => {
        const item = this.props.item;
        item.model = event.target.value;
        this.onChange(item);
    };

    modelChangeText = (newValue) => {
        const item = this.props.item;
        item.model = newValue;
        this.onChange(item);
    };
    
    seriesChange = (event) => {
        const item = this.props.item;
        item.series = event.target.value;
        this.onChange(item);
    };

    seriesChangeText = (newValue) => {
        const item = this.props.item;
        item.series = newValue;
        this.onChange(item);
    };
    
    onChange = (item) => {
        this.props.onChange(this.props.nb, item);
    };
    
    onRemove = () => {
        this.props.onRemove(this.props.nb);
    };
    
}

export default AddTicketEquipmentsSingle;