import React from 'react';
import PropTypes from 'prop-types';
import { getAllMail } from '../functional/api';
import { kFilterMailFields, kFilterMailFieldsDefault } from '../functional/constants';
import { withRouter } from 'react-router-dom';
import AddMailModal from '../components/modal/AddMailModal';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';
import { compareGeneratorString, prettyDateAndTime } from '../functional/min-support';


class MailPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = MailPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-mail-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(MailPage.defaultState, this.update);
    }

    update = () => {
        getAllMail((successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    let senderAddress = '';

                    if (data[ index ].fromName) {
                        senderAddress += `${ data[ index ].fromName } <`;
                    }
                    if (data[ index ].fromAddress) {
                        senderAddress += data[ index ].fromAddress;
                    }
                    if (data[ index ].fromName) {
                        senderAddress += '>';
                    }

                    data[ index ].sender_address = senderAddress;
                });
                this.setState({
                    items: data,
                });
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={true}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Scrie email',
                        icon: { title: 'ft-send' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Subiect',
                        transform: (item) => { return item.subject; },
                        compare: compareGeneratorString('subject'),
                        icon: 'ft-align-justify',
                    },
                    'date': {
                        title: 'Data',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGeneratorString('created', true),
                        icon: 'ft-calendar',
                    },
                    'sender': {
                        title: 'Sursa',
                        transform: (item) => { return item.sender_address; },
                        compare: compareGeneratorString('sender_address'),
                        icon: 'ft-at-sign',
                    },
                    'client': {
                        title: 'Client',
                        transform: (item) => { return item.fromName; },
                        compare: compareGeneratorString('fromName'),
                        icon: 'ft-briefcase',
                    },
                }}
                defaultSort={'date'}
                defaultFields={kFilterMailFieldsDefault}
                allFields={kFilterMailFields}>

                <AddMailModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }
}

export default withRouter(MailPage);