import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBriefListClients } from '../../../functional/api';
import AddClientModal from '../AddClientModal';
import PickerModal from '../model/PickerModal';

class Picker extends Component {

    static propTypes = {
        open: PropTypes.bool,
        value: PropTypes.any,

        onClose: PropTypes.func,
        onDone: PropTypes.func,

        setLabel: PropTypes.func,
    };

    state = {
        options: [],

        modalOpen: false,
    };

    componentDidMount () {
        this.update();
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.value != nextProps.value) {
            this.updateLabel(nextProps.value);
        }
    }

    update = () => {
        getBriefListClients((successful, data) => {
            if (successful) {
                this.setState({
                    options: data,
                }, () => {
                    this.updateLabel(this.props.value);
                });
            } else {
                console.error('data retrieve error');
            }
        });
    };

    render () {
        return (
            <PickerModal
                open={this.props.open}
                options={this.state.options}
                initialSelectedValues={[ this.props.value ]}

                title={'Alege clientul'}

                search={true}
                searchInDescription={false}

                minimumChoices={1}
                maximumChoices={1}

                onClose={this.props.onClose}
                onDone={this.onDoneAddData}

                onMore={() => {
                    this.setState({
                        modalOpen: true,
                    });
                }}
                onMoreText={'Adauga client'}
                onMoreIcon={'ft-briefcase'}>

                <AddClientModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </PickerModal>
        );
    }
    
    onDoneAddData = (selectedValues) => {
        const selectedValue = selectedValues[ 0 ];
        let allData = null;
        for (let i = 0; i < this.state.options.length; ++i) {
            if (this.state.options[ i ].value === selectedValue) {
                allData = this.state.options[ i ];
                break;
            }
        }
        this.props.onDone(selectedValues, allData);
    }

    updateLabel = (newValue) => {
        if (this.props.setLabel) {
            for (let i = 0; i < this.state.options.length; ++i) {
                if (this.state.options[ i ].value === newValue) {
                    this.props.setLabel(this.state.options[ i ].label);
                    return;
                }
            }
            this.props.setLabel(-1);
        }
    };

}

export default Picker;