import React from 'react';
import PropTypes from 'prop-types';
import AddTicketEquipmentsSingle from './AddTicketEquipmentsSingle';

class AddTicketEquipments extends React.Component {
    
    identifier = 0;
    
    static propTypes = {
        equipments: PropTypes.array,
        onChange: PropTypes.func,
    };
    
    state = {
    
    };
    
    render () {
        
        return (
            <div className={'w-100'}>
    
                {
                    (this.identifier = 0) || this.props.equipments.map((item) => {
                        const key = this.identifier;
                        this.identifier ++;
                        return (
                            <AddTicketEquipmentsSingle key={key} nb={key} item={item} onChange={this.onChange}/>
                        );
                    })
                }
                
            </div>
        );
    }
    
    onAddNew = () => {
        const equipments = this.props.equipments;
        equipments.push({ title: '', model: '', series: '', problem: '' });
        this.props.onChange(equipments);
    };
    
    onChange = (nb, item) => {
        const equipments = this.props.equipments;
        equipments[ nb ] = item;
        this.props.onChange(equipments);
    };
    
    onRemove = (nb) => {
        const equipments = this.props.equipments;
        equipments.splice(nb, 1);
        this.props.onChange(equipments);
    }
}

export default AddTicketEquipments;