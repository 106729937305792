import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closePDFViewModal, closeUpdateModal } from '../../store/actions/application-store-actions';

import UpdateModal from './UpdateModal';
import LoadingModal from './LoadingModal';
import PDFViewModal from './PDFViewModal';
import ChangeEmailAddressModal from './ChangeEmailAddressModal';

class Modals extends Component {
    
    static propTypes = {
        history: PropTypes.object,
        
        updateModal: PropTypes.bool,
        loadingModal: PropTypes.bool,
        
        onCloseUpdateModal: PropTypes.func,
    };
    
    render () {
        return (
            <div>
                <UpdateModal open={this.props.updateModal} onClose={this.props.onCloseUpdateModal}/>
                <LoadingModal open={this.props.loadingModal}/>
                <ChangeEmailAddressModal open={this.props.addEmailModal}/>
                
                <PDFViewModal open={this.props.pdfViewModal} onClose={this.props.onClosePDFViewModal}/>
            </div>
        );
    }
    
}

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
        loadingModal: state.modals.loadingModal,
        addEmailModal: state.modals.addEmailModal,
    
        pdfViewModal: state.modals.pdfViewModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseUpdateModal: () => { return dispatch(closeUpdateModal()); },
        onClosePDFViewModal: () => { return dispatch(closePDFViewModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Modals));