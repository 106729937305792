import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import MetisMenu from 'metismenujs';

import sidebarRoutes from '../functional/sidebar-routes';
import sidebarRoutesManager from '../functional/sidebar-routes-manager';
import sidebarRoutesUser from '../functional/sidebar-routes-user';
import { smallIcon } from '../functional/min-support';
import { connect } from 'react-redux';

class Sidebar extends Component {

    constructor (props) {
        super(props);
        this.state = {};
    }

    update = () => {
        let matchingMenuItem = null;
        const ul = document.getElementById('menu');
        const items = ul.getElementsByTagName('a');
        for (let i = 0; i < items.length; ++i) {
            this.disableParentDropdown(items[ i ]);
        }
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[ i ].pathname) {
                matchingMenuItem = items[ i ];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.update();
        }
    }

    componentDidMount () {
        new MetisMenu('#menu', {});
        this.update();
    }

    activateParentDropdown = (item) => {
        item.classList.add('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show');
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[ 0 ].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('active');
                    }
                }
            }
            return false;
        }
    }

    disableParentDropdown = (item) => {
        item.classList.remove('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.remove('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.remove('mm-show');
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.remove('mm-active'); // li
                    parent3.childNodes[ 0 ].classList.remove('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.remove('active');
                    }
                }
            }
            return false;
        }
    }

    mouseEnter = () => {
        return;
        if (!this.props.drawerOpen) {
            const pageClass = document.querySelector('.page-container');
            pageClass.classList.remove('sidebar_collapsed');
        }
    }

    mouseLeave = () => {
        return;
        if (!this.props.drawerOpen) {
            const pageClass = document.querySelector('.page-container');
            pageClass.classList.add('sidebar_collapsed');
        }
    }

    render () {
        let routes = sidebarRoutes;
        if (this.props.user.level == '2') {
            routes = sidebarRoutesManager;
        } else if (this.props.user.level == '3') {
            routes = sidebarRoutesUser;
        }
        return (
            <React.Fragment>
                <div className="left side-menu">
                    <div className="sidebar-menu light-sidebar" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                        <div className="main-menu">
                            <div className="menu-inner" id="sidebar_menu">
                                <nav>
                                    <ul className="metismenu" id="menu">
                                        {
                                            routes.map((sidebarRoute) => {
                                                const isParent = Boolean(sidebarRoute.children);
                                                return (
                                                    <li>
                                                        <Link to={isParent ? '/#' : sidebarRoute.path}>
                                                            {
                                                                smallIcon(sidebarRoute.icon)
                                                            }
                                                            <span> {sidebarRoute.title}</span>
                                                        </Link>
                                                        {
                                                            isParent &&
                                                                <ul className="submenu">
                                                                    {
                                                                        sidebarRoute.children.map((childRoute) => {
                                                                            return (
                                                                                <li>
                                                                                    <Link to={childRoute.path}>
                                                                                        {
                                                                                            smallIcon(childRoute.icon)
                                                                                        }
                                                                                        <span> {childRoute.title}</span>
                                                                                    </Link>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                        }
                                                    </li>
                                                );
                                            })
                                        }

                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        drawerOpen: state.drawerOpen,
    };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
