import { trimString, updateObject } from '../../functional/min-support';
import {
    kActionApplicationModalCloseAddEmailModal,
    kActionApplicationModalCloseLoadingModal,
    kActionApplicationModalClosePDFViewModal,
    kActionApplicationModalCloseUpdateModal,
    kActionApplicationModalOpenAddEmailModal,
    kActionApplicationModalOpenLoadingModal,
    kActionApplicationModalOpenPDFViewModal,
    kActionApplicationModalOpenUpdateModal,
    kActionApplicationPopulateUser,
    kActionApplicationSetAuthenticated,
    kActionApplicationSetItemPageTitle,
    kActionApplicationSnackAdd,
    kActionApplicationSnackRemove,
    kActionApplicationToggleDrawer,
} from '../actions/application-store-actions';
import { translateUser } from '../../functional/logic';

const initialState = {
    isAuthenticated: false,
    user: {
        firstName: '',
        lastName: '',
        username: '',
        uid: '',
        level: 0,
        phone: '',
        email: '',
        hasCustomLogo: '',
        isPerson: false,
    },
    
    drawerOpen: true,
    
    modals: {
        updateModal: false,
        loadingModal: false,
        addEmailModal: false,
    
        pdfViewModal: false,
    },
    
    pdfViewModalInfo: {
        url: '',
        data: null,
    },
    
    updateModalInfo: {
        id: '',
        previousValue: '',
        type: '',
        on: '',
    },
    
    itemPageTitle: 'Se incarca...',
    snack: [],
    snackMonitor: 0,
};

const reducer = (state = initialState, action = null) => {
    if (action.type === kActionApplicationSetAuthenticated) {
        return setAuthenticated(state, action.value, action.info);
    } else if (action.type === kActionApplicationPopulateUser) {
        return populate(state, action.data);
    } else if (action.type === kActionApplicationToggleDrawer) {
        return toggleDrawer(state);
    } else if (action.type === kActionApplicationSetItemPageTitle) {
        return setItemPageTitle(state, action.value);
    } else if (action.type === kActionApplicationModalOpenUpdateModal) {
        return openUpdateModal(state, action);
    } else if (action.type === kActionApplicationModalCloseUpdateModal) {
        return closeUpdateModal(state);
    } else if (action.type === kActionApplicationModalOpenPDFViewModal) {
        return openPDFViewModal(state, action.data, action.info);
    } else if (action.type === kActionApplicationModalClosePDFViewModal) {
        return closePDFViewModal(state);
    } else if (action.type === kActionApplicationModalOpenLoadingModal) {
        return openLoadingModal(state);
    } else if (action.type === kActionApplicationModalCloseLoadingModal) {
        return closeLoadingModal(state);
    } else if (action.type === kActionApplicationModalOpenAddEmailModal) {
        return openAddEmailModal(state);
    } else if (action.type === kActionApplicationModalCloseAddEmailModal) {
        return closeAddEmailModal(state);
    } else if (action.type === kActionApplicationSnackAdd) {
        return snackAdd(state, action.label, action.action);
    } else if (action.type === kActionApplicationSnackRemove) {
        return snackRemove(state);
    } else {
        return state;
    }
};

const setAuthenticated = (state, value, info) => {
    let user = null;
    if (info) {
        user = translateUser(info);
    }
    return updateObject(state, {
        isAuthenticated: value,
        user,
    });
};

const populate = (state, data) => {
    const user = translateUser(data);
    return updateObject(state, {
        user,
    });
};

const toggleDrawer = (state) => {
    return updateObject(state, {
        drawerOpen: !state.drawerOpen,
    });
};

const openUpdateModal = (state, action) => {
    const modals = state.modals;
    modals.updateModal = true;
    const values = {
        id: action.id,
        previousValue: action.previousValue,
        type: action.updateType,
        on: action.on,
    };
    return updateObject(state, {
        modals,
        updateModalInfo: values,
    });
};

const closeUpdateModal = (state) => {
    const modals = state.modals;
    modals.updateModal = false;
    const values = {
        id: 0,
        previousValue: '',
        type: '',
        on: '',
    };
    return updateObject(state, {
        modals,
        updateModalInfo: values,
    });
};

const openPDFViewModal = (state, link, data = null) => {
    const modals = state.modals;
    modals.pdfViewModal = true;
    return updateObject(state, {
        modals,
        pdfViewModalInfo: {
            url: link,
            data,
        },
    });
};

const closePDFViewModal = (state) => {
    const modals = state.modals;
    modals.pdfViewModal = false;
    return updateObject(state, {
        modals,
        pdfViewModalInfo: {
            url: '',
            data: null,
        },
    });
};

const openLoadingModal = (state, action) => {
    const modals = state.modals;
    modals.loadingModal = true;
    return updateObject(state, {
        modals,
    });
};

const closeLoadingModal = (state) => {
    const modals = state.modals;
    modals.loadingModal = false;
    return updateObject(state, {
        modals,
    });
};

const openAddEmailModal = (state) => {
    const modals = state.modals;
    modals.addEmailModal = true;
    return updateObject(state, {
        modals,
    });
};

const closeAddEmailModal = (state) => {
    const modals = state.modals;
    modals.addEmailModal = false;
    return updateObject(state, {
        modals,
    });
};


const snackAdd = (state, label, action) => {
    const snack = state.snack;
    snack.push({ label, action });
    return updateObject(state, {
        snack,
        snackMonitor: state.snackMonitor + 1,
    });
};

const snackRemove = (state) => {
    const snack = state.snack;
    snack.shift();
    return updateObject(state, {
        snack,
    });
};

const setItemPageTitle = (state, value) => {
    let newValue = 'Se incarca...';
    if (value) {
        newValue = value;
    }
    return updateObject(state, {
        itemPageTitle: trimString(newValue, 30),
    });
};

export default reducer;