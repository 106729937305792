export const kLSSessionToken = 'polidom-dashboard-session-token';
export const kLSUserUid = 'polidom-dashboard-uid';

export const kLSUserUsername = 'polidom-dashboard-username-login';

export const getLoginUsername = () => {
    const username = localStorage.getItem(kLSUserUsername);
    return username;
};

export const saveLoginUsername = (value) => {
    localStorage.setItem(kLSUserUsername, value);
};

export const deleteLoginUsername = () => {
    localStorage.removeItem(kLSUserUsername);
};

export const getCredentials = () => {
    const userUid = localStorage.getItem(kLSUserUid);
    const token = localStorage.getItem(kLSSessionToken);
    return { username: userUid, token };
};

export const saveCredentials = (userUid, token) => {
    localStorage.setItem(kLSUserUid, userUid);
    localStorage.setItem(kLSSessionToken, token);
};

export const deleteCredentials = () => {
    localStorage.removeItem(kLSUserUid);
    localStorage.removeItem(kLSSessionToken);
};