import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { snackAdd } from '../../../store/actions/application-store-actions';
import PropTypes from 'prop-types';
import BaseModal from './BaseModal';
import TextFieldDisplay from '../../input/TextFieldDisplay';


class ImageUploadModal extends Component {
    static propTypes = {
        open: PropTypes.bool,

        title: PropTypes.string,

        onClose: PropTypes.func,
        onMore: PropTypes.func,
        onDone: PropTypes.func,

        onMoreText: PropTypes.string,

        children: PropTypes.array,
    };
    
    state = {
        image: null,
        imageURL: null,
    };
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update(nextProps);
        }
    }

    clear = () => {
        this.setState({
            image: null,
        });
    };
    
    update = (props) => {
        this.clear();
    };
    
    onChange = (event) => {
        this.setState({
            image: event.target.files[ 0 ],
            imageURL: URL.createObjectURL(event.target.files[ 0 ]),
        });
    }

    render () {
        return (
            <BaseModal
                open={this.props.open}
                hideDone={false}
                doneActive={this.inParameters}
                stableHeight={false}
                stableWidth={true}

                title={this.props.title}

                onMore={this.props.onMore}
                onMoreText={this.props.onMoreText}

                onClose={this.props.onClose}
                onDone={this.onDone}>

                <div className={'image-upload-modal'}>
                    <input ref={(ref) => { return this.uploadElement = ref; }} type={'file'} id={'file-upload-element'} onChange={this.onChange} style={{ width: 0, height: 0 }} accept="image/*"/>
                    <TextFieldDisplay
                        label={this.state.image !== null ? 'Ai ales o imagine' : 'Inca nu ai ales o imagine'}
                        value={this.state.image !== null ? 'Schimba' : 'Alege'}
                        icon={'ft-layers'}
                        action={'edit'}
                        onPress={() => {
                            if (this.uploadElement) {
                                this.uploadElement.click();
                            }
                        }}
                        style={{ marginBottom: 4 }}/>
                        
                    {
                        this.state.image !== null &&
                        <div className={'image-container'}>
                            <img src={this.state.imageURL} className={'image-okay'}/>
                        </div>
                    }
                </div>

                {
                    this.props.children
                }

            </BaseModal>
        );
    }

    get inParameters () {
        return this.state.image !== null;
    }

    onDone = () => {
        this.props.onDone(this.state.image, this.state.imageURL);
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ImageUploadModal));