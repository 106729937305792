import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


class PageError extends Component {
    render () {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>{this.props.art}</h1>
                    </div>
                    <h2>{this.props.title}</h2>
                    <p>{this.props.message}</p>
                    {
                        this.props.button && this.props.button.link &&
                        <Link to={this.props.button.link} class="btn btn-secondary btn-rounded">{this.props.button.title}</Link>
                    }
    
                    {
                        this.props.button && this.props.button.action &&
                        <Link to={'#'} onClick={this.props.button.action} class="btn btn-secondary btn-rounded">{this.props.button.title}</Link>
                    }
                    
                </div>
            </div>
        );
    }
}

export default withRouter(PageError);