import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { smallIcon } from '../../functional/min-support';

class SimpleButton extends Component {
    
    static propTypes = {
        value: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.number,
        style: PropTypes.object,
        
        onPress: PropTypes.func,
    };
    
    render () {
        return (
            <div className="btn btn-rounded btn-invisible-primary" onClick={this.props.onPress ? this.props.onPress : () => {}} style={this.props.style ? this.props.style : {}}>
                {
                    this.props.icon && smallIcon(this.props.icon, this.props.color ? this.props.color : '#544c4c', this.props.size ? this.props.size : 14, { paddingRight: 4 })
                }
                {this.props.value}
            </div>
        );
    }
    
}

export default SimpleButton;