import React from 'react';
import './App.scss';
import { verifyToken } from './functional/api';
import routes, { routeNotFoundProtected, routeNotFoundPublic } from './functional/routes';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingAnimation from './components/general/LoadingAnimation';
import PageLayout from './layout/PageLayout';

/*
 * ============ Font Awesome Css =============*
 * import './assets/css/font-awesome.min.css';
 */

import 'font-awesome/css/font-awesome.min.css';
//============ Flag Icons Css =============*
import './assets/css/flag-icon.min.css';
//============ Themify Icons Css =============*
import './assets/css/themify-icons.css';
//============ Ionicons Css =============*
import './assets/css/ionicons.min.css';
//============ Ladda Buttons Css =============*
import './assets/css/ladda.min.css';
//============ EtLine Css =============*
import './assets/css/et-line.css';
//============ Feather Css =============*
import './assets/css/feather.css';
import { bindHistory } from './functional/navigation';
import { openAddEmailModal, setAuthenticated } from './store/actions/application-store-actions';

class App extends React.Component {
    state = {
        tokenVerified: false,
        loading: true,
    };

    componentDidMount () {
        bindHistory(this.props.history);
        verifyToken((successful, result) => {
            if (successful) {
                this.props.onSetAuthenticated(true, result);
                if (!result.email) {
                    this.props.onOpenAddEmailModal();
                }
                if (this.isCurrentPathPublic()) {
                    this.props.history.push('/dashboard');
                }
            } else {
                this.props.onSetAuthenticated(false);
                if (!this.isCurrentPathPublic()) {
                    this.props.history.push('/login');
                }
            }
            this.setState({
                tokenVerified: true,
            });
        });

        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 1000);
    }

    isCurrentPathPublic = () => {
        const currentPath = this.props.history.location.pathname;
        let found = false;
        if (currentPath === '/' || currentPath === '') {
            found = true;
        } else {
            routes.some((route) => {
                if (route.public && route.path !== '/' && currentPath.startsWith(route.path)) {
                    found = true;
                    return true;
                }
                return false;
            });
        }
        return found;
    }

    render () {
        //let version = this.state.latestUpdate.version;
        const stillLoading = !this.state.tokenVerified || this.state.loading;
        if (stillLoading) {
            return (
                <div className={'App'} style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingAnimation/>
                </div>
            );
        } else {
            if (this.props.isAuthenticated) { // TODO: is darker needed here?
                return (
                    <div className={'App darker'}>
                        <PageLayout>
                            <Switch>
                                <Redirect exact from="/" to="/dashboard" />
                                {
                                    routes.map((route) => {
                                        if (!route.public) {
                                            return (
                                                <Route exact={true} path={route.path} component={route.component} key={route.id}/>
                                            );
                                        }
                                        return null;
                                    })
                                }
                                <Route component={routeNotFoundProtected.component} key={routeNotFoundProtected.id}/>

                            </Switch>
                        </PageLayout>
                    </div>
                );
            } else {
                return (
                    <div className={'App'}>
                        <Switch>
                            <Redirect exact from="/" to="/login" />
                            {
                                routes.map((route) => {
                                    if (route.public) {
                                        return (
                                            <Route exact={true} path={route.path} component={route.component} key={route.id}/>
                                        );
                                    }
                                    return null;
                                })
                            }
                            <Route component={routeNotFoundPublic.component} key={routeNotFoundPublic.id}/>
                        </Switch>
                    </div>
                );
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated,
        drawerOpen: state.drawerOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAuthenticated: (value, info = null) => { return dispatch(setAuthenticated(value, info)); },
        onOpenAddEmailModal: () => { return dispatch(openAddEmailModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));


// const App = () => {
//
//     /**
//      * @label Hooks : Start
//      */
//
//     const dispatch = useDispatch();
//     const history = useHistory();
//
//     /**
//      * @label Hooks : End
//      */
//
//     /**
//      * @label State : Start
//      */
//
//     const [tokenVerified, setTokenVerified] = useState(false);
//     const [loading, setLoading] = useState(false);
//
//     /**
//      * @label State : End
//      */
//
//     /**
//      * @label Store : Start
//      */
//
//     const drawerOpen = useSelector(applicationDrawerOpenSelector);
//     const isAuthenticated = useSelector(applicationIsAuthenticatedSelector);
//
//     /**
//      * @label Store : End
//      */
//
//     /**
//      * @label Functionality : Start
//      */
//
//     const isCurrentPathPublic = (currentPath) => {
//         //let currentPath = history.location.pathname;
//         let found = false;
//         if(currentPath === '/' || currentPath === '') {
//             found = true;
//         } else {
//             routes.some((route) => {
//                 if (route.public && route.path !== '/' && currentPath.startsWith(route.path)) {
//                     found = true;
//                     return true;
//                 }
//             });
//         }
//         return found;
//     };
//
//     /**
//      * @label Functionality : End
//      */
//
//     /**
//      * @label Effects : Start
//      */
//
//     useEffect(() => {
//         bindHistory(history);
//         verifyToken((successful, result) => {
//             if(successful) {
//                 dispatch(applicationSetIsAuthenticated(true));
//                 dispatch(applicationSetUser(result));
//
//                 if(!result.email) {
//                     dispatch(applicationOpenAddEmailModal());
//                 }
//
//                 if(isCurrentPathPublic(history.location.pathname)) {
//                     history.push('/dashboard');
//                 }
//             } else {
//                 dispatch(applicationSetIsAuthenticated(false));
//                 dispatch(applicationSetUser(null));
//                 if(!isCurrentPathPublic(history.location.pathname)) {
//                     this.props.history.push('/login');
//                 }
//             }
//             setTokenVerified(true);
//         });
//
//         setTimeout(() => {
//             setLoading(false);
//         }, 1000);
//     }, []);
//
//     /**
//      * @label Effects : End
//      */
//
//     /**
//      * @label Render
//      */
//
//     let stillLoading = !tokenVerified || loading;
//     if(stillLoading) {
//         return (
//             <div className={'App'} style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
//                 <LoadingAnimation/>
//             </div>
//         )
//     } else {
//         if(isAuthenticated) {
//             return (
//                 <div className={'App darker'}>
//                     <PageLayout>
//                         <Switch>
//                             <Redirect exact from="/" to="/dashboard" />
//                             {
//                                 routes.map((route) => {
//                                     if(!route.public) {
//                                         return (
//                                             <Route exact={true} path={route.path} component={route.component} key={route.id}/>
//                                         );
//                                     }
//                                     return null;
//                                 })
//                             }
//                             <Route component={routeNotFoundProtected.component} key={routeNotFoundProtected.id}/>
//
//                         </Switch>
//                     </PageLayout>
//                 </div>
//             )
//         } else {
//             return (
//                 <div className={'App'}>
//                     <Switch>
//                         <Redirect exact from="/" to="/login" />
//                         {
//                             routes.map((route) => {
//                                 if(route.public) {
//                                     return (
//                                         <Route exact={true} path={route.path} component={route.component} key={route.id}/>
//                                     );
//                                 }
//                                 return null;
//                             })
//                         }
//                         <Route component={routeNotFoundPublic.component} key={routeNotFoundPublic.id}/>
//                     </Switch>
//                 </div>
//             )
//         }
//     }
// }
//
// export default App;
//