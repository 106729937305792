import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PickerModal from '../modal/model/PickerModal';
import { renderIf } from '../../functional/min-support';
import SupplierMultiplePicker from '../modal/pickers/SupplierMultiplePicker';
import TextFieldDisplay from './TextFieldDisplay';

class MultipleChoiceField extends Component {
    
    static propTypes = {
        label: PropTypes.string,

        options: PropTypes.array,
        values: PropTypes.array,
        placeholder: PropTypes.string,
        onSelect: PropTypes.func,

        search: PropTypes.bool,
        searchInDescription: PropTypes.bool,

        style: PropTypes.object,

        icon: PropTypes.string,

        onMore: PropTypes.func,
        onMoreText: PropTypes.string,

        fetchOptions: PropTypes.func,

        predefinedModal: PropTypes.string,
        parentKey: PropTypes.any,

        required: PropTypes.bool,
        validated: PropTypes.func,
        requireCompletion: PropTypes.bool,
        
        minimumChoices: PropTypes.number,
        maximumChoices: PropTypes.number,
        
        displayLabelsBelow: PropTypes.bool,
    };

    state = {
        modalOpen: false,

        predefinedLabel: -1,
        labels: [],
    };
    
    render () {
        let style = {};
        const textStyle = {};
        let action = 'done';
        let actionColor = '#0f4a4788';
        if (this.props.style) style = { ...this.props.style };
        if (this.isPlaceholder()) {
            textStyle.color = '#A9A9A9';
            action = 'clear';
            actionColor = '#66262888';
        }
        return (
            <div className={'MultipleChoiceField'} style={style}>

                <TextFieldDisplay
                    label={this.label}
                    value={this.getLabel()}
                    icon={this.props.icon}
                    action={action}
                    actionColor={actionColor}
                    onPress={this.onModalOpen}
                    textStyle={textStyle}
                    hideLabel={this.isPlaceholder()}/>
                    
                {
                    renderIf(this.props.displayLabelsBelow && this.state.labels.length > 0,
                        <div className={'multiple-choice-labels-container'}>
                            {
                                this.state.labels.map((label, key) => {
                                    return (
                                        <div key={key} className={'multiple-choice-label-container'}>
                                            {
                                                label
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>)
                }

                {
                    renderIf(this.props.predefinedModal,
                        this.getPredefinedModal())
                }

                {
                    renderIf(!this.props.predefinedModal,
                        <PickerModal
                            search={this.props.search}
                            searchInDescription={this.props.searchInDescription}
                            title={this.props.label}
                            open={this.state.modalOpen}
                            options={this.props.options}
                            initialSelectedValues={this.props.values}
                            minimumChoices={this.props.minimumChoices}
                            maximumChoices={this.props.maximumChoices}
                            onMore={this.props.onMore}
                            onMoreText={this.props.onMoreText}
                            onClose={this.onModalClose}
                            onDone={this.onModalDone}/>)
                }
            
            </div>
        );
    }

    getPredefinedModal = () => {
        switch (this.props.predefinedModal) {
        case 'supplier':
            return (
                <SupplierMultiplePicker
                    minimumChoices={0}
                    maximumChoices={100}
                    open={this.state.modalOpen}
                    values={this.props.values}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    setLabels={(labels) => {
                        this.setState({
                            labels,
                        });
                    }}/>
            );
        }
        return null;
    };

    get label () {
        if (this.props.label) {
            return this.props.label; //.toLowerCase();
        }
        return '';
    }

    isPlaceholder = () => {
        return this.props.values.length === 0;
        if (this.props.predefinedModal) {
            return this.state.predefinedLabel == -1;
        }

        for (let i = 0; i < this.props.options.length; ++i) {
            if (this.props.options[ i ].value === this.props.value) {
                return false;
            }
        }
        return true;
    };

    getLabel = () => {
        return `${ this.props.label } - ${ this.props.values.length }${ this.props.values.length === 1 ? ' alegere' : ' alegeri' }`;
        if (this.props.predefinedModal) {
            if (!this.isPlaceholder()) {
                return this.state.predefinedLabel;
            } else {
                return this.props.label;
            }
        }

        for (let i = 0; i < this.props.options.length; ++i) {
            if (this.props.options[ i ].value === this.props.value) {
                return this.props.options[ i ].label;
            }
        }
        return this.props.label;
    };

    onModalOpen = () => {
        this.setState({
            modalOpen: true,
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false,
        });
    };

    onModalDone = (options) => {
        this.props.onSelect(options);
        this.onModalClose();
    };
    
}

export default MultipleChoiceField;