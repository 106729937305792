import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { kDateFormatDisplay, prettyMonthLocal } from '../../functional/min-support';

const quickDateButtons = [
    {
        label: 'sapt. asta',
        value: 'this-week',
    }, {
        label: 'sapt. trecuta',
        value: 'last-week',
    }, {
        label: 'luna asta',
        value: 'this-month',
    }, {
        label: 'luna trecuta',
        value: 'last-month',
    },
];

const rows = [ 0, 1, 2, 3, 4, 5 ];
const columns = [ 0, 1, 2, 3, 4, 5, 6 ];

const days = [ 'L', 'Ma', 'Mi', 'J', 'V', 'S', 'D' ];

class InternalCalendar extends Component {
    
    identifier = 0;
    
    static propTypes = {
        startDate: PropTypes.string,
        onDayPressed: PropTypes.func,
        activeDate: PropTypes.string,
    };
    
    state = {
        days: [],
    };
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (nextProps.startDate !== this.props.startDate) {
            this.rewriteCalendar(nextProps.startDate);
        }
    }
    
    componentDidMount () {
        if (this.props.startDate) {
            this.rewriteCalendar(this.props.startDate);
        }
    }
    
    rewriteCalendar = (startDate) => {
        const days = [];
        let index = 0;
        const momentCurrent = new moment(startDate, kDateFormatDisplay);
        const today = new moment();
        const monthEnd = new moment(momentCurrent).add(1, 'months');
        const previousMonthDaysNb = new moment(momentCurrent).subtract(1, 'months')
            .daysInMonth();
        let firstDayOfWeek = momentCurrent.day();
        firstDayOfWeek -= 1;
        firstDayOfWeek = firstDayOfWeek < 0 ? 6 : firstDayOfWeek;
        index = previousMonthDaysNb - firstDayOfWeek + 1;
        for (let i = 0; i < firstDayOfWeek; ++i) {
            days.push({
                label: `${ index++ }`,
                locked: true,
                direction: 'left',
            });
        }
        for (; momentCurrent.isBefore(monthEnd); momentCurrent.add(1, 'days')) {
            let badge = 0;
            if (Math.floor(Math.random() * 5) > 100) {
                badge = Math.ceil(Math.random() * 4);
            }
            days.push({
                label: momentCurrent.date(),
                locked: false,
                current: today.isSame(momentCurrent, 'day'),
                badge,
            });
        }
        index = 1;
        while (days.length < 6 * 7) {
            days.push({
                label: `${ index++ }`,
                locked: true,
                direction: 'right',
            });
        }
        this.setState({
            days,
        });
    };
    
    render () {
        const activeDayNb = new moment(this.props.activeDate, kDateFormatDisplay).date();
        return (
            <div className={'internal-calendar'}>
                <div className={'month-header'}>{prettyMonthLocal(this.props.startDate)}</div>
                <div className={'calendar-container'}>
                    <div className={'day-row'}>
                        {
                            columns.map((cellNb) => {
                                return (
                                    <div onClick={this.onCellClick} key={`day-${ cellNb }`} className={'cell'}>
                                        {days[ cellNb ]}
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        this.state.days.length > 0 && rows.map((nb) => {
                            return (
                                <div key={nb} className={'calendar-row'}>
                                    {
                                        columns.map((cellNb) => {
                                            const index = nb * 7 + cellNb;
                                            const item = this.state.days[ index ];
                                            item.active = (item.label == activeDayNb);
                                            return (
                                                <InternalCalendarCell key={index} item={item} index={index} onClick={this.onCellClick}/>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
    
    onCellClick = (item) => {
        if (item.locked) {
            const dayNb = parseInt(item.label);
            let newDate = new moment(this.props.startDate, kDateFormatDisplay);
            if (item.direction === 'left') {
                newDate.subtract(1, 'months');
            } else if (item.direction === 'right') {
                newDate.add(1, 'months');
            }
            newDate = newDate.add(dayNb - 1, 'days');
            this.props.onDayPressed(false, newDate.format(kDateFormatDisplay), item.direction);
        } else {
            const dayNb = parseInt(item.label);
            const newDate = new moment(this.props.startDate, kDateFormatDisplay).add(dayNb - 1, 'days');
            this.props.onDayPressed(true, newDate.format(kDateFormatDisplay));
        }
    }
    
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

class InternalCalendarCell extends React.Component {
    
    static propTypes = {
        index: PropTypes.number,
        item: PropTypes.object,
        onClick: PropTypes.func,
    };
    
    render () {
        if (this.props.item.locked) {
            return (
                <div onClick={this.onClick} key={this.props.index} className={'cell-locked'}>
                    {this.props.item.label}
                </div>
            );
        } else {
            const style = {
            
            };
            if (this.props.item.current) {
                style.backgroundColor = '#54484D88';
            }
            if (this.props.item.active) {
                style.backgroundColor = '#A92B2A';
                style.color = '#ffffff';
            }
            
            if (this.props.item.badge && this.props.item.badge > 0) {
                style.borderWidth = `${ Math.min(this.props.item.badge, 4) }px`;
                style.borderColor = '#00000044';
                style.borderStyle = 'solid';
            }
    
            return (
                <div onClick={this.onClick} style={style} className={'cell'}>
                    {this.props.item.label}
                </div>
            );
        }
    }
    
    onClick = () => {
        this.props.onClick(this.props.item);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalCalendar);