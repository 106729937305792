import React, { useRef } from 'react';
import { SnapItem, SnapList, useScroll, useVisibleElements } from 'react-snaplist-carousel';
import { smallIcon } from '../../functional/min-support';

const HorizontalSnapper = (props) => {
    const snapList = useRef(null);
    const goToSnapItem = useScroll({ ref: snapList });
    const visible = useVisibleElements(
        { debounce: 10, ref: snapList },
        ([ element ]) => { return element; }
    );
    
    let step = 1;
    if (props.step) {
        step = props.step;
    }
    let style = {};
    if (props.style) {
        style = { ...style, ...props.style };
    }
    
    if (props.flexed) {
        style.flex = 1;
    } else {
        style.width = '100%';
    }
    
    if (!props.items) {
        return null;
    }
    
    const leftChevronStyle = {
        cursor: 'pointer',
        backgroundColor: '#cccccc',
        borderRadius: 100,
        width: 46,
        height: 46,
        paddingLeft: 9,
        paddingTop: 9,
        marginRight: 20,
    };
    
    const rightChevronStyle = {
        cursor: 'pointer',
        backgroundColor: '#cccccc',
        borderRadius: 100,
        width: 46,
        height: 46,
        paddingLeft: 11,
        paddingTop: 9,
        marginLeft: 20,
    };
    
    let chevronSize = 26;
    let marginBetween = 20;
    
    if (props.small) {
        leftChevronStyle.width = 24;
        leftChevronStyle.height = 24;
        leftChevronStyle.marginRight = 4;
        leftChevronStyle.paddingTop = 0;
        leftChevronStyle.paddingLeft = 4;
        
        rightChevronStyle.width = 24;
        rightChevronStyle.height = 24;
        rightChevronStyle.marginLeft = 4;
        rightChevronStyle.paddingTop = 0;
        rightChevronStyle.paddingLeft = 6;
        
        chevronSize = 14;
        marginBetween = 8;
    }
    
    return (
        <div className="d-flex flex-row align-items-center justify-content-center" style={style}>
            <div onClick={() => { goToSnapItem(visible - step); }} style={leftChevronStyle}>
                {
                    smallIcon('ft-chevron-left', '#ffffff', chevronSize)
                }
            </div>
    
            <SnapList
                ref={snapList}>
                {
                    props.items.map((item, key) => {
                        return (
                            <SnapItem margin={{ right: marginBetween }} snapAlign="center">
                                {
                                    props.renderItem(item)
                                }
                            </SnapItem>
                        );
                    })
                }
            </SnapList>
            
            <div onClick={() => { goToSnapItem(visible + step); }} style={rightChevronStyle}>
                {
                    smallIcon('ft-chevron-right', '#ffffff', chevronSize)
                }
            </div>
        </div>
    );
};

export default HorizontalSnapper;