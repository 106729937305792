import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { update } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';

class Modal extends Component {
    
    static propTypes = {
        updateModal: PropTypes.bool,
        updateModalInfo: PropTypes.object,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        value: '',
    };

    render () {

        return (
            <BaseModal
                title={'Schimba valoarea'}
                open={this.props.open}
                doneActive={this.readyToSend}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>
                    <TextFieldInput
                        title
                        value={this.state.value}
                        style={{ marginBottom: 4 }}
                        label={'Noua valoare'}
                        onChange={(newValue) => {
                            this.setState({
                                value: newValue,
                            });
                        }}/>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if (!this.props.updateModalInfo || !this.props.updateModalInfo.id || !this.props.updateModalInfo.type || !this.props.updateModalInfo.on) {
            this.props.toast('Schimbare esuata!!!', kToastActionError);
            return;
        }
        const request = {
            id: this.props.updateModalInfo.id,
            value: this.state.value,
            item: this.props.updateModalInfo.type,
            name: this.props.updateModalInfo.on,
        };

        this.props.openLoading();

        update(request, (successful) => {
            if (successful) {
                this.props.toast('Schimbare inregistrata cu succes.');
            } else {
                this.props.toast('Schimbare esuata!', kToastActionError);
            }
            this.setState({
                value: '',
            });
            this.props.closeLoading();
            this.props.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if ((!this.props.open && nextProps.open) || (this.props.updateModalInfo && (this.props.updateModalInfo.previousValue !== nextProps.updateModalInfo.previousValue))) {
            this.update(nextProps);
        }
    }
    
    update = (props) => {
        if (props.updateModalInfo) {
            if (props.updateModalInfo.previousValue) {
                this.setState({
                    value: props.updateModalInfo.previousValue,
                });
            }
        }
    };

    get valueSelected () {
        return this.state.value != '';
    }

    get readyToSend () {
        return this.valueSelected;
    }

    clear = () => {
        this.setState({
            value: '',
        });
    }

}

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
        updateModalInfo: state.updateModalInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);