import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addClient } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import {
    inputValidationInteger,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
} from '../../functional/min-support';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';

class Modal extends Component {
    
    static propTypes = {
        
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        name: '',
        cif: '',
        reg: '',
        bank: '',
        iban: '',
        address: '',

        workPrice: '120',
        workPriceCustom: '120',
        
        email: '',
        notificationType: 0,

        formComplete: false,
    };

    render () {
        
        if (this.props.user.isPerson) {
            return (
                <BaseModal
                    stableHeight
                    title={'Adauga client'}
                    open={this.props.open}
                    doneActive={this.state.formComplete}
                    onClose={this.onClose}
                    onDone={this.onDone}>
            
                    <div className={'w-100'}>
                
                        <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
                    
                            <TextFieldInput
                                title
                                required
                                validated={inputValidationNonEmpty(this.state.name)}
                        
                                value={this.state.name}
                                style={{ marginBottom: 4 }}
                                label={'Nume si prenume client'}
                                onChange={(newValue) => {
                                    this.setState({
                                        name: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'fa-code'}
                                value={this.state.cif}
                                style={{ marginBottom: 4 }}
                                label={'Cod numeric personal'}
                                onChange={(newValue) => {
                                    this.setState({
                                        cif: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'blank'}
                                value={this.state.reg}
                                style={{ marginBottom: 4 }}
                                label={'Seria si numarul de buletin'}
                                onChange={(newValue) => {
                                    this.setState({
                                        reg: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'fa-credit-card'}
                                value={this.state.bank}
                                style={{ marginBottom: 4 }}
                                label={'Banca client'}
                                onChange={(newValue) => {
                                    this.setState({
                                        bank: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'blank'}
                                value={this.state.iban}
                                style={{ marginBottom: 4 }}
                                label={'IBAN client'}
                                onChange={(newValue) => {
                                    this.setState({
                                        iban: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'fa-home'}
                                value={this.state.address}
                                style={{ marginBottom: 4 }}
                                label={'Adresa locuinta'}
                                onChange={(newValue) => {
                                    this.setState({
                                        address: newValue,
                                    });
                                }}/>
                    
                            <TextFieldInput
                                icon={'fa-at-sign'}
                                value={this.state.email}
                                style={{ marginBottom: 4 }}
                                label={'Adresa de email (pentru notificari automate)'}
                                onChange={(newValue) => {
                                    this.setState({
                                        email: newValue,
                                    });
                                }}/>

                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.workPrice)}

                                icon={'fa-dollar-sign'}
                                label={'Costul mediu al manoperei'}
                                options={[
                                    {
                                        label: 'Cost scazut',
                                        description: '80 RON',
                                        value: '80',
                                    },
                                    {
                                        label: 'Cost normal',
                                        description: '120 RON',
                                        value: '120',
                                    },
                                    {
                                        label: 'Cost ridicat',
                                        description: '160 RON',
                                        value: '160',
                                    },
                                    {
                                        label: 'Altul',
                                        value: 'other',
                                    },
                                ]}
                                value={this.state.workPrice}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        workPrice: value,
                                    });
                                }}/>

                            {
                                this.state.workPrice === 'other' &&
                                <TextFieldInput
                                    required
                                    validated={inputValidationInteger(this.state.workPriceCustom)}
                                    icon={'blank'}
                                    value={this.state.workPriceCustom}
                                    style={{ marginBottom: 4 }}
                                    label={'Introdu costul manoperei'}
                                    onChange={(newValue) => {
                                        this.setState({
                                            workPriceCustom: newValue,
                                        });
                                    }}/>
                            }

                
                        </Form>
            
                    </div>
        
                </BaseModal>
            );
        }

        return (
            <BaseModal
                stableHeight
                title={'Adauga client'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.name)}

                            value={this.state.name}
                            style={{ marginBottom: 4 }}
                            label={'Nume firma client'}
                            onChange={(newValue) => {
                                this.setState({
                                    name: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'fa-code'}
                            value={this.state.cif}
                            style={{ marginBottom: 4 }}
                            label={'Cod identificare fiscala'}
                            onChange={(newValue) => {
                                this.setState({
                                    cif: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'blank'}
                            value={this.state.reg}
                            style={{ marginBottom: 4 }}
                            label={'Nr. inregistrare registrul comertului'}
                            onChange={(newValue) => {
                                this.setState({
                                    reg: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'fa-credit-card'}
                            value={this.state.bank}
                            style={{ marginBottom: 4 }}
                            label={'Banca client'}
                            onChange={(newValue) => {
                                this.setState({
                                    bank: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'blank'}
                            value={this.state.iban}
                            style={{ marginBottom: 4 }}
                            label={'IBAN client'}
                            onChange={(newValue) => {
                                this.setState({
                                    iban: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            icon={'fa-home'}
                            value={this.state.address}
                            style={{ marginBottom: 4 }}
                            label={'Adresa sediu social'}
                            onChange={(newValue) => {
                                this.setState({
                                    address: newValue,
                                });
                            }}/>
    
                        <TextFieldInput
                            icon={'fa-at-sign'}
                            value={this.state.email}
                            style={{ marginBottom: 4 }}
                            label={'Adresa de email (pentru notificari automate)'}
                            onChange={(newValue) => {
                                this.setState({
                                    email: newValue,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            validated={true}
        
                            icon={'fa-bell'}
                            label={'Notificari prin email puncte de lucru'}
                            options={[
                                {
                                    value: 1,
                                    label: 'Da, notifica si punctele de lucru',
                                    description: 'atunci cand se efectueaza o interventie.',
                                }, {
                                    value: 0,
                                    label: 'Nu, nu notifica si punctele de lucru',
                                    description: 'atunci cand se efectueaza o interventie.',
                                },
                            ]}
                            value={this.state.notificationType}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    notificationType: value,
                                });
                            }}/>

                        <SingleChoiceField
                            required
                            validated={inputValidationSingleChoice(this.state.workPrice)}

                            icon={'fa-dollar-sign'}
                            label={'Costul mediu al manoperei'}
                            options={[
                                {
                                    label: 'Cost scazut',
                                    description: '80 RON',
                                    value: '80',
                                },
                                {
                                    label: 'Cost normal',
                                    description: '120 RON',
                                    value: '120',
                                },
                                {
                                    label: 'Cost ridicat',
                                    description: '160 RON',
                                    value: '160',
                                },
                                {
                                    label: 'Altul',
                                    value: 'other',
                                },
                            ]}
                            value={this.state.workPrice}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    workPrice: value,
                                });
                            }}/>

                        {
                            this.state.workPrice === 'other' &&
                            <TextFieldInput
                                required
                                validated={inputValidationInteger(this.state.workPriceCustom)}
                                icon={'blank'}
                                value={this.state.workPriceCustom}
                                style={{ marginBottom: 4 }}
                                label={'Introdu costul manoperei'}
                                onChange={(newValue) => {
                                    this.setState({
                                        workPriceCustom: newValue,
                                    });
                                }}/>
                        }

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const request = {
            name: this.state.name,
            cif: this.state.cif,
            reg: this.state.reg,
            bank: this.state.bank,
            iban: this.state.iban,
            address: this.state.address,
            email: this.state.email,
            notificationType: this.state.notificationType,
            type: this.props.user.isPerson ? '2' : '1',
            workPrice: this.state.workPrice === 'other' ? this.state.workPriceCustom : this.state.workPrice,
        };

        this.props.openLoading();

        addClient(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Client inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    get nameSelected () {
        return this.state.name != '';
    }

    get cifSelected () {
        return this.state.cif != '';
    }

    get regSelected () {
        return this.state.reg != '';
    }

    get bankSelected () {
        return this.state.bank != '';
    }

    get ibanSelected () {
        return this.state.iban != '';
    }

    get addressSelected () {
        return this.state.address != '';
    }

    get readyToSend () {
        return this.nameSelected;
    }

    clear = () => {
        this.setState({
            name: '',
            cif: '',
            reg: '',
            bank: '',
            iban: '',
            address: '',
            email: '',
            notificationType: 0,
        });
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);