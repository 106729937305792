import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { openPDFViewModal, openUpdateModal, setItemPageTitle } from '../store/actions/application-store-actions';
import { getInvoice, kAPIEndpointInvoice, kAPIEndpointInvoicePerson } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter, prettyDateAndTime } from '../functional/min-support';
import { toClientPage, toSalePage, toTicketPage } from '../functional/navigation';
import { mincodeServicesGeneratePDF, translateInvoiceEntries } from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';

class InvoicePage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        search: '',
    };
    
    state = InvoicePage.defaultState;
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(InvoicePage.defaultState, this.update);
    }
    
    update = () => {
        const key = this.props.match.params.key;
        getInvoice(key, (successful, data) => {
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let entries = this.state.item ? translateInvoiceEntries(this.state.item.papers, this.state.item.sales) : null;
        const toolbarActions = [
            {
                title: 'Vezi factura',
                onPress: () => {
                    if (this.props.user.isPerson) {
    
                        this.props.openPDFViewModal('loading');
    
                        mincodeServicesGeneratePDF(`${ kAPIEndpointInvoicePerson }?id=${ this.state.item.id }&username=${ this.props.user.username }`, (successful, result) => {
                            if (successful) {
                                this.props.openPDFViewModal(result.versions[ 0 ].extended);
                            }
                        });
                        
                        //openInvoicePerson(this.state.item.id, this.props.user.username);
                    } else {
    
                        this.props.openPDFViewModal('loading');
    
                        mincodeServicesGeneratePDF(`${ kAPIEndpointInvoice }?id=${ this.state.item.id }&username=${ this.props.user.username }`, (successful, result) => {
                            if (successful) {
                                this.props.openPDFViewModal(result.versions[ 0 ].extended);
                            }
                        });
                        
                        //openInvoice(this.state.item.id, this.props.user.username);
                    }
                },
                icon: { title: 'ft-file' },
            },
            {
                title: 'Adauga deviz',
                onPress: () => { this.setState({ paperModalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
            {
                title: 'Adauga aviz',
                onPress: () => { this.setState({ saleModalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'se cauta in lista de intrari dupa:',
                tags: [ 'Denumirea piesei' ],
            },
        ];
        if (entries && this.state.search.length > 0) {
            entries = filter(entries, [ 'name' ], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
    
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit-2'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.name,
                                                'sale',
                                                'name'
                                            );
                                        }}/>
    
                                    <TextFieldDisplay
                                        label={'creata la data'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{ marginBottom: 4 }}/>
    
                                    <TextFieldDisplay
                                        label={'de catre operatorul'}
                                        value={`${ this.state.item.creator.first_name } ${ this.state.item.creator.last_name }`}
                                        icon={'ft-users'}
                                        style={{ marginBottom: 4 }}/>
    
                                    <TextFieldDisplay
                                        label={'numarul de zile pana la scadenta'}
                                        value={this.state.item.deadline}
                                        icon={'ft-calendar'}
                                        action={'ft-edit-2'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.deadline,
                                                'invoice',
                                                'deadline'
                                            );
                                        }}/>
                                        
                                    <TextFieldDisplay
                                        label={'client'}
                                        value={this.state.item.client.name}
                                        icon={'ft-briefcase'}
                                        action={'ft-eye'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            toClientPage(this.state.item.client.id);
                                        }}/>
    
                                    {
                                        this.state.item.client.address &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.address}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.client.cif &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.cif}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.client.reg &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.reg}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.client.bank &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.bank}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.client.iban &&
                                        <TextFieldDisplay
                                            value={this.state.item.client.iban}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
                            
                                </div>

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                
                    <div className="h-100 overflow-scroll" style={{ width: '40%', padding: 20, paddingLeft: 0 }}>
                        {
                            entries && entries.length > 0 &&
                            <React.Fragment>
                                {
                                    entries.map((entry, entryKey) => {
                                        const type = entry.hasOwnProperty('ticket_id') ? 'paper' : 'sale';
                                        return (
                                            <div key={entryKey} className="general-card card-small-padding" style={{ marginBottom: 20 }}>
    
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div style={{ flex: 1, paddingRight: 20 }}>
                                                        
                                                        {
                                                            type === 'paper' &&
                                                            <React.Fragment>
                                                                <TextFieldDisplay
                                                                    style={{ marginBottom: 4 }}
                                                                    label={'deviz numarul'}
                                                                    value={`PDD 20${ entry.id }`}
                                                                    icon={'ft-file'}/>
    
                                                                <TextFieldDisplay
                                                                    style={{ marginBottom: 4 }}
                                                                    label={'pentru tichetul'}
                                                                    value={entry.ticket_name}
                                                                    icon={'ft-check-square'}/>
                                                            </React.Fragment>
                                                        }
    
                                                        {
                                                            type === 'sale' &&
                                                            <React.Fragment>
                                                                <TextFieldDisplay
                                                                    style={{ marginBottom: 4 }}
                                                                    label={'aviz numarul'}
                                                                    value={entry.name}
                                                                    icon={'ft-file'}/>
                                                            </React.Fragment>
                                                        }
    
                                                        <TextFieldDisplay
                                                            style={{ marginBottom: 4 }}
                                                            label={'creat la data'}
                                                            value={prettyDateAndTime(entry.created)}
                                                            icon={'ft-clock'}/>
    
                                                        <TextFieldDisplay
                                                            style={{ marginBottom: 4 }}
                                                            label={'de catre operatorul'}
                                                            value={`${ entry.first_name } ${ entry.last_name }`}
                                                            icon={'ft-users'}/>
    
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`Pret fara TVA: ${ entry.total } RON`}/>
    
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`TVA: ${ entry.total_tva } RON`}/>
    
                                                        <TextFieldDisplay
                                                            icon={'blank'}
                                                            style={{ marginBottom: 4 }}
                                                            value={`Total: ${ parseFloat(entry.total) + parseFloat(entry.total_tva) } RON`}/>
    
                                                        {
                                                            type === 'paper' &&
                                                            <React.Fragment>
                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => { toTicketPage(entry.ticket_id); }}
                                                                    value={'Vezi tichetul'}/>
                                                            </React.Fragment>
                                                        }
    
                                                        {
                                                            type === 'sale' &&
                                                            <React.Fragment>
                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => { toSalePage(entry.id); }}
                                                                    value={'Vezi avizul'}/>
                                                            </React.Fragment>
                                                        }
    
                                                        
                                                    </div>
        
                                                    <div style={{ width: 200, height: 200, borderRadius: 8 }}>
                                                        <ImageFieldDisplay value={entry.services_picture_uid_m} full={entry.services_picture_uid_o}/>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </React.Fragment>
                        }
                        
                        {
                            entries && entries.length <= 0 &&
                            <h3>
                                Niciun produs
                            </h3>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(setItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(openUpdateModal(id, previous, type, on)); },
    
        openPDFViewModal: (link) => { return dispatch(openPDFViewModal(link)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoicePage));