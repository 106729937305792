import React from 'react';
import PropTypes from 'prop-types';
import { getAllTickets, kAPIEndpointPVI } from '../functional/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PickerModal from '../components/modal/model/PickerModal';
import {
    colorByStatus,
    iconByStatus,
    kStatusAssigned,
    kStatusAwaitsApproval,
    kStatusDescription,
    kStatusFinished,
    kStatusInProgress,
    kStatusItemsRequired,
    kStatusNotInvoiced,
    kStatusPartial,
    kStatusReceived,
    kStatusRepaired,
    kStatusScheduled,
    kStatusStopped,
} from '../functional/status';
import AddTicketModal from '../components/modal/AddTicketModal';
import { kFilterTicketFields, kFilterTicketFieldsDefault } from '../functional/constants';
import { compareGeneratorString, filter, prettyDateAndTime, smallIcon } from '../functional/min-support';
import OperatorMultiplePicker from '../components/modal/pickers/OperatorMultiplePicker';
import ClientMultiplePicker from '../components/modal/pickers/ClientMultiplePicker';
import AddClientModal from '../components/modal/AddClientModal';
import AddSaleModal from '../components/modal/AddSaleModal';
import AddInvoiceModal from '../components/modal/AddInvoiceModal';
import AddStockModal from '../components/modal/AddStockModal';
import AddOrderModal from '../components/modal/AddOrderModal';
import InternalToolbar from '../components/general/InternalToolbar';
import { Card, CardBody } from 'reactstrap';
import { toClientPage, toTicketPage, toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import { mincodeServicesGeneratePDF, translateTicketActionList } from '../functional/logic';
import { ticketActionAdvancedFieldsSimple } from './TicketPage';
import { priorityNbBasedOnText } from './TicketsPage';
import { openPDFViewModal } from '../store/actions/application-store-actions';
import BaseModal from '../components/modal/model/BaseModal';


const tableFields = {
    'title': {
        title: 'Numar',
        transform: (item) => { return item.name; },
        action: (item) => { toTicketPage(item.id); },
        compare: compareGeneratorString('name', true),
        icon: 'ft-align-justify',
    },
    'date': {
        title: 'Data',
        transform: (item) => { return prettyDateAndTime(item.created); },
        compare: compareGeneratorString('created', true),
        icon: 'ft-calendar',
    },
    'type': {
        title: 'Tipul',
        transform: (item) => { return `${ item.type } (${ item.priority_text })`; },
        compare: compareGeneratorString('type'),
        icon: 'ft-clipboard',
    },
    'status': {
        title: 'Status',
        transform: (item) => { return <span style={{ color: colorByStatus(item.status) }}>{item.status}</span>; },
        compare: compareGeneratorString('status'),
        icon: 'ft-code',
    },
    'client': {
        title: 'Client',
        transform: (item) => { return item.client_name; },
        action: (item) => { toClientPage(item.client_id); },
        compare: compareGeneratorString('client_name'),
        icon: 'ft-briefcase',
    },
    'headquarters': {
        title: 'Adresa',
        transform: (item) => { return item.client_hq_address; },
        action: (item) => { toClientPage(item.client_id); },
        compare: compareGeneratorString('client_hq_address'),
        icon: 'ft-map-pin',
    },
    'equipments': {
        title: 'Echipamente',
        transform: (item) => { return item.equipments ? item.equipments.map((equipment, equipmentKey) => { return <><span>{equipment.title}</span><br/></>; }) : ''; },
        icon: 'ft-settings',
    },
    'view': {
        title: '',
        transform: (item) => { return 'Vezi'; },
        action: (item) => { toTicketPage(item.id); },
        weight: 0.5,
    },
};

const tableFieldsKeys = Object.keys(tableFields);

const customContainerGenerator = (onPVIPressed) => {
    const component = (item) => {
        const actions = translateTicketActionList(item.actions);
        return (
            <div className={'table-action-list'}>
                {
                    actions && actions.map((action, index) => {
                        return (
                            <>
                                {index > 0 && <span style={{ marginRight: 10 }}>{smallIcon('ft-chevron-left')}</span>}
                                <div className={'table-action-list-item'}>
                                    <div className={'table-action-list-item-entry'} style={{ color: colorByStatus(action.status) }}>
                                        <span style={{ marginRight: 4 }}>{smallIcon(iconByStatus(action.status))}</span>
                                        {action.status}
                                    </div>
                                    <div className={'table-action-list-item-entry'}>
                                        <span style={{ marginRight: 4 }}>{smallIcon('ft-calendar')}</span>
                                        {prettyDateAndTime(action.created)}
                                    </div>
                                    <div onClick={() => { toUserPage(action.user.id); }} className={'table-action-list-item-entry table-action-list-item-entry-action'}>
                                        <span style={{ marginRight: 4 }}>{smallIcon('ft-user')}</span>
                                        {`${ action.user.first_name } ${ action.user.last_name }`}
                                    </div>
                                    {
                                        ticketActionAdvancedFieldsSimple(action, (action) => { onPVIPressed(item, action); })
                                    }
                                </div>
                            </>
                        );
                    })
                }
            </div>
        );
    };
    return component;
};

let nbOfCols = 0;
let pointWeight = 0;
tableFieldsKeys.forEach((fieldKey) => {
    const field = tableFields[ fieldKey ];
    pointWeight += field.weight ? field.weight : 1;
    nbOfCols += 1;
});
pointWeight = 100 / pointWeight;

class DashboardPage extends PersistentStateComponent {

    identifier = 0;

    updateInterval = null;

    static propTypes = {
        user: PropTypes.object,
    };

    static defaultState = {
        loading: true,
        search: '',
        fields: kFilterTicketFieldsDefault,
        filterModal: false,

        items: [],

        operators: [],
        operatorLabels: [],

        clients: [],
        clientLabels: [],

        operatorModalOpen: false,
        clientModalOpen: false,

        addModalOpen: false,
        addTicketModalOpen: false,
        addSaleModalOpen: false,
        addInvoiceModalOpen: false,
        addClientModalOpen: false,
        addStockModalOpen: false,
        addOrderModalOpen: false,

        activeStatus: null,

        sort: 'date',
    };

    state = DashboardPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-dashboard-page';
    }

    get shouldDisplayGroups () {
        return !this.state.activeStatus;
    }

    componentDidMount () {
        super.componentDidMount();
        this.setState({
            loading: true,
        });
        this.update();
    }

    componentWillUnmount () {
        super.componentWillUnmount();
        if (this.updateInterval) {
            //clearInterval(this.updateInterval);
        }
    }

    generalUpdate = () => {
        this.setState(DashboardPage.defaultState, this.update);
    }

    update = () => {
        getAllTickets((successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    data[ index ].priority_text = priorityNbBasedOnText(data[ index ].priority);
                    data[ index ].client_name = data[ index ].client.name;
                    data[ index ].client_id = data[ index ].client.id;
                    data[ index ].client_hq_address = data[ index ].client.headquarters.address;
                });
                this.setState({
                    items: data,
                    loading: false,
                });
            } else {
                console.error('no tickets');
                this.setState({
                    loading: false,
                });
            }
        });
    };

    render () {
        let items = [];
        const statusGroup = {};
        let totalNb = 0;

        const customContainer = customContainerGenerator(this.onPVIPressed);

        let elements = this.state.items;
        if (this.state.sort && tableFields[ this.state.sort ] && tableFields[ this.state.sort ].compare) {
            elements = elements.sort(tableFields[ this.state.sort ].compare);
            if (this.state.sortReverse) {
                elements = elements.reverse();
            }
        }
        const filtered = this.filterForActiveStatus(elements, this.state.activeStatus);
        const newFiltered = this.filterByClientAndOperator(filtered);
        items = filter(newFiltered, this.state.fields, this.state.search, true);

        const list = items.map((item) => { return <DashboardTableListRow key={item.key} item={item} tableFields={tableFields} tableFieldsKeys={tableFieldsKeys} customContainer={customContainer} nbOfCols={nbOfCols}/>; });

        for (let i = 0; i < items.length; ++i) {
            if (!statusGroup[ items[ i ].status ]) {
                statusGroup[ items[ i ].status ] = 0;
            }
            statusGroup[ items[ i ].status ] += 1;
            totalNb += 1;
        }

        /*
         * On additional actions
         * this.props.user.level != -3 ? [...] : []
         */

        const toolbarActions = [
            {
                title: 'Filtre',
                onPress: this.openFilterModal,
                icon: { title: 'ft-filter' },
            },
            {
                title: 'Utilizatori',
                onPress: this.openOperatorModal,
                icon: { title: 'ft-users' },
            },
            {
                title: 'Clienti',
                onPress: this.openClientModal,
                icon: { title: 'ft-briefcase' },
            },
            {
                title: 'Adauga',
                onPress: this.openAddModal,
                icon: { title: 'ft-plus' },
            },
        ];

        const toolbarLabels = [
            {
                connection: 'se cauta dupa:',
                tags: this.matchFields(this.state.fields),
            },
            {
                connection: 'pentru operatorii:',
                tags: this.getSecondLabels(this.state.operatorLabels),
            },
            {
                connection: 'pentru clientii:',
                tags: this.getSecondLabels(this.state.clientLabels),
            },
        ];

        if (this.state.activeStatus) {
            toolbarActions.unshift({
                title: this.state.activeStatus !== 'all' ? this.state.activeStatus : 'Toate',
                onPress: () => { this.setState({ activeStatus: null }); },
                style: { backgroundColor: colorByStatus(this.state.activeStatus), color: '#ffffff' },
                icon: { title: 'ft-x', color: '#ffffff', size: 14 },
            });
        }

        return (
            <React.Fragment>

                <InternalToolbar
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    onSearchChange={this.searchChange}
                    searchValue={this.state.search}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>

                <div className={'dashboard-page'}>

                    <div className={'dashboard-arranger'}>
                        <div className={'dashboard-top'}>
                            <div className={'dashboard-top-left'} style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>

                                {
                                    this.shouldDisplayGroups &&
                                    <StatusSelectGrid level={this.props.user.level} statusGroup={statusGroup} onItemPress={this.onStatusPress} all={totalNb}/>
                                }

                                {
                                    this.state.loading &&
                                    <div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{ minHeight: 200 }}>
                                        <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                }

                                {
                                    !this.state.loading &&
                                    <div className={'list-table-container'} ref={this.listElement} style={{ marginTop: this.shouldDisplayGroups ? 10 : 0 }}>

                                        <table className={'list-table'}>

                                            <colgroup>
                                                {
                                                    tableFieldsKeys.map((fieldKey) => {
                                                        const field = tableFields[ fieldKey ];
                                                        return (
                                                            <col key={fieldKey} style={{ width: pointWeight * (field.weight ? field.weight : 1) }}/>
                                                        );
                                                    })
                                                }
                                            </colgroup>

                                            <thead style={{ position: 'sticky', top: 0, zIndex: 20 }}>

                                                <tr className={'table-list-item-row table-list-item-row-header'}>
                                                    {
                                                        tableFieldsKeys.map((fieldKey) => {
                                                            const field = tableFields[ fieldKey ];
                                                            return (
                                                                <th
                                                                    key={fieldKey}
                                                                    onClick={() => {
                                                                        if (field.compare) {
                                                                            this.setState({
                                                                                sort: fieldKey,
                                                                                sortReverse: this.state.sort === fieldKey && !this.state.sortReverse,
                                                                            });
                                                                        }
                                                                    }}
                                                                    className={`table-list-item-cell-header ${ field.compare ? 'table-list-item-cell-header-action' : '' }`}>
                                                                    {field.icon && <span style={{ marginRight: 4 }}>{smallIcon(field.icon)}</span>}
                                                                    {field.title}
                                                                    {this.state.sort === fieldKey && !this.state.sortReverse && <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-down')}</span>}
                                                                    {this.state.sort === fieldKey && this.state.sortReverse && <span style={{ marginLeft: 4 }}>{smallIcon('ft-chevron-up')}</span>}
                                                                </th>
                                                            );
                                                        })
                                                    }
                                                </tr>

                                            </thead>

                                            <tbody>

                                                {
                                                    list
                                                }

                                            </tbody>

                                        </table>

                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <OperatorMultiplePicker
                        minimumChoices={0}
                        maximumChoices={100}
                        open={this.state.operatorModalOpen}
                        values={this.state.operators}
                        onClose={this.closeOperatorModal}
                        onDone={this.onOperatorModalDone}
                        setLabels={(labels) => {
                            this.setState({
                                operatorLabels: labels,
                            });
                        }}/>

                    <ClientMultiplePicker
                        minimumChoices={0}
                        maximumChoices={100}
                        open={this.state.clientModalOpen}
                        values={this.state.clients}
                        onClose={this.closeClientModal}
                        onDone={this.onClientModalDone}
                        setLabels={(labels) => {
                            this.setState({
                                clientLabels: labels,
                            });
                        }}/>

                    <PickerModal
                        title={'Alege campurile cautarii'}
                        open={this.state.filterModal}
                        options={kFilterTicketFields}
                        initialSelectedValues={this.state.fields}
                        minimumChoices={1}
                        maximumChoices={100}
                        onClose={this.closeFilterModal}
                        onDone={this.doneFilterModal}
                        returnMatchedFields/>

                    <AddTicketModal
                        open={this.state.addTicketModalOpen}
                        onClose={this.closeAddTicketModal}/>

                    <AddSaleModal
                        open={this.state.addSaleModalOpen}
                        onClose={this.closeAddSaleModal}/>

                    <AddInvoiceModal
                        open={this.state.addInvoiceModalOpen}
                        onClose={this.closeAddInvoiceModal}/>

                    <AddClientModal
                        open={this.state.addClientModalOpen}
                        onClose={this.closeAddClientModal}/>

                    <AddStockModal
                        open={this.state.addStockModalOpen}
                        onClose={this.closeAddStockModal}/>

                    <AddOrderModal
                        open={this.state.addOrderModalOpen}
                        onClose={this.closeAddOrderModal}/>

                    <BaseModal
                        hideDone
                        title={'Adauga'}
                        open={this.state.addModalOpen}
                        onClose={this.closeAddModal}>

                        {
                            this.buttonsBasedOnLevel().map((item) => { return item; })
                        }

                    </BaseModal>

                </div>
            </React.Fragment>
        );
    }

    openAddModal = () => {
        this.setState({ addModalOpen: true });
    };

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    };

    buttonsBasedOnLevel = () => {
        const buttons = [];

        if (this.props.user.level == 1) { // ADMIN
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-check-square'} hint={'Adauga'} label={'Tichet'} description={'Adauga un nou tichet primit de la client.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddSaleModal} style={{ width: '100%' }} icon={'ft-credit-card'} hint={'Adauga'} label={'Aviz'} description={'Adauga un nou aviz pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddInvoiceModal} style={{ width: '100%' }} icon={'ft-file-text'} hint={'Adauga'} label={'Factura'} description={'Adauga o noua factura pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddClientModal} style={{ width: '100%' }} icon={'ft-briefcase'} hint={'Adauga'} label={'Client'} description={'Adauga un nou client in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} style={{ width: '100%' }} icon={'ft-layers'} hint={'Adauga'} label={'Piesa'} description={'Adauga un nou tip de piesa in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddOrderModal} style={{ width: '100%' }} icon={'ft-shopping-bag'} hint={'Adauga'} label={'Comanda'} description={'Adauga o noua comanda catre un furnizor.'}/>);
        } else if (this.props.user.level == -1) { // ACCOUNTING
            buttons.push(<AdvancedButton onPress={this.openAddSaleModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-credit-card'} hint={'Adauga'} label={'Aviz'} description={'Adauga un nou aviz pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddInvoiceModal} style={{ width: '100%' }} icon={'ft-file-text'} hint={'Adauga'} label={'Factura'} description={'Adauga o noua factura pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddClientModal} style={{ width: '100%' }} icon={'ft-briefcase'} hint={'Adauga'} label={'Client'} description={'Adauga un nou client in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} style={{ width: '100%' }} icon={'ft-layers'} hint={'Adauga'} label={'Piesa'} description={'Adauga un nou tip de piesa in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddOrderModal} style={{ width: '100%' }} icon={'ft-shopping-bag'} hint={'Adauga'} label={'Comanda'} description={'Adauga o noua comanda catre un furnizor.'}/>);
        } else if (this.props.user.level == -2) { // DEPOZIT
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-layers'} hint={'Adauga'} label={'Piesa'} description={'Adauga un nou tip de piesa in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddOrderModal} style={{ width: '100%' }} icon={'ft-shopping-bag'} hint={'Adauga'} label={'Comanda'} description={'Adauga o noua comanda catre un furnizor.'}/>);
        } else if (this.props.user.level == -3) { // CLIENT
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-check-square'} hint={'Adauga'} label={'Tichet'} description={'Adauga un nou tichet pentru a fi rezolvat de echipa Polidom.'}/>);
        } else if (this.props.user.level == 2) { // MANAGER
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-check-square'} hint={'Adauga'} label={'Tichet'} description={'Adauga un nou tichet primit de la client.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddSaleModal} style={{ width: '100%' }} icon={'ft-credit-card'} hint={'Adauga'} label={'Aviz'} description={'Adauga un nou aviz pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddInvoiceModal} style={{ width: '100%' }} icon={'ft-file-text'} hint={'Adauga'} label={'Factura'} description={'Adauga o noua factura pentru unul din clientii tai.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddClientModal} style={{ width: '100%' }} icon={'ft-briefcase'} hint={'Adauga'} label={'Client'} description={'Adauga un nou client in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddStockModal} style={{ width: '100%' }} icon={'ft-layers'} hint={'Adauga'} label={'Piesa'} description={'Adauga un nou tip de piesa in registrul tau.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddOrderModal} style={{ width: '100%' }} icon={'ft-shopping-bag'} hint={'Adauga'} label={'Comanda'} description={'Adauga o noua comanda catre un furnizor.'}/>);
        } else if (this.props.user.level == 3) { // OPERATOR
            buttons.push(<AdvancedButton onPress={this.openAddTicketModal} style={{ width: '100%', marginTop: 10 }} icon={'ft-check-square'} hint={'Adauga'} label={'Tichet'} description={'Adauga un nou tichet primit de la client.'}/>);
            buttons.push(<AdvancedButton onPress={this.openAddSaleModal} style={{ width: '100%' }} icon={'ft-credit-card'} hint={'Adauga'} label={'Aviz'} description={'Adauga un nou aviz pentru unul din clientii tai.'}/>);
        }

        return buttons;
    }

    onStatusPress = (item) => {
        this.setState({
            activeStatus: item,
        });
    }

    onPVIPressed = (ticket, action) => {
        this.props.openPDFViewModal('loading');

        mincodeServicesGeneratePDF(`${ kAPIEndpointPVI }?id=${ action.id }`, (successful, result) => {
            if (successful) {
                this.props.openPDFViewModal(
                    result.versions[ 0 ].extended,
                    {
                        type: 'pvi',
                        clientId: ticket.client.id,
                        subject: `Proces verbal de interventie nr. ${ parseInt(20000 + parseInt(action.id)) } pentru ${ ticket.name }`,
                    }
                );
            }
        });
    }

    filterForActiveStatus = (list, activeStatus) => {
        if (!activeStatus || activeStatus === 'all') {
            return list;
        }
        const result = [];
        for (let i = 0; i < list.length; ++i) {
            if (list[ i ].status === activeStatus) {
                result.push(list[ i ]);
            }
        }
        return result;
    }

    searchChange = (newValue) => {
        this.setState({
            search: newValue,
        });
    };

    openFilterModal = () => {
        this.setState({
            filterModal: true,
        });
    };

    closeFilterModal = () => {
        this.setState({
            filterModal: false,
        });
    };

    doneFilterModal = (newFilters, matchedFields) => {
        this.setState({
            fields: newFilters,
        });
        this.closeFilterModal();
    };

    openAddTicketModal = () => {
        this.setState({
            addTicketModalOpen: true,
        });
    };

    closeAddTicketModal = () => {
        this.setState({
            addTicketModalOpen: false,
        });
        this.update();
    };

    openAddSaleModal = () => {
        this.setState({
            addSaleModalOpen: true,
        });
    };

    closeAddSaleModal = () => {
        this.setState({
            addSaleModalOpen: false,
        });
        this.update();
    };

    openAddInvoiceModal = () => {
        this.setState({
            addInvoiceModalOpen: true,
        });
    };

    closeAddInvoiceModal = () => {
        this.setState({
            addInvoiceModalOpen: false,
        });
        this.update();
    };

    openAddClientModal = () => {
        this.setState({
            addClientModalOpen: true,
        });
    };

    closeAddClientModal = () => {
        this.setState({
            addClientModalOpen: false,
        });
        this.update();
    };

    openAddStockModal = () => {
        this.setState({
            addStockModalOpen: true,
        });
    };

    closeAddStockModal = () => {
        this.setState({
            addStockModalOpen: false,
        });
        this.update();
    };

    openAddOrderModal = () => {
        this.setState({
            addOrderModalOpen: true,
        });
    };

    closeAddOrderModal = () => {
        this.setState({
            addOrderModalOpen: false,
        });
        this.update();
    };

    filterByClientAndOperator = (items) => {
        const result = items;
        // now foreach operator
        let filteredByOperator = [];
        const operators = this.state.operators;
        if (operators.length > 0) {
            for (let i = 0; i < result.length; ++i) {
                for (let j = 0; j < operators.length; ++j) {
                    if (result[ i ].creator_id == operators[ j ] || result[ i ].assigned_to == operators[ j ]) {
                        filteredByOperator.push(result[ i ]);
                        break;
                    }
                }
            }
        } else {
            filteredByOperator = result;
        }

        // now foreach operator
        const filteredByClient = [];
        const clients = this.state.clients;
        if (clients.length > 0) {
            for (let i = 0; i < filteredByOperator.length; ++i) {
                for (let j = 0; j < clients.length; ++j) {
                    if (filteredByOperator[ i ].client.id == clients[ j ]) {
                        filteredByClient.push(filteredByOperator[ i ]);
                        break;
                    }
                }
            }
        } else {
            return filteredByOperator;
        }

        return filteredByClient;
    };

    getSecondLabels = (list) => {
        if (list.length > 0) {
            return list;
        } else {
            return [ 'Toti' ];
        }
    }

    matchFields = (fields) => {
        const matchedFields = [];
        for (let i = 0; i < kFilterTicketFields.length; ++i) {
            if (fields.includes(kFilterTicketFields[ i ].value)) {
                matchedFields.push(kFilterTicketFields[ i ]);
            }
        }
        return matchedFields.map((item) => { return item.label; });
    };

    onItemPress = (item) => {
        toTicketPage(item.id);
    };

    onOperatorModalDone = (newValues) => {
        this.setState({
            operators: newValues,
        });
        this.closeOperatorModal();
    }

    openOperatorModal = () => {
        this.setState({
            operatorModalOpen: true,
        });
    }

    closeOperatorModal = () => {
        this.setState({
            operatorModalOpen: false,
        });
    }

    onClientModalDone = (newValues) => {
        this.setState({
            clients: newValues,
        });
        this.closeClientModal();
    }

    openClientModal = () => {
        this.setState({
            clientModalOpen: true,
        });
    }

    closeClientModal = () => {
        this.setState({
            clientModalOpen: false,
        });
    }

}

const StatusSelectGrid = (props) => {
    const statusGroup = props.statusGroup;
    if (props.level == -1) {
        return (
            <div className={'dashboard-status-select-grid'}>
                <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusRepaired} count={statusGroup[ kStatusRepaired ]}/>
                <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusNotInvoiced} count={statusGroup[ kStatusNotInvoiced ]}/>
                <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusFinished} count={statusGroup[ kStatusFinished ]}/>
                <StatusSelectGridEntry onPress={props.onItemPress} item={'all'} count={props.all}/>
            </div>
        );
    }
    return (
        <div className={'dashboard-status-select-grid'}>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusAwaitsApproval} count={statusGroup[ kStatusAwaitsApproval ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusReceived} count={statusGroup[ kStatusReceived ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusAssigned} count={statusGroup[ kStatusAssigned ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusScheduled} count={statusGroup[ kStatusScheduled ]}/>

            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusItemsRequired} count={statusGroup[ kStatusItemsRequired ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusPartial} count={statusGroup[ kStatusPartial ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusInProgress} count={statusGroup[ kStatusInProgress ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusStopped} count={statusGroup[ kStatusStopped ]}/>

            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusRepaired} count={statusGroup[ kStatusRepaired ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusNotInvoiced} count={statusGroup[ kStatusNotInvoiced ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={kStatusFinished} count={statusGroup[ kStatusFinished ]}/>
            <StatusSelectGridEntry onPress={props.onItemPress} item={'all'} count={props.all}/>
        </div>
    );
};

export const DashboardTableListRow = ({ item, tableFields, tableFieldsKeys, customContainer, nbOfCols }) => {
    return (
        <>
            <tr className={`table-list-item-row${ customContainer ? '-advanced' : '' }`} style={{ borderLeft: `solid 10px ${ colorByStatus(item.status) }`, borderRight: `solid 10px ${ colorByStatus(item.status) }` }}>
                {
                    tableFieldsKeys.map((fieldKey) => {
                        const field = tableFields[ fieldKey ];
                        return (
                            <td
                                key={fieldKey}
                                className={`table-list-item-cell ${ field.action ? 'table-list-item-cell-action' : '' }`}
                                onClick={() => {
                                    if (field.action) {
                                        field.action(item);
                                    }
                                }}>
                                {
                                    !field.custom && field.transform(item)
                                }

                                {
                                    field.custom && field.custom(item)
                                }
                            </td>
                        );
                    })
                }
            </tr>
            {
                Boolean(customContainer) &&
                <tr className={'table-list-item-row-advanced'} style={{ borderLeft: `solid 10px ${ colorByStatus(item.status) }`, borderRight: `solid 10px ${ colorByStatus(item.status) }` }}>
                    <td colSpan={nbOfCols} className={'table-list-item-cell'}>
                        {customContainer(item)}
                    </td>
                </tr>
            }
        </>
    );
};

const StatusSelectGridEntry = (props) => {
    const style = {};
    const innerContainerStyle = {};
    //let statusColor = (new Color(colorByStatus(props.item))).darken(0.5).hexa();
    const statusColor = colorByStatus(props.item);
    style.backgroundColor = statusColor;
    let count = 0;
    if (props.count) {
        count = props.count;
    }
    let description = '';
    if (kStatusDescription[ props.item ]) {
        description = kStatusDescription[ props.item ];
    }
    let name = '';
    if (props.item) {
        name = props.item;
    }
    if (name === 'all') {
        name = 'Toate';
    }
    if (name === 'Asteapta aprobare') {
        name = 'Asteapta aprob.'; // TMP
    }

    const iconClassname = `feather ${ iconByStatus(props.item) }`;

    let buttonActiveClass = 'another-container-inactive';
    if (count > 0) {
        buttonActiveClass = 'another-container-active';
        innerContainerStyle.backgroundColor = '#eee';
    }

    if (count <= 0) {
        return null;
    }

    return (
        <div onClick={() => { if (count > 0) props.onPress(props.item); }} className={'dashboard-status-select-grid-entry-improved'} >
            <div className={'dashboard-status-select-grid-entry-improved-container'} style={{ backgroundColor: colorByStatus(props.item), color: 'white' }}>
                {
                    smallIcon(iconByStatus(props.item))
                }
                <div className={'dashboard-status-select-grid-entry-improved-title'}>
                    {name}
                </div>
                <div className={'dashboard-status-select-grid-entry-improved-count'}>
                    {count}
                </div>
            </div>
        </div>
    );

    return (
        <div className="col-lg-3 col-md-6 stretched_card" style={{ paddingLeft: 10, paddingTop: 10 }}>
            <Card className="analytics_card_dashboard" style={{ backgroundColor: statusColor, borderRadius: 8 }}>
                <CardBody style={{ padding: 20, paddingRight: 4 }}>
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <div style={{ backgroundColor: '#ffffff', padding: 10, borderRadius: 100, height: 40, width: 40 }}>
                            {
                                smallIcon(iconByStatus(props.item), statusColor, 20)
                            }
                        </div>
                        <div className="text-white flex-grow-1" style={{ paddingLeft: 20 }}>
                            <p className="mt-xl-0 text-xl-left mb-2">{name}</p>
                            <div className="d-flex flex-row flex-wrap align-items-center ">
                                <h3 className="mb-0 text-white">{count}</h3>
                                <div className="stats_icon" onClick={() => { if (count > 0) props.onPress(props.item); }}>vezi<span className="feather ft-eye" style={{ paddingLeft: 4 }}/></div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};


const AdvancedButton = (props) => {
    let style = { marginLeft: 10 };
    if (props.style) {
        style = { ...props.style };
    }

    return (
        <div className={'advanced-button'} onClick={props.onPress} style={style}>

            {
                props.icon &&
                <div className={'icon-container'}>
                    {
                        smallIcon(props.icon, null, 20)
                    }
                </div>
            }

            <div className={'text-container'}>
                <div className={'label-container'}>
                    {
                        props.label
                    }
                </div>
                {
                    props.description &&
                    <div className={'description-container'}>
                        {
                            props.description
                        }
                    </div>
                }
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link, data) => { return dispatch(openPDFViewModal(link, data)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardPage));