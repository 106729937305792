import React from 'react';
import PropTypes from 'prop-types';
import { getAllSales } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterSaleFields, kFilterSaleFieldsDefault } from '../functional/constants';
import AddSaleModal from '../components/modal/AddSaleModal';
import { connect } from 'react-redux';
import { compareGenerator, compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import { toClientPage, toSalePage, toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';

class SalesPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    }

    state = SalesPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-sales-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(SalesPage.defaultState, this.update);
    }

    update = () => {
        getAllSales((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga aviz',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Numar',
                        transform: (item) => { return item.name; },
                        action: (item) => { toSalePage(item.id); },
                        compare: compareGeneratorString('name'),
                        icon: 'ft-align-justify',
                    },
                    'date': {
                        title: 'Data',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGenerator('created'),
                        icon: 'ft-calendar',
                    },
                    'client': {
                        title: 'Client',
                        transform: (item) => { return item.client_name; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_name'),
                        icon: 'ft-briefcase',
                    },
                    'operator': {
                        title: 'Operator',
                        transform: (item) => { return item.username; },
                        action: (item) => { toUserPage(item.creator_id); },
                        compare: compareGeneratorString('username'),
                        icon: 'ft-user',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toSalePage(item.id); },
                        weight: 0.3,
                    },
                }}
                defaultSort={'date'}
                defaultFields={kFilterSaleFieldsDefault}
                allFields={kFilterSaleFields}>

                <AddSaleModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, false)(withRouter(SalesPage));