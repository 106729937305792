import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/animations/animation-loading';

class LoadingAnimation extends Component {
    
    render () {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };
        return (
            <Lottie options={defaultOptions} height={200} width={200}/>
        );
    }
    
}

export default LoadingAnimation;