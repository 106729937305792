import React, { Component } from 'react';
import { attemptAuthenticate } from '../../functional/logic';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { inputValidationStringLengthBetween, inputValidationStringNonEmpty, is } from '../../functional/min-support';
import TextFieldInput from '../../components/input/TextFieldInput';
import { setAuthenticated, snackAdd } from '../../store/actions/application-store-actions';
import { getLoginUsername } from '../../functional/storage';
import { Alert, Card, CardBody, Col, Form } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Recaptcha from 'react-recaptcha';

class LoginPage extends Component {

    state = {
        username: '',
        password: '',

        loading: false,
        successful: false,
        error: null,

        captchaLoaded: false,
        captchaVerified: null,
    };

    componentDidMount () {
        this.attemptToGetLastUsername();
    }

    attemptToGetLastUsername = () => {
        const username = getLoginUsername();
        if (is(username) && this.state.username === '') {
            this.setState({
                username,
            });
        }
    };

    render () {

        return (
            <div className="login-page">
                <div className="login-page-overlay">
                    <Col md="4" className="m-auto h-100 align-middle d-flex align-items-center">
                        <Card className="flex-grow-1">
                            <CardBody>
                                <h4 className="card_title text-primary">Autentificare Polidom</h4>
                                { this.state.successful && <Alert color="primary">Autentificare reusita.</Alert> }
                                { this.state.error && <Alert color="danger">Autentificare esuata. {this.state.error}</Alert> }
                                <Form onSubmit={(event) => { event.preventDefault(); this.onLoginPressed(); }}>
                                    <TextFieldInput
                                        title
                                        required
                                        classname={'mb-4'}
                                        validated={inputValidationStringNonEmpty(this.state.username)}
                                        label={'Nume utilizator'}
                                        value={this.state.username}
                                        icon={'ft-lock'}
                                        placeholder={'Introduceti numele utilizatorului'}
                                        onChange={(newValue) => { this.setState({ username: newValue }); }}/>

                                    <TextFieldInput
                                        title
                                        required
                                        classname={'mb-4'}
                                        validated={inputValidationStringLengthBetween(this.state.password, 4, 100)}
                                        label={'Parola'}
                                        value={this.state.password}
                                        icon={'ft-lock'}
                                        secure
                                        placeholder={'Introduceti parola'}
                                        onChange={(newValue) => { this.setState({ password: newValue }); }}/>

                                    <Recaptcha
                                        sitekey="6LeGv98fAAAAAPxEtlqmPPzapAAFl-wgIRREXaFM"
                                        render="explicit"
                                        expiredCallback={() => { this.setState({ captchaVerified: null }); }}
                                        onloadCallback={() => { this.setState({ captchaLoaded: true }); }}
                                        verifyCallback={(verification) => { this.setState({ captchaVerified: verification }); }}/>

                                    <div className="submit-btn-area mt-4">

                                        <LaddaButton
                                            loading={this.state.loading}
                                            onClick={this.onLoginPressed}
                                            data-style={EXPAND_LEFT}
                                            className="btn btn-primary">

                                            Autentificare

                                        </LaddaButton>

                                    </div>

                                    <div className="form-footer text-center mt-4">
                                        <p className="text-muted">Nu stii ce este asta?<Link to={{ pathname: 'https://polidomservice.ro' }} target="_blank"><i className="mdi mdi-lock"></i>Citeste mai multe</Link></p>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </div>
        );
    }

    onLoginPressed = () => {
        this.setState({
            loading: true,
        });
        const username = this.state.username;
        const password = this.state.password;
        if (!inputValidationStringNonEmpty(username)) {
            this.setState({ loading: false, successful: false, error: 'Trebuie sa introduci numele de utilizator.' });
            return;
        }

        if (!inputValidationStringLengthBetween(password, 4, 100)) {
            this.setState({ loading: false, successful: false, error: 'Parola trebuie sa aiba cel putin 4 caractere.' });
            return;
        }

        if (this.state.captchaLoaded && !this.state.captchaVerified) {
            this.setState({ loading: false, successful: false, error: 'Trebuie sa completezi captch-ul pentru a putea continua.' });
            return;
        }

        attemptAuthenticate(username, password, (successful, data) => {
            if (successful) {
                this.props.onSetAuthenticated(true, data);
                this.props.history.push('/dashboard');
            } else {
                this.setState({ loading: false, successful: false, error: 'Utilizatorul si parola nu se potrivesc.' });
            }
        });
    }
}

LoginPage.propTypes = {
    onSetAuthenticated: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAuthenticated: (value, data = null) => { return dispatch(setAuthenticated(value, data)); },
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
    };
};

export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
