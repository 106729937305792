import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { snackAdd } from '../../../store/actions/application-store-actions';
import PropTypes from 'prop-types';
import BaseModal from './BaseModal';
import TextFieldDisplay from '../../input/TextFieldDisplay';
import MapPicker from 'react-google-map-picker';
import TextFieldInput from '../../input/TextFieldInput';
import Geocode from 'react-geocode';
import { debounce } from '../../../functional/min-support';

const defaultLocation = {
    lat: 47.15762043824356,
    lng: 27.57635931378952,
};
 
const defaultAddress = 'Sediul Polidom Service Iasi';

class LocationPickerModal extends Component {
    static propTypes = {
        open: PropTypes.bool,

        title: PropTypes.string,
        
        initialAddress: PropTypes.string,
        initialPosition: PropTypes.object,

        onClose: PropTypes.func,
        onMore: PropTypes.func,
        onDone: PropTypes.func,

        onMoreText: PropTypes.string,

        children: PropTypes.array,
    };
    
    state = {
        searchAddress: '',
        address: defaultAddress,
        position: defaultLocation,
        zoom: 18,
    };
    
    componentDidMount () {
        Geocode.setApiKey('AIzaSyCb-C6kVk6reWGnq0sF_eSHpO1xCc2jdq4');
        Geocode.setLanguage('ro');
        Geocode.setRegion('ro');
    }
    
    /*
     *handleLocationChange = ({ position, address, places }) => {
     *  this.setState({ position, address });
     *}
     */
    
    handleLocationChange = (lat, lng) => {
        this.setState({ position: { lat, lng } });
        this.geolocationReverseTransform(lat, lng);
    }
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update(nextProps);
        }
    }

    clear = () => {
        this.setState({
            address: defaultAddress,
            position: defaultLocation,
            zoom: 18,
        });
    };
    
    update = (props) => {
        //this.clear();
        this.setState({
            address: this.props.initialAddress,
            position: this.props.initialPosition,
        });
    };
    
    geolocationTransform = debounce((searchAddress) => {
        Geocode.fromAddress(searchAddress).then(
            (response) => {
                const { lat, lng } = response.results[ 0 ].geometry.location;
                this.setState({
                    position: { lat, lng },
                });
                this.geolocationReverseTransform(lat, lng);
            },
            (error) => {
                console.error(error);
            }
        );
    }, 500);
    
    geolocationReverseTransform = (lat, lng) => {
        Geocode.fromLatLng(lat, lng).then((response) => {
            const address = response.results[ 0 ].formatted_address;
            this.setState({
                address,
            });
        }, (error) => {
            console.error(error);
            this.setState({
                address: 'Adresa indisponibila',
            });
        });

        /*
         *Geocode.fromAddress(this.state.searchAddress).then(
         *  response => {
         *      const { lat, lng } = response.results[0].geometry.location;
         *      this.setState({
         *          position: response.results[0].geometry.location,
         *      });
         *  },
         *  error => {
         *      console.error(error);
         *  }
         *);
         */

    };
    
    addressSearchChange = (newValue) => {
        this.setState({
            searchAddress: newValue,
        });
        this.geolocationTransform(newValue);
    }

    render () {
        return (
            <BaseModal
                open={this.props.open}
                hideDone={false}
                doneActive={this.inParameters}
                stableHeight={true}
                stableWidth={true}

                title={this.props.title}

                onMore={this.props.onMore}
                onMoreText={this.props.onMoreText}

                onClose={this.props.onClose}
                onDone={this.onDone}>

                <div className="location-picker-modal d-flex flex-column align-items-start w-100 h-100">
                    <div style={{ width: '100%', marginBottom: 12 }}>
                        <TextFieldInput
                            flex={1}
                            style={{ flex: 1 }}
                            label={'Cauta adresa'}
                            value={this.state.searchAddress}
                            onChange={this.addressSearchChange}/>
                    </div>
    
                    <MapPicker
                        defaultLocation={this.state.position}
                        location={this.state.position}
                        zoom={this.state.zoom}
                        mapTypeId="roadmap"
                        style={{ height: '100%', width: '100%' }}
                        onChangeLocation={this.handleLocationChange}
                        onChangeZoom={(newValue) => {
                            this.setState({
                                zoom: newValue,
                            });
                        }}
                        apiKey="AIzaSyC4xQ0dc4wSggeJGouXR6Xh-N7ikXNEoxM"/>
                    
                    <div style={{ width: '100%', paddingTop: 10 }}>
                        <TextFieldDisplay
                            flex={1}
                            style={{ flex: 1 }}
                            label={'Adresa selectata acum'}
                            value={this.state.address}
                            onChange={(newValue) => { this.setState({ address: newValue }); }}/>
                    </div>
                </div>

                {
                    this.props.children
                }

            </BaseModal>
        );
    }

    get inParameters () {
        return this.state.address.length > 0;
    }

    onDone = () => {
        this.props.onDone(this.state.address, this.state.position);
    };

}

/*
 *
 *<LocationPicker
 *    containerElement={ <div style={ {flex: 1, width: '100%', paddingTop: 10} } /> }
 *    mapElement={ <div style={ {height: '100%', width: '100%'} } /> }
 *    defaultPosition={this.state.position}
 *    onChange={this.handleLocationChange}
 *    zoom={18}
 *    radius={-1}/>
 *
 */

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(LocationPickerModal));