import React from 'react';
import PropTypes from 'prop-types';
import { getAllInvoices } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterInvoiceFields, kFilterInvoiceFieldsDefault } from '../functional/constants';
import AddInvoiceModal from '../components/modal/AddInvoiceModal';
import { connect } from 'react-redux';
import { compareGenerator, compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import { toClientPage, toInvoicePage, toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';

class InvoicesPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    }

    state = InvoicesPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-invoices-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(InvoicesPage.defaultState, this.update);
    }

    update = () => {
        getAllInvoices((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };

    render () {
        // TODO: if(user is not authorized) => no add button
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga factura',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Numar',
                        transform: (item) => { return item.name; },
                        action: (item) => { toInvoicePage(item.id); },
                        compare: compareGeneratorString('name'),
                        icon: 'ft-align-justify',
                    },
                    'date': {
                        title: 'Data',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGenerator('created'),
                        icon: 'ft-calendar',
                    },
                    'client': {
                        title: 'Client',
                        transform: (item) => { return item.client_name; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_name'),
                        icon: 'ft-briefcase',
                    },
                    'cif': {
                        title: 'CIF',
                        transform: (item) => { return item.client_cif; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_cif'),
                        icon: 'ft-hash',
                    },
                    'reg': {
                        title: 'Reg. Com.',
                        transform: (item) => { return item.client_reg; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_reg'),
                        icon: 'ft-code',
                    },
                    'operator': {
                        title: 'Operator',
                        transform: (item) => { return item.username; },
                        action: (item) => { toUserPage(item.creator_id); },
                        compare: compareGeneratorString('username'),
                        icon: 'ft-user',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toInvoicePage(item.id); },
                        weight: 0.5,
                    },
                }}
                defaultSort={'date'}
                defaultFields={kFilterInvoiceFieldsDefault}
                allFields={kFilterInvoiceFields}>

                <AddInvoiceModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, false)(withRouter(InvoicesPage));