import React from 'react';
import PropTypes from 'prop-types';
import { getAllTickets, kAPIEndpointPVI } from '../functional/api';
import { kFilterTicketFields, kFilterTicketFieldsDefault } from '../functional/constants';
import AddTicketModal from '../components/modal/AddTicketModal';
import { withRouter } from 'react-router-dom';
import { toClientPage, toTicketPage, toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import { compareGeneratorString, prettyDateAndTime, smallIcon } from '../functional/min-support';
import TablePage from '../layout/page/TablePage';
import { colorByStatus, iconByStatus } from '../functional/status';
import { ticketActionAdvancedFieldsSimple } from './TicketPage';
import { mincodeServicesGeneratePDF, translateTicketActionList } from '../functional/logic';
import { openPDFViewModal } from '../store/actions/application-store-actions';
import { connect } from 'react-redux';

const ticketHeight = '310px';

class TicketsPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = TicketsPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-tickets-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(TicketsPage.defaultState, this.update);
    }

    update = () => {
        getAllTickets((successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    data[ index ].priority_text = priorityNbBasedOnText(data[ index ].priority);
                    data[ index ].client_name = data[ index ].client.name;
                    data[ index ].client_id = data[ index ].client.id;
                    data[ index ].client_hq_address = data[ index ].client.headquarters.address;
                });
                this.setState({
                    items: data,
                });
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={true}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga tichet',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Numar',
                        transform: (item) => { return <>{item.name} {item.creator_id == '75' && <span style={{ color: '#642626' }}>{' POLIDOM'}</span>}</>; },
                        action: (item) => { toTicketPage(item.id); },
                        compare: compareGeneratorString('name', true),
                        icon: 'ft-align-justify',
                    },
                    'date': {
                        title: 'Data',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGeneratorString('created', true),
                        icon: 'ft-calendar',
                    },
                    'type': {
                        title: 'Tipul',
                        transform: (item) => { return `${ item.type } (${ item.priority_text })`; },
                        compare: compareGeneratorString('type'),
                        icon: 'ft-clipboard',
                    },
                    'status': {
                        title: 'Status',
                        transform: (item) => { return <span style={{ color: colorByStatus(item.status) }}>{item.status}</span>; },
                        compare: compareGeneratorString('status'),
                        icon: 'ft-code',
                    },
                    'client': {
                        title: 'Client',
                        transform: (item) => { return item.client_name; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_name'),
                        icon: 'ft-briefcase',
                    },
                    'headquarters': {
                        title: 'Adresa',
                        transform: (item) => { return item.client_hq_address; },
                        action: (item) => { toClientPage(item.client_id); },
                        compare: compareGeneratorString('client_hq_address'),
                        icon: 'ft-map-pin',
                    },
                    'equipments': {
                        title: 'Echipamente',
                        transform: (item) => { return item.equipments ? item.equipments.map((equipment) => { return <><span>{equipment.title}</span><br/></>; }) : ''; },
                        icon: 'ft-settings',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toTicketPage(item.id); },
                        weight: 0.5,
                    },
                }}
                customContainer={(item) => {
                    const actions = translateTicketActionList(item.actions);
                    return (
                        <div className={'table-action-list'}>
                            {
                                actions && actions.map((action, index) => {
                                    return (
                                        <>
                                            {index > 0 && <span style={{ marginRight: 10 }}>{smallIcon('ft-chevron-left')}</span>}
                                            <div className={'table-action-list-item'}>
                                                <div className={'table-action-list-item-entry'} style={{ color: colorByStatus(action.status) }}>
                                                    <span style={{ marginRight: 4 }}>{smallIcon(iconByStatus(action.status))}</span>
                                                    {action.status}
                                                </div>
                                                <div className={'table-action-list-item-entry'}>
                                                    <span style={{ marginRight: 4 }}>{smallIcon('ft-calendar')}</span>
                                                    {prettyDateAndTime(action.created)}
                                                </div>
                                                <div onClick={() => { toUserPage(action.user.id); }} className={'table-action-list-item-entry table-action-list-item-entry-action'}>
                                                    <span style={{ marginRight: 4 }}>{smallIcon('ft-user')}</span>
                                                    {`${ action.user.first_name } ${ action.user.last_name }`}
                                                </div>
                                                {
                                                    ticketActionAdvancedFieldsSimple(action, (action) => { this.onPVIPressed(item, action); })
                                                }
                                            </div>
                                        </>
                                    );
                                })
                            }
                        </div>
                    );
                }}
                defaultSort={'date'}
                defaultFields={kFilterTicketFieldsDefault}
                allFields={kFilterTicketFields}>

                <AddTicketModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }

    onItemClick = (item) => {
        toTicketPage(item.id);
    }

    onPVIPressed = (ticket, action) => {
        this.props.openPDFViewModal('loading');


        mincodeServicesGeneratePDF(`${ kAPIEndpointPVI }?id=${ action.id }`, (successful, result) => {
            if (successful) {
                this.props.openPDFViewModal(
                    result.versions[ 0 ].extended,
                    {
                        type: 'pvi',
                        clientId: ticket.client.id,
                        subject: `Proces verbal de interventie nr. ${ parseInt(20000 + parseInt(action.id)) } pentru ${ ticket.name }`,
                    }
                );
            }
        });
    }

}

export const priorityNbBasedOnText = (prioText) => {
    if (prioText === 'normal') {
        return 'prio 2';
    } else if (prioText === 'low') {
        return 'prio 3';
    } else if (prioText === 'high') {
        return 'prio 1';
    } else if (prioText === 'urgent') {
        return 'urgent';
    }
    return prioText;
};

const mapStateToProps = (state) => {
    return {
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link, data) => { return dispatch(openPDFViewModal(link, data)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketsPage));
