import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { openUpdateModal, setItemPageTitle } from '../store/actions/application-store-actions';
import { getOrder } from '../functional/api';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { filter, prettyDateAndTime } from '../functional/min-support';
import { toStockItemPage, toSupplierPage } from '../functional/navigation';
import { translateOrderProducts } from '../functional/logic';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';

class OrderPage extends Component {
    
    static propTypes = {
        history: PropTypes.object,
    };
    
    static defaultState = {
        item: null,
        search: '',
        modalOpen: false,
    };
    
    state = OrderPage.defaultState;
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }
    
    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }
    
    generalUpdate = () => {
        this.setState(OrderPage.defaultState, this.update);
    }
    
    update = () => {
        const key = this.props.match.params.key;
        getOrder(key, (successful, data) => {
            if (successful) {
                this.setState({
                    item: data,
                });
                this.props.onSetItemPageTitle(data.name);
            }
        });
    };
    
    render () {
        let stock = this.state.item ? translateOrderProducts(this.state.item.stock) : null;
        const toolbarActions = [
            {
                title: 'Adauga produs',
                onPress: () => { this.setState({ modalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];
        const toolbarLabels = [
            {
                connection: 'se cauta produsele dupa:',
                tags: [ 'Denumirea produsului' ],
            },
        ];
        if (stock && this.state.search.length > 0) {
            stock = filter(stock, [ 'search_name' ], this.state.search, false);
        }
        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>
                <div className="w-100 h-100 overflow-hidden d-flex flex-row">
                
                    <div className="h-100 overflow-scroll" style={{ flex: 1 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'} style={{ margin: 20 }}>
    
                                    <TextFieldDisplay
                                        title
                                        value={this.state.item.name}
                                        style={{ marginBottom: 4 }}/>
    
                                    <TextFieldDisplay
                                        label={'creata la'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{ marginBottom: 4 }}/>
    
                                    <TextFieldDisplay
                                        label={'de catre operatorul'}
                                        value={`${ this.state.item.user.first_name } ${ this.state.item.user.last_name }`}
                                        icon={'ft-users'}
                                        style={{ marginBottom: 4 }}/>
    
                                    <TextFieldDisplay
                                        label={'furnizor'}
                                        value={this.state.item.supplier.name}
                                        icon={'ft-package'}
                                        action={'ft-eye'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            toSupplierPage(this.state.item.supplier.id);
                                        }}/>
    
                                    {
                                        this.state.item.supplier.address &&
                                        <TextFieldDisplay
                                            value={this.state.item.supplier.address}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.supplier.cif &&
                                        <TextFieldDisplay
                                            value={this.state.item.supplier.cif}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.supplier.reg &&
                                        <TextFieldDisplay
                                            value={this.state.item.supplier.reg}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.supplier.bank &&
                                        <TextFieldDisplay
                                            value={this.state.item.supplier.bank}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
    
                                    {
                                        this.state.item.supplier.iban &&
                                        <TextFieldDisplay
                                            value={this.state.item.supplier.iban}
                                            icon={'blank'}
                                            style={{ marginBottom: 4 }}/>
                                    }
                            
                                </div>
    
                                {
                                    stock && stock.length > 0 &&
                                    <div className="w-100 d-flex flex-row flex-wrap" style={{ paddingLeft: 20, paddingBottom: 20 }}>
                                        {
                                            stock.map((item) => {
                                                return (
                                                    <div key={item.id} style={{ width: '25%', paddingRight: 20, paddingBottom: 20 }}>
                                                        <div className="general-card" style={{ padding: 0 }}>
                                                            <div style={{ width: '100%', height: 300 }}>
                                                                <ImageFieldDisplay value={item.services_picture_uid_m} full={item.services_picture_uid_o} imageStyle={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}/>
                                                            </div>
                                                            <div style={{ padding: 20 }}>
                                                                <TextFieldDisplay
                                                                    icon={'ft-package'}
                                                                    oneLine
                                                                    value={item.item.name}
                                                                    style={{ marginBottom: 4 }}/>
    
                                                                <TextFieldDisplay
                                                                    label={'incluse in comanda'}
                                                                    value={`${ item.count ? item.count : 0 } ${ item.item.counter }`}
                                                                    icon={'ft-layers'}
                                                                    style={{ marginBottom: 4 }}/>
    
                                                                <TextFieldDisplay
                                                                    icon={'blank'}
                                                                    value={'Vezi produsul'}
                                                                    action={'ft-eye'}
                                                                    onPress={() => { toStockItemPage(item.item.id); }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
    
                                {
                                    stock && stock.length <= 0 &&
                                    <h3 style={{ marginTop: 20, paddingLeft: 20 }}>
                                        Niciun produs
                                    </h3>
                                }

                            </React.Fragment>
                        }
                    
                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                        
                    </div>
                </div>
        
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        updateModal: state.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(setItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(openUpdateModal(id, previous, type, on)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderPage));