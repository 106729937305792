export const kActionApplicationSetAuthenticated = 'SET_AUTHENTICATED';
export const kActionApplicationPopulateUser = 'POPULATE_USER';

export const kActionApplicationToggleDrawer = 'TOGGLE_DRAWER';
export const kActionApplicationSetItemPageTitle = 'SET_ITEM_PAGE_TITLE';

export const kActionApplicationModalOpenUpdateModal = 'MODAL_UPDATE_MODAL_OPEN';
export const kActionApplicationModalCloseUpdateModal = 'MODAL_UPDATE_MODAL_CLOSE';

export const kActionApplicationModalOpenPDFViewModal = 'MODAL_PDF_VIEW_MODAL_OPEN';
export const kActionApplicationModalClosePDFViewModal = 'MODAL_PDF_VIEW_MODAL_CLOSE';

export const kActionApplicationModalOpenLoadingModal = 'MODAL_LOADING_MODAL_OPEN';
export const kActionApplicationModalCloseLoadingModal = 'MODAL_LOADING_MODAL_CLOSE';

export const kActionApplicationModalOpenAddEmailModal = 'MODAL_ADD_EMAIL_MODAL_OPEN';
export const kActionApplicationModalCloseAddEmailModal = 'MODAL_ADD_EMAIL_MODAL_CLOSE';

export const kActionApplicationSnackAdd = 'SNACKBAR_ADD';
export const kActionApplicationSnackRemove = 'SNACKBAR_REMOVE';

export const setAuthenticated = (value, info = null) => {
    return {
        type: kActionApplicationSetAuthenticated,
        value,
        info,
    };
};

export const populateUser = (data) => {
    return {
        type: kActionApplicationPopulateUser,
        data,
    };
};

export const toggleDrawer = () => {
    return {
        type: kActionApplicationToggleDrawer,
    };
};

export const openUpdateModal = (id, previousValue, type, on) => {
    return {
        type: kActionApplicationModalOpenUpdateModal,
        id,
        previousValue,
        updateType: type,
        on,
    };
};

export const closeUpdateModal = () => {
    return {
        type: kActionApplicationModalCloseUpdateModal,
    };
};

export const openPDFViewModal = (link, info = null) => {
    return {
        type: kActionApplicationModalOpenPDFViewModal,
        data: link,
        info,
    };
};

export const closePDFViewModal = () => {
    return {
        type: kActionApplicationModalClosePDFViewModal,
    };
};

export const openLoadingModal = () => {
    return {
        type: kActionApplicationModalOpenLoadingModal,
    };
};

export const closeLoadingModal = () => {
    return {
        type: kActionApplicationModalCloseLoadingModal,
    };
};

export const openAddEmailModal = () => {
    return {
        type: kActionApplicationModalOpenAddEmailModal,
    };
};

export const closeAddEmailModal = () => {
    return {
        type: kActionApplicationModalCloseAddEmailModal,
    };
};

export const snackAdd = (message, action) => {
    return {
        type: kActionApplicationSnackAdd,
        label: message,
        action,
    };
};

export const snackRemove = () => {
    return {
        type: kActionApplicationSnackRemove,
    };
};

export const setItemPageTitle = (value = null) => {
    return {
        type: kActionApplicationSetItemPageTitle,
        value,
    };
};