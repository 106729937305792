import React from 'react';
import PropTypes from 'prop-types';

class Form extends React.Component {
    static propTypes = {
        children: PropTypes.array,

        complete: PropTypes.bool,
        setComplete: PropTypes.func,
    };

    render () {
        return (
            this.filterChildren()
        );
    }

    filterChildren = () => {
        const children = [];
        let completedToHere = true;
        this.props.children.some((child) => {
            if (child && Array.isArray(child)) {
                for (let i = 0; i < child.length; ++i) {
                    const innerChild = child[ i ];
                    if (innerChild && innerChild.props) {
                        if (innerChild.props.requireCompletion) {
                            if (completedToHere) {
                                children.push(innerChild);
                            } else {
                                return true;
                            }
                        } else {
                            children.push(innerChild);
                        }
                        if (innerChild.props.required) {
                            if (!innerChild.props.validated) {
                                completedToHere = false;
                            }
                        }
                    }
                }
            } else {
                if (child && child.props) {
                    if (child.props.requireCompletion) {
                        if (completedToHere) {
                            children.push(child);
                        } else {
                            return true;
                        }
                    } else {
                        children.push(child);
                    }
                    if (child.props.required) {
                        if (!child.props.validated) {
                            completedToHere = false;
                        }
                    }
                }
            }
            return false;
        });
        if (this.props.complete !== completedToHere) {
            this.props.setComplete(completedToHere);
        }
        return children;
    }
}

export default Form;