import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBriefListOperators } from '../../../functional/api';
import PickerModal from '../model/PickerModal';

class Picker extends Component {

    static propTypes = {
        open: PropTypes.bool,
        values: PropTypes.any,

        onClose: PropTypes.func,
        onDone: PropTypes.func,

        setLabels: PropTypes.func,
        
        minimumChoices: PropTypes.number,
        maximumChoices: PropTypes.number,
    };

    state = {
        options: [],

        modalOpen: false,
    };

    componentDidMount () {
        this.update();
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.values.length != nextProps.values.length) {
            //this.updateLabel(nextProps.values);
        }
    }

    update = () => {
        getBriefListOperators((successful, data) => {
            if (successful) {
                this.setState({
                    options: data,
                }, () => {
                    this.updateLabel(this.props.value);
                });
            } else {
                console.error('data retrieve error');
            }
        });
    };

    render () {
        return (
            <PickerModal
                open={this.props.open}
                options={this.state.options}
                initialSelectedValues={this.props.values}

                title={'Alege operatorii'}

                search={true}
                searchInDescription={false}

                minimumChoices={this.props.minimumChoices}
                maximumChoices={this.props.maximumChoices}

                onClose={this.props.onClose}
                onDone={this.onDone}>

            </PickerModal>
        );
    }
    
    onDone = (values) => {
        this.props.onDone(values);
        this.updateLabel(values);
    }

    updateLabel = (newValues) => {
        if (this.props.setLabels && newValues) {
            const labels = [];
            for (let j = 0; j < newValues.length; ++j) {
                for (let i = 0; i < this.state.options.length; ++i) {
                    if (this.state.options[ i ].value === newValues[ j ]) {
                        labels.push(this.state.options[ i ].label);
                        break;
                    }
                }
            }
            this.props.setLabels(labels);
        }
    };

}

export default Picker;