import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addSale, getAllStock, getStockOwn, getStockOwnAll } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationLengthNonZero,
    inputValidationSingleChoice,
    inputValidationSpecificAllStockHasQuantityAndPrice,
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';

const kSaleTypes = [
    {
        value: 'client',
        label: 'Client',
        description: 'Produsele sunt vandute unui client obisnuit.',
    },
    {
        value: 'manager',
        label: 'Manager partener',
        description: 'Produsele sunt vandute unui manager partener.',
    },
];

class Modal extends Component {

    static propTypes = {
        open: PropTypes.bool,

        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };

    state = {
        clientId: -1,
        stock: [],
        stockSelected: [],
        stockQuantity: {},
        stockPrice: {},
        allStock: [],

        name: '',

        saleType: 'client',

        formComplete: false,
    };

    render () {
        console.log('stockSelected', this.state.stockSelected);
        console.log('stockQuantity', this.state.stockQuantity);
        console.log('stockPrice', this.state.stockPrice);
        return (
            <BaseModal
                title={'Adauga aviz'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => {
                        this.setState({ formComplete: complete });
                    }}>

                        {
                            (this.props.user.level == 1 || this.props.user.level == -2 || this.props.user.level == -1) &&
                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.saleType)}

                                icon={'ft-code'}
                                label={'Tipul cumparatorului'}
                                options={kSaleTypes}
                                value={this.state.saleType}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        saleType: value,
                                        clientId: -1,
                                    });
                                }}/>
                        }

                        {
                            this.state.saleType == 'client' &&
                            <SingleChoiceField
                                required
                                requireCompletion
                                validated={inputValidationSingleChoice(this.state.clientId)}

                                icon={'ft-briefcase'}
                                label={'Clientul'}
                                predefinedModal={'client'}
                                value={this.state.clientId}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        clientId: value,
                                    });
                                }}/>
                        }

                        {
                            this.state.saleType != 'client' &&
                            <SingleChoiceField
                                required
                                requireCompletion
                                validated={inputValidationSingleChoice(this.state.clientId)}

                                icon={'ft-briefcase'}
                                label={'Managerul'}
                                predefinedModal={'manager'}
                                value={this.state.clientId}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        clientId: value,
                                    });
                                }}/>
                        }

                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationLengthNonZero(this.state.stockSelected)}
                            search

                            minimumChoices={0}
                            maximumChoices={100}

                            icon={'ft-layers'}
                            label={'Alege piesele care au fost incluse'}
                            options={this.state.stock}
                            values={this.state.stockSelected}
                            style={{ marginBottom: 4 }}
                            onSelect={(values) => {
                                this.setState({
                                    stockSelected: values,
                                });
                            }}/>

                        <div
                            requireCompletion
                            required
                            validated={inputValidationSpecificAllStockHasQuantityAndPrice(this.state.stockSelected, this.state.stockQuantity, this.state.stockPrice)}>
                            {
                                this.state.stockSelected.map((item) => {
                                    let found = this.state.stock.filter((i) => { return i.value == item; });
                                    console.log('found', found);
                                    const newFound = this.state.allStock.filter((i) => { return i.id == item; });
                                    console.log('new found', newFound);
                                    if (found.length <= 0 && newFound.length > 0) {
                                        found.push({ value: newFound[ 0 ].id, label: newFound[ 0 ].name, description: newFound[ 0 ].model, price: '0', total: newFound[ 0 ].own, counter: newFound[ 0 ].counter });
                                    }
                                    if (found.length > 0) {
                                        found = found[ 0 ];
                                        return (
                                            <StockEntry
                                                item={found}
                                                onChange={(value, newCount, newPrice) => {

                                                    if (parseFloat(newCount) > parseFloat(found.total)) {
                                                        newCount = found.total;
                                                    }
                                                    const q = this.state.stockQuantity;
                                                    q[ value ] = newCount;

                                                    const p = this.state.stockPrice;
                                                    p[ value ] = newPrice;

                                                    this.setState({
                                                        stockQuantity: q,
                                                        stockPrice: p,
                                                    });
                                                }}
                                                counter={found.counter}
                                                maxQuantity={found.total}
                                                quantity={this.state.stockQuantity.hasOwnProperty(found.value) ? this.state.stockQuantity[ found.value ] : ''}
                                                price={this.state.stockPrice.hasOwnProperty(found.value) ? this.state.stockPrice[ found.value ] : ''}
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const selected = this.state.stockSelected;
        const stock = [];
        for (let i = 0; i < selected.length; ++i) {
            stock.push({
                count: this.state.stockQuantity[ selected[ i ] ],
                price: this.state.stockPrice[ selected[ i ] ],
                value: selected[ i ],
            });
        }

        const request = {
            saleType: this.state.saleType,
            name: this.state.name,
            clientId: this.state.clientId,
            stock,
        };

        this.props.openLoading();

        addSale(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Aviz inregistrat cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    setInitial = (initial) => {
        const finalState = {
            stockSelected: [],
            stockQuantity: {},
            stockPrice: {},
        };
        initial.forEach((item) => {
            finalState.stockSelected.push(`${ item.id }`);
            finalState.stockPrice[ item.id ] = `${ item.price }`;
            finalState.stockQuantity[ item.id ] = `${ item.quantity }`;
        });
        this.setState({
            ...finalState,
        });
    }

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
            if (nextProps.initial && nextProps.clientId) {
                this.setState({ saleType: 'manager', clientId: nextProps.clientId });
                this.setInitial(nextProps.initial);
            }
        }
    }

    update = () => {
        getAllStock((successful, allStock) => {
            if (successful) {
                this.setState({ allStock });
            }
        });
        getStockOwnAll((successful, data) => {
            if (successful) {
                this.setState({
                    stock: data.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                            description: `Ai in stoc: ${ item.total } ${ item.counter }. Pretul din baza de date: ${ item.price } RON`,
                            total: item.total,
                            counter: item.counter,
                            price: item.price,
                        };
                    }),
                });
            } else {
                console.error('no add action info');
            }
        });
    };

    clear = () => {
        this.setState({
            clientId: -1,
            stock: [],
            stockSelected: [],
            stockQuantity: {},
            stockPrice: {},
            name: '',
            saleType: 'client',
        });
    }

}

const StockEntry = (props) => {
    return (
        <div
            style={{
                width: '100%',
                padding: 10,
                marginBottom: 10,
                backgroundColor: '#eeeeee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                flex: 5,
            }}>

            <TextFieldDisplay
                style={{ flex: 4 }}
                label={'piesa'}
                value={props.item.label}
                icon={'ft-settings'}/>

            <TextFieldInput
                flex={1}
                style={{ flex: 1 }}
                label={`Numar ${ props.counter } < ${ props.maxQuantity }`}
                value={props.quantity}
                onChange={(newValue) => {
                    props.onChange(props.item.value, newValue, props.price);
                }}/>

            <TextFieldInput
                flex={1}
                style={{ flex: 1 }}
                label={'Pret per U.M. (RON)'}
                value={props.price}
                onChange={(newValue) => {
                    props.onChange(props.item.value, props.quantity, newValue);
                }}/>

        </div>
    );
};

StockEntry.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
