import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { addEmailAddress } from '../../functional/api';
import {
    closeAddEmailModal,
    closeLoadingModal,
    openLoadingModal,
    snackAdd,
} from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import { inputValidationEmail, inputValidationNonEmpty } from '../../functional/min-support';
import { kToastActionError } from '../general/Toast';
import TextFieldInput from '../input/TextFieldInput';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,
        
        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        email: '',
    };
    
    render () {
        return (
            <BaseModal
                title={'Adauga adresa ta de email'}
                open={this.props.open}
                doneActive={inputValidationEmail(this.state.email)}
                onClose={this.onClose}
                onDone={this.onDone}>
                
                <div className={'w-100'}>

                    <TextFieldInput
                        required
                        validated={inputValidationNonEmpty(this.state.email)}

                        value={this.state.email}
                        label={'Introdu adresa de email la care vei fi notificat'}
                        onChange={(newValue) => {
                            this.setState({
                                email: newValue,
                            });
                        }}/>
                
                </div>
            
            </BaseModal>
        );
    }
    
    onClose = () => {
        this.props.toast('Introdu adresa de email pentru a inchide aceasta fereastra.', kToastActionError);
    };
    
    onDone = () => {
        this.props.openLoading();
        
        addEmailAddress(this.state.email, (successful) => {
            this.props.closeLoading();
            if (successful) {
                this.clear();
                this.props.toast('Email adaugat cu succes.');
                this.props.onCloseAddEmailModal();
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
        });
    };

    update = () => {

    }
    
    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }

    clear = () => {
        this.setState({
            email: '',
        });
    }
    
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
        
        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },

        onCloseAddEmailModal: () => { return dispatch(closeAddEmailModal()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);