import React from 'react';
import PropTypes from 'prop-types';
import {
    associateNecessaryEquipment,
    getAllNecessaryItems, getAllSentNecessaryItems,
    getAllStockModern,
    sendOperatorNecessaryEquipment,
} from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterNecessaryFields, kFilterNecessaryFieldsDefault } from '../functional/constants';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { toNecessaryItemPage, toStockItemPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import { snackAdd } from '../store/actions/application-store-actions';
import { connect } from 'react-redux';
import { kToastActionError } from '../components/general/Toast';
import PickerModal from '../components/modal/model/PickerModal';
import BaseModal from '../components/modal/model/BaseModal';
import GroupTablePage from '../layout/page/GroupTablePage';
import StockItemPicker from '../components/modal/pickers/StockItemPicker';
import ChooseOperatorModal from '../components/modal/ChooseOperatorModal';
import OperatorPicker from '../components/modal/pickers/OperatorPicker';
import AddSaleModal from '../components/modal/AddSaleModal';

class DeliveredStockPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        allItems: null,
        group: 'supplier',
        groupModal: false,
        selectedElements: [],
        errorModal: false,
        associateModalOpen: false,
        currentAssociateItem: null,

        sendToOperatorModal: false,
        uniqueOperatorId: 0,
        currentSendItemId: null,

        stock: [],
        stockLoading: true,

        openSaleModal: null,
        saleModalClientId: -1,
    };

    state = DeliveredStockPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items', 'allItems', 'stock' ];
        this.persistentIdentifier = 'pps-necessary-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
        this.retrieveInitialStock();
    }

    generalUpdate = () => {
        this.setState(DeliveredStockPage.defaultState, () => {
            this.update();
            this.retrieveInitialStock();
        });
    }

    update = () => {
        getAllSentNecessaryItems((successful, data) => {
            if (successful) {
                this.setState({
                    allItems: data,
                }, () => {
                    this.regroupData();
                });
            } else {
                console.error('no necessary');
            }
        });
    };

    retrieveInitialStock = () => {
        getAllStockModern([], [], this.state.search, 0, 21, this.state.sortType, (successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    data[ index ].stock_count = `${ data[ index ].own ? data[ index ].own : 0 } ${ data[ index ].counter }`;
                    data[ index ].stock_count_value = data[ index ].own ? parseFloat(data[ index ].own) : 0;
                });
                this.setState({
                    stock: data,
                    stockLoading: false,
                });
            } else {
                this.setState({
                    stockLoading: false,
                    stockError: true,
                });
            }
        });
    }

    regroupData = () => {
        this.setState({ items: this.groupData(this.state.allItems, this.state.group) }, () => { console.log('groups', this.state.items); });
    }

    getStockCountValue = (itemId) => {
        const found = this.state.stock.find((stockItem) => { return stockItem.id == itemId; });
        return found ? found.stock_count_value : 0;
    }

    groupData = (data, groupType) => {
        if (groupType === 'supplier') {
            const result = { noSupplier: [] };
            data.forEach((entry) => {
                if (entry.supplier_id) {
                    if (!result[ entry.supplier_id ]) {
                        result[ entry.supplier_id ] = [];
                    }
                    result[ entry.supplier_id ].push(entry);
                } else {
                    result.noSupplier.push(entry);
                }
            });
            return Object.keys(result).map((key) => { return { elements: result[ key ], id: key }; });
        } else if (groupType === 'ticket') {
            const result = { noTicket: [] };
            data.forEach((entry) => {
                if (entry.ticket_id) {
                    if (!result[ entry.ticket_id ]) {
                        result[ entry.ticket_id ] = [];
                    }
                    result[ entry.ticket_id ].push(entry);
                } else {
                    result.noTicket.push(entry);
                }
            });
            return Object.keys(result).map((key) => { return { elements: result[ key ], id: key }; });
        }
        return {};
    };

    selectItem = (item) => {
        if (this.state.selectedElements.includes(item.id)) {
            const newSelectedElements = this.state.selectedElements.filter((elementID) => { return elementID !== item.id; });
            this.setState({ selectedElements: newSelectedElements });
        } else {
            this.setState({ selectedElements: [ ...this.state.selectedElements, item.id ] });
        }
    };

    render () {
        const toolbarActions = [
            {
                title: 'Grupare',
                icon: { title: 'ft-chevrons-down' },
                onPress: this.openGroupModal,
            },
        ];

        const toolbarLabels = [
            {
                connection: 'grupare dupa: ',
                tags: [ this.getGroupLabels() ],
            },
            {
                connection: 'ai selectat: ',
                tags: [ this.getSelectedLabels() ],
            },
        ];

        if (this.state.stockLoading) {
            toolbarLabels.push({
                connection: 'asteptati: ',
                tags: [ 'error:Se incarca stocul' ],
            });
        }

        if (this.state.stockError) {
            toolbarLabels.push({
                connection: 'a aparut o problema: ',
                tags: [ 'error:Stocul nu s-a incarcat' ],
            });
        }

        if (false && this.state.selectedElements.length > 0) {
            toolbarActions.push({
                title: 'Adauga la comanda',
                icon: { title: 'ft-plus' },
                onPress: this.openErrorModal,
            });
        }

        return (
            <GroupTablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                allListItems={this.state.items}

                groupTitleExtractor={(firstElement) => {
                    if (this.state.group === 'supplier') {
                        return firstElement && firstElement.supplier_name ? firstElement.supplier_name : 'Fara furnizor';
                    } else {
                        return firstElement && firstElement.ticket_name ? firstElement.ticket_name : 'Tichet inexistent';
                    }
                }}

                includesAny={(item) => { return item.elements.reduce((acc, current) => { return acc || this.state.selectedElements.includes(current.id); }, false); }}
                onAllClick={(elements, shouldSelect) => {
                    if (shouldSelect) {
                        const newSelectedElements = [ ...this.state.selectedElements ];
                        elements.forEach((item) => {
                            if (!newSelectedElements.includes(item.id)) {
                                newSelectedElements.push(item.id);
                            }
                        });
                        this.setState({ selectedElements: newSelectedElements });
                    } else {
                        let newSelectedElements = [ ...this.state.selectedElements ];
                        elements.forEach((item) => {
                            newSelectedElements = newSelectedElements.filter((f) => { return f !== item.id; });
                        });
                        this.setState({ selectedElements: newSelectedElements });
                    }
                }}

                actions={toolbarActions}
                labels={toolbarLabels}

                tableFields={{
                    'selected': {
                        title: '',
                        transform: (item) => {
                            return this.state.selectedElements.includes(item.id) ?
                                <div style={{ width: 16, height: 16, backgroundColor: '#6c1b1b', borderRadius: 100, border: 'solid 2px #6c1b1b' }}/> :
                                <div style={{ width: 16, height: 16, backgroundColor: 'transparent', borderRadius: 100, border: 'solid 2px #6c1b1b' }}/>;
                        },
                        action: this.selectItem,
                        weight: 0.05,
                    },
                    'title': {
                        title: 'Denumirea',
                        transform: (item) => { return item.name; },
                        action: this.selectItem,
                        icon: 'ft-align-justify',
                    },
                    'associated': {
                        title: 'Piesa asociata',
                        transform: (item) => { return item.stock_name ? item.stock_name : <span className={'text-danger'}>Produs neasociat</span>; },
                        action: (item) => {
                            if (item.associated_id) {
                                toStockItemPage(item.associated_id);
                            } else {
                                this.setState({ associateModalOpen: true, currentAssociateItem: item });
                            }
                        },
                        icon: 'ft-link',
                    },
                    'select': {
                        title: '',
                        transform: (item) => { return this.state.selectedElements.includes(item.id) ? 'Deselecteaza' : 'Selecteaza'; },
                        action: this.selectItem,
                        weight: 0.3,
                    },
                    'send': {
                        title: '',
                        transform: (item) => {
                            return 'Creaza aviz';
                            if (item.associated_id) {
                                const myStock = this.getStockCountValue(item.associated_id);
                                return myStock >= item.nb ? `Trimite operatorului (${ item.nb } / ${ myStock })` : `Stoc insuficient (${ item.nb } / ${ myStock })`;
                            } else {
                                return 'Asociaza';
                            }
                        },
                        action: (item) => {
                            console.log('item', item);
                            this.setState({
                                openSaleModal: [ { id: item.associated_id, price: item.stock_price, quantity: item.nb } ],
                                saleModalClientId: item.parent_id,
                            });
                            //this.props.toast('Nu ai drepturile necesare pentru crearea unui aviz', kToastActionError);
                            return;
                            if (item.associated_id) {
                                const myStock = this.getStockCountValue(item.associated_id);
                                if (myStock >= item.nb) {
                                    this.setState({ sendToOperatorModal: true, uniqueOperatorId: item.user_id, currentSendItemId: item.id });
                                } else {
                                    this.props.toast('Stoc insuficient pentru a trimite operatorului!', kToastActionError);
                                }
                            } else {
                                this.setState({ associateModalOpen: true, currentAssociateItem: item });
                            }
                        },
                        weight: 0.3,
                    },
                }}

                renderListItem={(item, dark) => {
                    return (
                        <GroupItem
                            key={item.id}
                            openSaleModal={(data) => { this.setState({ openSaleModal: data }); }}
                            elements={item.elements}
                            id={item.id}
                            group={this.state.group}
                            selectedElements={this.state.selectedElements}
                            onAssociateClick={() => {
                                this.props.toast('A aparut o problema!', kToastActionError);
                            }}
                            includesAny={item.elements.reduce((acc, current) => { return acc || this.state.selectedElements.includes(current.id); }, false)}
                            onAllClick={(elements, shouldSelect) => {
                                if (shouldSelect) {
                                    const newSelectedElements = [ ...this.state.selectedElements ];
                                    elements.forEach((item) => {
                                        if (!newSelectedElements.includes(item.id)) {
                                            newSelectedElements.push(item.id);
                                        }
                                    });
                                    this.setState({ selectedElements: newSelectedElements });
                                } else {
                                    let newSelectedElements = [ ...this.state.selectedElements ];
                                    elements.forEach((item) => {
                                        newSelectedElements = newSelectedElements.filter((f) => { return f !== item.id; });
                                    });
                                    this.setState({ selectedElements: newSelectedElements });
                                }
                            }}
                            onElementClick={(element) => {
                                if (this.state.selectedElements.includes(element.id)) {
                                    const newSelectedElements = this.state.selectedElements.filter((item) => { return item !== element.id; });
                                    this.setState({ selectedElements: newSelectedElements });
                                } else {
                                    this.setState({ selectedElements: [ ...this.state.selectedElements, element.id ] });
                                }
                            }}/>
                    );
                }}

                defaultFields={kFilterNecessaryFieldsDefault}
                allFields={kFilterNecessaryFields}>

                <AddSaleModal
                    open={Boolean(this.state.openSaleModal)}
                    initial={this.state.openSaleModal}
                    clientId={this.state.saleModalClientId}
                    onClose={() => {
                        this.setState({
                            openSaleModal: null,
                        });
                        this.update();
                    }}/>

                <PickerModal
                    title={'Alege modul de grupare'}
                    open={this.state.groupModal}
                    options={[ { label: 'Dupa furnizor', value: 'supplier' }, { label: 'Dupa solicitare', value: 'ticket' } ]}
                    initialSelectedValues={[ this.state.group ]}
                    minimumChoices={1}
                    maximumChoices={1}
                    onClose={this.closeGroupModal}
                    onDone={(newValues) => { this.setState({ groupModal: false, group: newValues[ 0 ] }, () => { this.update(); }); }}/>

                <BaseModal
                    title={'Adauga produsele la comanda'}
                    open={this.state.errorModal}
                    doneActive={true}
                    onClose={this.closeErrorModal}
                    onDone={this.closeErrorModal}>

                    <TextFieldDisplay
                        label={'Produse neasociate'}
                        value={'Ai selectat produse care nu au un echivalent in baza de date. Rezolva toate asocierile inainte de a continua.'}
                        style={{ flex: 1 }}/>

                </BaseModal>

                <StockItemPicker
                    open={this.state.associateModalOpen}
                    onClose={() => { this.setState({ associateModalOpen: false }); }}
                    onDone={(result) => {
                        if (result.length > 0 && this.state.currentAssociateItem !== null) {
                            this.associateItem(this.state.currentAssociateItem.id, result[ 0 ]);
                        }
                        this.setState({ associateModalOpen: false, currentAssociateItem: null });
                    }}/>

                <OperatorPicker
                    open={this.state.sendToOperatorModal}
                    uniqueOperator={this.state.uniqueOperatorId}
                    onClose={() => { this.setState({ sendToOperatorModal: false, uniqueOperatorId: 0, currentSendItemId: null }); }}
                    onDone={(result) => {
                        const currentItemId = this.state.currentSendItemId;
                        this.setState({ sendToOperatorModal: false, uniqueOperatorId: 0, currentSendItemId: null });
                        if (result && result.length > 0) {
                            this.sentToOperatorItem(currentItemId, result[ 0 ]);
                        }
                    }}/>


            </GroupTablePage>
        );
    }

    associateItem = (necessaryId, associateId) => {
        console.log('associateItem', necessaryId, associateId);
        associateNecessaryEquipment(associateId, necessaryId, (successful, result) => {
            console.log('succ res', successful, result);
            this.update();
        });
    }

    sentToOperatorItem = (necessaryId, operatorId) => {
        console.log('send before', necessaryId, operatorId);
        sendOperatorNecessaryEquipment(necessaryId, operatorId, (successful, data) => {
            console.log('send result', successful, data);
            this.update();
        });
    }

    openGroupModal = () => {
        this.setState({ groupModal: true });
    }

    closeGroupModal = () => {
        this.setState({ groupModal: false });
    }

    openErrorModal = () => {
        this.setState({ errorModal: true });
    }

    closeErrorModal = () => {
        this.setState({ errorModal: false });
    }

    getGroupLabels = () => {
        return [ this.state.group === 'supplier' ? 'Furnizor' : 'Solicitare' ];
    }

    getSelectedLabels = () => {
        if (this.state.selectedElements.length <= 0) return 'error:Niciun produs';
        if (this.areElementsNotAssociated()) return 'error:Produse neasociate';
        return this.state.selectedElements.length + (this.state.selectedElements.length > 1 ? ' produse' : ' produs');
    }

    areElementsNotAssociated = () => {
        let result = false;
        if (!this.state.selectedElements || !this.state.allItems) return false;
        this.state.selectedElements.forEach((itemId) => {
            const found = this.state.allItems.find((item) => { return item.id === itemId; });
            if (!found || !found.associated_id) {
                result = true;
            }
        });
        return result;
    }

    onItemClick = (item) => {
        toNecessaryItemPage(item.id);
    }

}

const GroupItem = (props) => {
    if (!props.elements || props.elements.length <= 0) return null;
    const firstElement = props.elements[ 0 ];
    if (props.group === 'supplier') {
        return (
            <div className={'sectioned-list-item'}>
                <div className="sectioned-list-item-card">

                    <div className={'list-item-section'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <TextFieldDisplay
                                oneLine
                                label={'Denumirea furnizorului'}
                                value={firstElement.supplier_name ? firstElement.supplier_name : 'Fara furnizor'}
                                style={{ flex: 3 }}/>

                            {
                                props.includesAny &&
                                <TextFieldDisplay
                                    oneLine
                                    onPress={() => {
                                        props.onAllClick(props.elements, false);
                                    }}
                                    action={'edit'}
                                    label={'Deselecteaza tot'}
                                    value={'Deselecteaza toate produsele'}
                                    style={{ flex: 2 }}/>
                            }

                            {
                                !props.includesAny &&
                                <TextFieldDisplay
                                    oneLine
                                    onPress={() => {
                                        props.onAllClick(props.elements, true);
                                    }}
                                    action={'edit'}
                                    label={'Selecteaza tot'}
                                    value={'Selecteaza toate produsele'}
                                    style={{ flex: 2 }}/>
                            }
                        </div>

                        {
                            props.elements.map((element, index) => {
                                const selected = props.selectedElements && props.selectedElements.includes(element.id);
                                return (
                                    <div key={element.id} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div onClick={() => {
                                            props.onElementClick(element);
                                        }} style={{
                                            cursor: 'pointer',
                                            width: 22,
                                            height: 22,
                                            borderWidth: 2,
                                            borderColor: selected ? '#6c1b1b' : '#333333',
                                            backgroundColor: selected ? '#6c1b1b' : 'transparent',
                                            borderRadius: 100,
                                            borderStyle: 'solid',
                                            marginRight: 10,
                                        }}>

                                        </div>

                                        <TextFieldDisplay
                                            oneLine
                                            label={`Denumirea piesei #${ index + 1 }`}
                                            value={element.name}
                                            style={{ flex: 2 }}/>
                                        <TextFieldDisplay
                                            oneLine
                                            label={'Numar bucati'}
                                            value={element.nb}
                                            style={{ flex: 1 }}/>
                                        {
                                            element.associated_id &&
                                            <TextFieldDisplay
                                                oneLine
                                                label={'Denumire stock'}
                                                value={element.stock_name}
                                                style={{ flex: 2 }}/>
                                        }

                                        {
                                            !element.associated_id &&
                                            <TextFieldDisplay
                                                oneLine
                                                onPress={() => {
                                                    props.onAssociateClick(element);
                                                }}
                                                action={'edit'}
                                                label={'Asociaza stock'}
                                                value={'Asociaza'}
                                                style={{ flex: 2 }}/>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    } else if (props.group === 'ticket') {
        return (
            <div className={'sectioned-list-item'}>
                <div className="sectioned-list-item-card">

                    <div className={'list-item-section'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <TextFieldDisplay
                                oneLine
                                label={'Denumirea tichetului'}
                                value={firstElement.ticket_name ? firstElement.ticket_name : 'Tichet inexistent'}
                                style={{ flex: 3 }}/>

                            {
                                props.includesAny &&
                                <TextFieldDisplay
                                    oneLine
                                    onPress={() => {
                                        props.onAllClick(props.elements, false);
                                    }}
                                    action={'edit'}
                                    label={'Deselecteaza tot'}
                                    value={'Deselecteaza toate produsele'}
                                    style={{ flex: 2 }}/>
                            }

                            {
                                !props.includesAny &&
                                <TextFieldDisplay
                                    oneLine
                                    onPress={() => {
                                        props.onAllClick(props.elements, true);
                                    }}
                                    action={'edit'}
                                    label={'Selecteaza tot'}
                                    value={'Selecteaza toate produsele'}
                                    style={{ flex: 2 }}/>
                            }
                        </div>

                        {
                            props.elements.map((element, index) => {
                                const selected = props.selectedElements && props.selectedElements.includes(element.id);
                                return (
                                    <div key={element.id} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div onClick={() => {
                                            props.onElementClick(element);
                                        }} style={{
                                            cursor: 'pointer',
                                            width: 22,
                                            height: 22,
                                            borderWidth: 2,
                                            borderColor: selected ? '#6c1b1b' : '#333333',
                                            backgroundColor: selected ? '#6c1b1b' : 'transparent',
                                            borderRadius: 100,
                                            borderStyle: 'solid',
                                            marginRight: 10,
                                        }}>

                                        </div>

                                        <TextFieldDisplay
                                            oneLine
                                            label={`Denumirea piesei #${ index + 1 }`}
                                            value={element.name}
                                            style={{ flex: 2 }}/>
                                        <TextFieldDisplay
                                            oneLine
                                            label={'Numar bucati'}
                                            value={element.nb}
                                            style={{ flex: 1 }}/>
                                        {
                                            element.associated_id &&
                                            <TextFieldDisplay
                                                oneLine
                                                label={'Denumire stoc'}
                                                value={element.stock_name}
                                                style={{ flex: 2 }}/>
                                        }

                                        {
                                            !element.associated_id &&
                                            <TextFieldDisplay
                                                oneLine
                                                onPress={() => {
                                                    props.onAssociateClick(element);
                                                }}
                                                action={'edit'}
                                                label={'Asociaza stoc'}
                                                value={'Asociaza'}
                                                style={{ flex: 2 }}/>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
};

const ListItem = (props) => {

    return (
        <div className={'grid-list-item'} style={{ height: 'fit-content' }}>

            <div className={'inside-card'} style={{ backgroundColor: props.dark ? '#fff' : '#fff' }}>

                <div className={'internal-container'}>
                    <TextFieldDisplay
                        oneLine
                        value={props.item.name}/>
                    <TextFieldDisplay
                        oneLine
                        value={`${ props.item.nb } buc.`}
                        icon={'ft-package'}/>
                </div>

                <div className={'list-item-section-with-button'} style={{ flex: 1 }}>

                    <div className={'item-action-button'}>
                        <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                            {'Vezi piesa'}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

ListItem.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,

    empty: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
    };
};

export default withRouter(connect(null, mapDispatchToProps)(DeliveredStockPage));
