import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addOrder, getAllNecessaryItems, getStockBySupplier } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import SingleChoiceField from '../input/SingleChoiceField';
import {
    inputValidationLengthNonZero,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
    inputValidationSpecificAllStockHasQuantity,
} from '../../functional/min-support';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import Form from '../input/Form';
import MultipleChoiceField from '../input/MultipleChoiceField';
import TextFieldDisplay from '../input/TextFieldDisplay';

class Modal extends Component {
    
    static propTypes = {
        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        supplierId: -1,
        stock: [],
        stockSelected: [],
        stockQuantity: {},
    
        necessary: [],
        necessarySelected: [],
        
        suppliersTest: [],

        name: '',

        formComplete: false,
    };

    render () {

        return (
            <BaseModal
                title={'Adauga comanda'}
                stableHeight
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>

                        <TextFieldInput
                            title
                            required
                            validated={inputValidationNonEmpty(this.state.name)}

                            value={this.state.name}
                            style={{ marginBottom: 4 }}
                            label={'Denumirea comenzii'}
                            onChange={(newValue) => {
                                this.setState({
                                    name: newValue,
                                });
                            }}/>
    
                        <SingleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationSingleChoice(this.state.supplierId)}
        
                            icon={'ft-briefcase'}
                            label={'Furnizorul'}
                            predefinedModal={'supplier'}
                            value={this.state.supplierId}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    supplierId: value,
                                    stock: [],
                                    stockSelected: [],
                                }, () => {
                                    this.update();
                                });
                            }}/>
    
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={true}
                            search
        
                            minimumChoices={0}
                            maximumChoices={100}
        
                            icon={'ft-layers'}
                            label={'Alege piesele necesare care sunt comandate - nu adauga la comanda'}
                            options={this.state.necessary}
                            values={this.state.necessarySelected}
                            style={{ marginBottom: 4 }}
                            onSelect={(values) => {
                                this.setState({
                                    necessarySelected: values,
                                });
                            }}/>
    
                        <MultipleChoiceField
                            required
                            requireCompletion
                            validated={inputValidationLengthNonZero(this.state.stockSelected)}
                            search
                            
                            minimumChoices={0}
                            maximumChoices={100}
        
                            icon={'ft-layers'}
                            label={'Alege piesele'}
                            options={this.state.stock}
                            values={this.state.stockSelected}
                            style={{ marginBottom: 4 }}
                            onSelect={(values) => {
                                this.setState({
                                    stockSelected: values,
                                });
                            }}/>
                            
                        <div
                            requireCompletion
                            required
                            validated={inputValidationSpecificAllStockHasQuantity(this.state.stockSelected, this.state.stockQuantity)}>
                            {
                                this.state.stockSelected.map((item) => {
                                    let found = this.state.stock.filter((i) => { return i.value === item; });
                                    if (found.length > 0) {
                                        found = found[ 0 ];
                                        return (
                                            <StockEntry
                                                item={found}
                                                onChange={(value, newCount) => {
                                                    const q = this.state.stockQuantity;
                                                    q[ value ] = newCount;
                                                    this.setState({
                                                        stockQuantity: q,
                                                    });
                                                    
                                                }}
                                                quantity={this.state.stockQuantity.hasOwnProperty(found.value) ? this.state.stockQuantity[ found.value ] : ''}/>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        const selected = this.state.stockSelected;
        const stock = [];
        for (let i = 0; i < selected.length; ++i) {
            stock.push({
                count: this.state.stockQuantity[ selected[ i ] ],
                value: selected[ i ],
            });
        }
        
        const request = {
            name: this.state.name,
            supplierId: this.state.supplierId,
            stock,
            necessary: this.state.necessarySelected,
        };

        this.props.openLoading();

        addOrder(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Comanda inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {
        getStockBySupplier(this.state.supplierId, (successful, data) => {
            if (successful) {
                this.setState({
                    stock: data.map((item) => { return { value: item.id, label: item.name }; }),
                });
            } else {
                console.error('no add action info');
            }
        });
    
        getAllNecessaryItems((successful, data) => {
            if (successful) {
                this.setState({
                    necessary: data.map((item) => { return { value: item.id, label: item.name, description: `Necesare sunt ${ item.nb } bucati.` }; }),
                });
            } else {
                console.error('no add action info');
            }
        });
    };

    clear = () => {
        this.setState({
            supplierId: -1,
            stock: [],
            stockSelected: [],
            necessary: [],
            necessarySelected: [],

            name: '',
            model: '',
            series: '',
        });
    }

}

const StockEntry = (props) => {
    return (
        <div
            style={{ width: '100%', padding: 10, marginBottom: 10, backgroundColor: '#eeeeee', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flex: 5 }}>
            
            <TextFieldDisplay
                style={{ flex: 4 }}
                label={'piesa'}
                value={props.item.label}
                icon={'ft-settings'}/>
    
            <TextFieldInput
                flex={1}
                style={{ flex: 1 }}
                label={'Numar bucati'}
                value={props.quantity}
                onChange={(newValue) => { props.onChange(props.item.value, newValue); }}/>
        
        </div>
    );
};

StockEntry.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(null, mapDispatchToProps)(Modal);