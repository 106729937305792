import React from 'react';
import PropTypes from 'prop-types';
import { getAllOperatorsArranged } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterOperatorFields, kFilterOperatorFieldsDefault } from '../functional/constants';
import AddOperatorModal from '../components/modal/AddOperatorModal';
import { toUserPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';
import { compareGeneratorString } from '../functional/min-support';

class UsersPage extends PersistentStateComponent {
 
    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
    };

    state = UsersPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items' ];
        this.persistentIdentifier = 'pps-users-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
    }

    generalUpdate = () => {
        this.setState(UsersPage.defaultState, this.update);
    }

    update = () => {
        getAllOperatorsArranged((successful, data) => {
            if (successful) {
                data.forEach((item, index) => {
                    data[ index ].full_name = `${ data[ index ].first_name } ${ data[ index ].last_name }`;
                    data[ index ].level_name = getLevelNameBasedOnLevel(data[ index ].level);
                });
                this.setState({
                    items: data,
                });
            } else {
                console.error('no entries');
            }
        });
    };

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga utilizator',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Nume',
                        transform: (item) => { return item.full_name; },
                        action: (item) => { toUserPage(item.id); },
                        compare: compareGeneratorString('full_name'),
                        icon: 'ft-align-justify',
                    },
                    'email': {
                        title: 'Email',
                        transform: (item) => { return item.email; },
                        compare: compareGeneratorString('email'),
                        icon: 'ft-at-sign',
                    },
                    'phone': {
                        title: 'Telefon',
                        transform: (item) => { return item.phone; },
                        compare: compareGeneratorString('phone'),
                        icon: 'ft-phone',
                    },
                    'username': {
                        title: 'Utilizator',
                        transform: (item) => { return item.username; },
                        compare: compareGeneratorString('username'),
                        icon: 'ft-lock',
                    },
                    'level': {
                        title: 'Tip',
                        transform: (item) => { return item.level_name; },
                        compare: compareGeneratorString('level'),
                        icon: 'ft-layers',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toUserPage(item.id); },
                        weight: 0.5,
                    },
                }}
                defaultSort={'title'}
                defaultFields={kFilterOperatorFieldsDefault}
                allFields={kFilterOperatorFields}>

                <AddOperatorModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }

}

export const getLevelNameBasedOnLevel = (level) => {
    if (level == '2') {
        return 'Cont de management';
    } else if (level == '3') {
        return 'Cont de tehnician';
    } else if (level == '-2') {
        return 'Cont pentru depozit';
    }
    return 'neidentificat';
};

export default withRouter(UsersPage);