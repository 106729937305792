import React from 'react';
import PropTypes from 'prop-types';
import { acceptNewUser, getAllClients, getPendingUsers, rejectNewUser } from '../functional/api';
import { withRouter } from 'react-router-dom';
import { kFilterClientFields, kFilterClientFieldsDefault } from '../functional/constants';
import AddClientModal from '../components/modal/AddClientModal';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { snackAdd } from '../store/actions/application-store-actions';
import { connect } from 'react-redux';
import { kToastActionError } from '../components/general/Toast';
import { toClientPage } from '../functional/navigation';
import PersistentStateComponent from '../components/extends/PersistentStateComponent';
import TablePage from '../layout/page/TablePage';
import { compareGeneratorString } from '../functional/min-support';

class ClientsPage extends PersistentStateComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,

        pendingUsers: [],
    }

    state = ClientsPage.defaultState;

    constructor (props) {
        super(props);
        this.persistentExcludedState = [ 'items', 'pendingUsers' ];
        this.persistentIdentifier = 'pps-clients-page';
    }

    componentDidMount () {
        super.componentDidMount();
        this.update();
        this.updatePendingUsers();
    }

    updatePendingUsers = () => {
        getPendingUsers((successful, data) => {
            if (successful) {
                this.setState({
                    pendingUsers: data,
                });
            } else {
                console.error('no pending users');
            }
        });
    }

    generalUpdate = () => {
        this.setState(ClientsPage.defaultState, this.update);
    }

    update = () => {
        getAllClients((successful, data) => {
            if (successful) {
                this.setState({
                    items: data,
                });
            } else {
                console.error('no clients');
            }
        });
    };

    /*
     *renderHeaderElement={this.renderPendingUser}
     *headerElements={this.state.pendingUsers}
     */

    render () {
        return (
            <TablePage
                persistentIdentifier={`${ this.persistentIdentifier }-list`}
                onRefresh={this.generalUpdate}
                deepSearch={false}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga client',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                tableFields={{
                    'title': {
                        title: 'Denumirea',
                        transform: (item) => { return item.name; },
                        action: (item) => { toClientPage(item.id); },
                        compare: compareGeneratorString('name'),
                        icon: 'ft-align-justify',
                    },
                    'cif': {
                        title: 'CIF',
                        transform: (item) => { return item.cif; },
                        compare: compareGeneratorString('cif'),
                        icon: 'ft-hash',
                    },
                    'reg': {
                        title: 'Reg. Com.',
                        transform: (item) => { return item.reg; },
                        compare: compareGeneratorString('reg'),
                        icon: 'ft-code',
                    },
                    'bank': {
                        title: 'Banca',
                        transform: (item) => { return item.bank; },
                        compare: compareGeneratorString('bank'),
                        icon: 'ft-credit-card',
                    },
                    'iban': {
                        title: 'IBAN',
                        transform: (item) => { return item.iban; },
                        compare: compareGeneratorString('iban'),
                        icon: 'ft-info',
                    },
                    'address': {
                        title: 'Sediu',
                        transform: (item) => { return item.address; },
                        compare: compareGeneratorString('address'),
                        icon: 'ft-map-pin',
                    },
                    'view': {
                        title: '',
                        transform: (item) => { return 'Vezi'; },
                        action: (item) => { toClientPage(item.id); },
                        weight: 0.5,
                    },
                }}
                defaultSort={'title'}
                defaultFields={kFilterClientFieldsDefault}
                allFields={kFilterClientFields}>

                <AddClientModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </TablePage>
        );
    }

    renderPendingUser = (item) => {
        return (
            <div className={'HeaderElementItem'}>
                <TextFieldDisplay
                    oneLine
                    label={'numele clientului'}
                    value={item.company}
                    style={{ marginBottom: 4 }}/>

                <TextFieldDisplay
                    oneLine
                    label={'cod identificare fiscala'}
                    value={item.cif}
                    icon={'blank'}
                    style={{ marginBottom: 4 }}/>

                <TextFieldDisplay
                    oneLine
                    label={'nr. inregistrare reg. comert'}
                    value={item.reg}
                    icon={'blank'}
                    style={{ marginBottom: 4 }}/>

                <TextFieldDisplay
                    oneLine
                    label={'email'}
                    icon={'blank'}
                    value={item.username}
                    style={{ marginBottom: 4 }}/>

                <TextFieldDisplay
                    oneLine
                    label={'telefon'}
                    value={item.phone}
                    icon={'blank'}
                    style={{ marginBottom: 4 }}/>

                <div className={'buttons-container'}>
                    <div className={'button-left'} onClick={() => {
                        this.onClientUserAccept(item);
                    }}>
                        {'Accepta'}
                    </div>

                    <div className={'button-right'} onClick={() => {
                        this.onClientUserAccept(item);
                    }}>
                        {'Respinge'}
                    </div>
                </div>
            </div>
        );
    }

    onClientUserAccept = (client) => {
        acceptNewUser(client.id, (successful) => {
            if (successful) {
                this.props.toast('Noul utilizator a fost acceptat.');
            } else {
                this.props.toast('Operatiunea a esuat!', kToastActionError);
            }
            this.updatePendingUsers();
        });

    }

    onClientUserReject = (client) => {
        rejectNewUser(client.id, (successful) => {
            if (successful) {
                this.props.toast('Noul utilizator a fost respins.');
            } else {
                this.props.toast('Operatiunea a esuat!', kToastActionError);
            }

            this.updatePendingUsers();
        });
    }

    onItemClick = (item) => {
        toClientPage(item.id);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ClientsPage));