import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import { addStockAction, getUserLevel } from '../../functional/api';
import { closeLoadingModal, openLoadingModal, snackAdd } from '../../store/actions/application-store-actions';
import BaseModal from './model/BaseModal';
import TextFieldInput from '../input/TextFieldInput';
import { kToastActionError } from '../general/Toast';
import {
    inputValidationAlwaysTrue,
    inputValidationInteger,
    inputValidationNonEmpty,
    inputValidationSingleChoice,
} from '../../functional/min-support';
import Form from '../input/Form';
import SingleChoiceField from '../input/SingleChoiceField';

const kTypesOfTransfer = [
    {
        value: 'host',
        label: 'Custodie',
        description: 'Proprietarul pieselor va ramane acelasi, dar piesele vor intra in custodia destinatarului.',
    },
    {
        value: 'own',
        label: 'Transfer de piese',
        description: 'Proprietarul pieselor transferate va deveni compania catre care trimiteti.',
    },
];

class Modal extends Component {
    
    static propTypes = {
        user: PropTypes.object,
        parentKey: PropTypes.any,

        open: PropTypes.bool,
        
        onClose: PropTypes.func,

        toast: PropTypes.func,
        openLoading: PropTypes.func,
        closeLoading: PropTypes.func,
    };
    
    state = {
        quantity: '',
        description: '',

        destinationId: 0,
        sourceId: 0,

        formComplete: false,
        
        shouldChooseHosting: false,
        hostingType: -1,
    };

    componentDidMount () {
        this.setState({
            destinationId: 0,
            sourceId: 0,
        });
    }
    
    updateDestination = (userId) => {
        getUserLevel(userId, (successful, data) => {
            if (successful && data == '2') {
                this.setState({
                    shouldChooseHosting: true,
                });
            } else {
                this.setState({
                    shouldChooseHosting: false,
                });
            }
        });
    }

    render () {

        return (
            <BaseModal
                title={'Adauga intrare / iesire piesa'}
                open={this.props.open}
                doneActive={this.state.formComplete}
                onClose={this.onClose}
                onDone={this.onDone}>

                <div className={'w-100'}>

                    <Form complete={this.state.formComplete} setComplete={(complete) => { this.setState({ formComplete: complete }); }}>
    
                        <SingleChoiceField
                            required
                            validated={inputValidationAlwaysTrue(this.state.sourceId)}
        
                            parentKey={this.props.parentKey}
                            icon={'name'}
                            label={'Sursa intrarii'}
                            predefinedModal={'operator'}
                            forStock
                            extended
                            value={this.state.sourceId}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    sourceId: value,
                                });
                            }}/>
                        
                        <SingleChoiceField
                            required
                            validated={inputValidationAlwaysTrue(this.state.destinationId)}

                            parentKey={this.props.parentKey}
                            icon={'name'}
                            label={'Destinatia intrarii'}
                            predefinedModal={'operator'}
                            forStock
                            value={this.state.destinationId}
                            style={{ marginBottom: 4 }}
                            onSelect={(value) => {
                                this.setState({
                                    destinationId: value,
                                });
                                this.updateDestination(value);
                            }}/>
                            
                        {
                            this.state.shouldChooseHosting &&
                            <SingleChoiceField
                                required
                                validated={inputValidationSingleChoice(this.state.hostingType)}
    
                                icon={'code'}
                                label={'Tipul transferului'}
                                options={kTypesOfTransfer}
                                value={this.state.hostingType}
                                style={{ marginBottom: 4 }}
                                onSelect={(value) => {
                                    this.setState({
                                        hostingType: value,
                                    });
                                }}/>
                        }

                        <TextFieldInput
                            required
                            validated={inputValidationInteger(this.state.quantity)}

                            icon={'ft-layers'}
                            value={this.state.quantity}
                            style={{ marginBottom: 4 }}
                            label={'Cantitatea (+ / -)'}
                            onChange={(newValue) => {
                                this.setState({
                                    quantity: newValue,
                                });
                            }}/>

                        <TextFieldInput
                            required
                            validated={inputValidationNonEmpty(this.state.description)}

                            icon={'description'}
                            value={this.state.description}
                            style={{ marginBottom: 4 }}
                            label={'Descrierea intrarii / iesirii'}
                            onChange={(newValue) => {
                                this.setState({
                                    description: newValue,
                                });
                            }}/>

                    </Form>

                </div>

            </BaseModal>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onDone = () => {
        if (!this.props.parentKey) {
            return;
        }

        const request = {
            variation: this.state.quantity,
            description: this.state.description,
            stockId: this.props.parentKey,
            destinationId: this.state.destinationId,
            sourceId: this.state.sourceId,
            shouldChooseHosting: this.state.shouldChooseHosting,
            hostingType: this.state.hostingType,
        };

        this.props.openLoading();

        addStockAction(request, (successful) => {
            if (successful) {
                this.clear();
                this.props.toast('Intrare stoc inregistrata cu succes.');
            } else {
                this.props.toast('Operatiune esuata!', kToastActionError);
            }
            this.props.closeLoading();
            this.onClose();
        });
    };

    componentWillReceiveProps (nextProps, nextContext) {
        if (!this.props.open && nextProps.open) {
            this.update();
        }
    }
    
    update = () => {

    };

    get quantitySelected () {
        return this.state.quantity != '';
    }

    get descriptionSelected () {
        return this.state.description != '';
    }

    get readyToSend () {
        return this.quantitySelected;
    }

    clear = () => {
        this.setState({
            quantity: '',
            description: '',
        });
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        toast: (label, action) => { return dispatch(snackAdd(label, action)); },

        openLoading: () => { return dispatch(openLoadingModal()); },
        closeLoading: () => { return dispatch(closeLoadingModal()); },
    };
};

export default connect(null, mapDispatchToProps)(Modal);