import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PickerModal from '../modal/model/PickerModal';
import { renderIf } from '../../functional/min-support';
import ClientPicker from '../modal/pickers/ClientPicker';
import SupplierPicker from '../modal/pickers/SupplierPicker';
import HeadquartersPicker from '../modal/pickers/HeadquartersPicker';
import OperatorPicker from '../modal/pickers/OperatorPicker';
import ManagerPicker from '../modal/pickers/ManagerPicker';
import TextFieldDisplay from './TextFieldDisplay';

class SingleChoiceField extends Component {
    
    static propTypes = {
        label: PropTypes.string,

        options: PropTypes.array,
        value: PropTypes.any,
        placeholder: PropTypes.string,
        onSelect: PropTypes.func,

        search: PropTypes.bool,
        searchInDescription: PropTypes.bool,

        style: PropTypes.object,

        icon: PropTypes.string,

        onMore: PropTypes.func,
        onMoreText: PropTypes.string,

        fetchOptions: PropTypes.func,

        predefinedModal: PropTypes.string,
        parentKey: PropTypes.any,

        required: PropTypes.bool,
        validated: PropTypes.func,
        requireCompletion: PropTypes.bool,
    };

    state = {
        modalOpen: false,

        predefinedLabel: -1,
    };
    
    render () {
        let style = {};
        const textStyle = {};
        let action = 'ft-check';
        let actionColor = '#0f4a4788';
        if (this.props.style) style = { ...this.props.style };
        if (this.isPlaceholder()) {
            textStyle.color = '#999999';
            action = 'ft-x';
            actionColor = '#66262888';
        }
        return (
            <div className={'single-choice-field'} style={style}>

                <TextFieldDisplay
                    label={this.label}
                    value={this.getLabel()}
                    icon={this.props.icon}
                    action={action}
                    actionColor={actionColor}
                    onPress={this.onModalOpen}
                    textStyle={textStyle}
                    hideLabel={this.isPlaceholder()}/>

                {
                    renderIf(this.props.predefinedModal,
                        this.getPredefinedModal())
                }

                {
                    renderIf(!this.props.predefinedModal,
                        <PickerModal
                            search={this.props.search}
                            searchInDescription={this.props.searchInDescription}
                            title={this.props.label}
                            open={this.state.modalOpen}
                            options={this.props.options}
                            initialSelectedValues={[ this.props.value ]}
                            minimumChoices={1}
                            maximumChoices={1}
                            onMore={this.props.onMore}
                            onMoreText={this.props.onMoreText}
                            onClose={this.onModalClose}
                            onDone={this.onModalDone}/>)
                }
            
            </div>
        );
    }

    getPredefinedModal = () => {
        switch (this.props.predefinedModal) {
        case 'client':
            return (
                <ClientPicker
                    open={this.state.modalOpen}
                    value={this.props.value}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    setLabel={(label) => {
                        this.setState({
                            predefinedLabel: label,
                        });
                    }}/>
            );
        case 'supplier':
            return (
                <SupplierPicker
                    open={this.state.modalOpen}
                    value={this.props.value}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    setLabel={(label) => {
                        this.setState({
                            predefinedLabel: label,
                        });
                    }}/>
            );
        case 'headquarters':
            return (
                <HeadquartersPicker
                    parentKey={this.props.parentKey}
                    open={this.state.modalOpen}
                    value={this.props.value}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    setLabel={(label) => {
                        this.setState({
                            predefinedLabel: label,
                        });
                    }}/>
            );
        case 'operator':
            return (
                <OperatorPicker
                    parentKey={this.props.parentKey}
                    open={this.state.modalOpen}
                    value={this.props.value}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    forStock={this.props.forStock}
                    extended={this.props.extended}
                    setLabel={(label) => {
                        this.setState({
                            predefinedLabel: label,
                        });
                    }}/>
            );
    
        case 'manager':
            return (
                <ManagerPicker
                    parentKey={this.props.parentKey}
                    open={this.state.modalOpen}
                    value={this.props.value}
                    onClose={this.onModalClose}
                    onDone={this.onModalDone}
                    forStock={this.props.forStock}
                    extended={this.props.extended}
                    setLabel={(label) => {
                        this.setState({
                            predefinedLabel: label,
                        });
                    }}/>
            );
        }
        return null;
    };

    get label () {
        if (this.props.label) {
            return this.props.label; //.toLowerCase();
        }
        return '';
    }

    isPlaceholder = () => {
        if (this.props.predefinedModal) {
            return this.state.predefinedLabel == -1;
        }

        for (let i = 0; i < this.props.options.length; ++i) {
            if (this.props.options[ i ].value === this.props.value) {
                return false;
            }
        }
        return true;
    };

    getLabel = () => {
        if (this.props.predefinedModal) {
            if (!this.isPlaceholder()) {
                return this.state.predefinedLabel;
            } else {
                return this.props.label;
            }
        }

        for (let i = 0; i < this.props.options.length; ++i) {
            if (this.props.options[ i ].value === this.props.value) {
                return this.props.options[ i ].label;
            }
        }
        return this.props.label;
    };

    onModalOpen = () => {
        if (this.props.blocked) {
            return;
        }
        this.setState({
            modalOpen: true,
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false,
        });
    };

    onModalDone = (options, additionalData = null) => {
        this.props.onSelect(options[ 0 ], additionalData);
        this.onModalClose();
    };
    
}

export default SingleChoiceField;