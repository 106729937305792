import React from 'react';
import LoginPage from '../pages/public/LoginPage';
import TicketsPage from '../pages/TicketsPage';
import SalesPage from '../pages/SalesPage';
import InvoicesPage from '../pages/InvoicesPage';
import ClientsPage from '../pages/ClientsPage';
import SuppliersPage from '../pages/SuppliersPage';
import UsersPage from '../pages/UsersPage';
import NecessaryPage from '../pages/NecessaryPage';
import OrdersPage from '../pages/OrdersPage';
import StockPage from '../pages/StockPage';
import MapPage from '../pages/MapPage';
import DashboardPage from '../pages/DashboardPage';
import ReportsPage from '../pages/ReportsPage';
import TicketPage from '../pages/TicketPage';
import NotFoundProtectedPage from '../pages/NotFoundProtectedPage';
import LogoutPage from '../pages/public/LogoutPage';
import NotFoundPublicPage from '../pages/public/NotFoundPublicPage';
import StockItemPage from '../pages/StockItemPage';
import SalePage from '../pages/SalePage';
import InvoicePage from '../pages/InvoicePage';
import ClientPage from '../pages/ClientPage';
import SupplierPage from '../pages/SupplierPage';
import NecessaryItemPage from '../pages/NecessaryItemPage';
import OrderPage from '../pages/OrderPage';
import UserPage from '../pages/UserPage';
import MailPage from '../pages/MailPage';
import DeliveredStockPage from '../pages/DeliveredStockPage';
import ReceivedStockPage from '../pages/ReceivedStockPage';

export const routeLogin = {
    id: 'login',
    path: '/login',
    title: 'Autentificare',
    component: LoginPage,
    public: true,
};

export const routeLoginDefault = {
    id: 'login-default',
    path: '/',
    title: 'Autentificare',
    component: LoginPage,
    public: true,
};

export const routeLogout = {
    id: 'logout',
    path: '/logout',
    title: 'Deautentificare',
    component: LogoutPage,
    public: true,
};

export const routeNotFoundPublic = {
    id: 'not-found',
    path: '/not-found',
    title: 'Pagina inexistenta',
    icon: 'ft-help-circle',
    component: NotFoundPublicPage,
    public: true,
};


export const routeDashboard = {
    id: 'dashboard',
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'ft-home',
    component: DashboardPage,
    public: false,
};

export const routeTickets = {
    id: 'tickets',
    path: '/tickets',
    title: 'Tichete',
    icon: 'ft-check-square',
    component: TicketsPage,
    public: false,
};

export const routeMail = {
    id: 'mail',
    path: '/mail',
    title: 'Emailuri',
    icon: 'ft-inbox',
    component: MailPage,
    public: false,
};

export const routeSales = {
    id: 'sales',
    path: '/sales',
    title: 'Avize',
    icon: 'ft-credit-card',
    component: SalesPage,
    public: false,
};

export const routeInvoices = {
    id: 'invoices',
    path: '/invoices',
    title: 'Facturi',
    icon: 'ft-file-text',
    component: InvoicesPage,
    public: false,
};

export const routeClients = {
    id: 'clients',
    path: '/clients',
    title: 'Clienti',
    icon: 'ft-briefcase',
    component: ClientsPage,
    public: false,
};

export const routeMap = {
    id: 'map',
    path: '/map',
    title: 'Harta',
    icon: 'ft-map',
    component: MapPage,
    public: false,
};

export const routeStock = {
    id: 'stock',
    path: '/stock',
    title: 'Lista produse',
    icon: 'ft-list',
    component: StockPage,
    public: false,
};

export const routeSuppliers = {
    id: 'suppliers',
    path: '/suppliers',
    title: 'Furnizori',
    icon: 'ft-box',
    component: SuppliersPage,
    public: false,
};

export const routeUsers = {
    id: 'users',
    path: '/users',
    title: 'Lista utilizatori',
    icon: 'ft-list',
    component: UsersPage,
    public: false,
};

export const routeNecessary = {
    id: 'necessary',
    path: '/necessary',
    title: 'Piese necesare',
    icon: 'ft-alert-triangle',
    component: NecessaryPage,
    public: false,
};

export const routeDeliveredStockPage = {
    id: 'delivered',
    path: '/delivered',
    title: 'Piese expediate',
    icon: 'ft-truck',
    component: DeliveredStockPage,
    public: false,
};

export const routeReceivedStockPage = {
    id: 'received',
    path: '/received',
    title: 'Piese receptionate',
    icon: 'ft-log-in',
    component: ReceivedStockPage,
    public: false,
};

export const routeOrders = {
    id: 'orders',
    path: '/orders',
    title: 'Comenzi',
    icon: 'ft-shopping-bag',
    component: OrdersPage,
    public: false,
};

export const routeReports = {
    id: 'reports',
    path: '/reports',
    title: 'Exemple rapoarte',
    icon: 'ft-trending-up',
    component: ReportsPage,
    public: false,
};


export const routeTicket = {
    id: 'ticket',
    path: '/tickets/:key',
    title: 'Tichet',
    component: TicketPage,
    public: false,
};

export const routeSale = {
    id: 'sale',
    path: '/sales/:key',
    title: 'Aviz',
    component: SalePage,
    public: false,
};

export const routeInvoice = {
    id: 'invoice',
    path: '/invoices/:key',
    title: 'Factura',
    component: InvoicePage,
    public: false,
};

export const routeClient = {
    id: 'client',
    path: '/clients/:key',
    title: 'Client',
    component: ClientPage,
    public: false,
};

export const routeStockItem = {
    id: 'stock',
    path: '/stock/:key',
    title: 'Piesa',
    component: StockItemPage,
    public: false,
};

export const routeSupplier = {
    id: 'supplier',
    path: '/suppliers/:key',
    title: 'Furnizor',
    component: SupplierPage,
    public: false,
};

export const routeNecessaryItem = {
    id: 'necessary-item',
    path: '/necessary/:key',
    title: 'Piesa necesara',
    component: NecessaryItemPage,
    public: false,
};

export const routeOrder = {
    id: 'order',
    path: '/orders/:key',
    title: 'Comanda',
    component: OrderPage,
    public: false,
};

export const routeUser = {
    id: 'user',
    path: '/users/:key',
    title: 'Utilizator',
    component: UserPage,
    public: false,
};


export const routeNotFoundProtected = {
    id: 'not-found',
    path: '/not-found',
    title: 'Pagina inexistenta',
    icon: 'ft-help-circle',
    component: NotFoundProtectedPage,
    public: false,
};


const routes = [
    routeLogin,
    routeLoginDefault,
    routeLogout,
    routeNotFoundPublic,

    routeDashboard,

    routeTickets,
    routeMail,
    routeSales,
    routeInvoices,
    routeClients,
    routeMap,
    routeStock,
    routeSuppliers,
    routeUsers,
    routeNecessary,
    routeDeliveredStockPage,
    routeReceivedStockPage,
    routeOrders,
    routeReports,
    routeNotFoundProtected,

    routeTicket,
    routeSale,
    routeInvoice,
    routeClient,
    routeStockItem,
    routeSupplier,
    routeNecessaryItem,
    routeOrder,
    routeUser,
];

export default routes;
